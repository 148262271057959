<template>
  <div>
    <div class="info-header">
      <div>
        <h2 v-t="'guerrillaRainstormTitle'"></h2>
      </div>
      <div>
        <p v-t="'guerrillaRainstormOverview'"></p>
      </div>
    </div>
    <div class="info-card">
      <p>
        {{ $t("guerrillaRainstormDitails1") }}<br />
        <br />
        {{ $t("guerrillaRainstormDitails2") }}<br />
        <br />
        {{ $t("guerrillaRainstormDitails3") }}<br />
      </p>
    </div>
  </div>
</template>
<i18n>
{
  "ja": {
    "guerrillaRainstormTitle": "ゲリラ豪雨予報とは？",
    "guerrillaRainstormOverview": "関東・北陸・中部地域における2，3時間先の豪雨を予測しています。",
    "guerrillaRainstormDitails1": "短時間ゲリラ豪雨予測システムは、主に、予測が非常に困難とされるゲリラ豪雨（局所的な集中豪雨）を、２，３時間前に予測するために開発されたシステムです。予測の範囲は、関東・甲信・東海・北陸地方で、地図上で黒枠で囲んだ範囲で予測しています。",
    "guerrillaRainstormDitails2": "本システムは、名古屋大学宇宙地球環境研究所で開発された「雲解像モデルCReSS」を利用して、約1.5kmの非常に細かいメッシュサイズで大気現象をシミュレーションすることで、豪雨の予測を行っています。約1.5kmメッシュサイズは、現在気象庁が実施している最も詳細なシミュレーションよりもさらに小さいサイズで、積乱雲など小さいスケールの現象をより忠実に表現できるモデルとなっています。",
    "guerrillaRainstormDitails3": "さらに、本システムでは、気象庁の３次元レーダー情報を時々刻々と数値シミュレーションに取り込む(同化する)ことで、２、３時間先の精度の高いゲリラ豪雨の予測を目指しています。"
  },
  "en": {
    "guerrillaRainstormTitle": "What is \"Guerrilla Rainstorm\" Forecast?",
    "guerrillaRainstormOverview": "Forecasting localised heavy rain, 2-3 hours ahead, in the Kanto, Hokuriku and Chubu regions. ",
    "guerrillaRainstormDitails1": "This forecasting system has been designed to locate the hard-to-predict localised heavy rain (\"guerilla rainstorm\") within the short time window of 2-3 hours ahead. It covers Kanto, Koshin, Tokai and Hokuriku as designated on the map.",
    "guerrillaRainstormDitails2": "We make prediction of heavy rains by incorporating \"Cloud Resolving Storm Simulator (CReSS) model\" developed by Nagoya University ISEE (Institute for Space-Earth Environmental Research). The model provides simulation of atmospheric phenomena within the extremely fine mesh size of approximately 1.5 km resolution, (which is finer than that used by the Japan Meteorological Agency for the most detailed simulation,) making it possible to minutely illustrate smaller scale phenomena such as cumulonimbus clouds. ",
    "guerrillaRainstormDitails3": "Furthermore, this system also integrates real-time 3D radar data from the Japan Meteorological Agency, aiming for higher accuracy in predicting localised heavy rains of 2-3 hours ahead."
  }
}
</i18n>

<script>
import RiskInfoBase from "./RiskInfoBase";
export default {
  name: "GuerrillaRainInfo",
  extends: RiskInfoBase,
};
</script>
