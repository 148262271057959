<template>
  <div>
    <virtual-water-surface-image-layer></virtual-water-surface-image-layer>
    <l-tile-layer
      :url="waterMapRiver.url"
      :maxZoom="waterMapRiver.maxZoom"
      :minZoom="waterMapRiver.minZoom"
      layer-type="overlay"
    ></l-tile-layer>
    <l-geo-json
      :geojson="alluvialFan.geojson"
      :optionsStyle="alluvialFanStyle()"
    ></l-geo-json>
    <l-geo-json
      :geojson="zeroM.geojson"
      :optionsStyle="zeroMStyle()"
    ></l-geo-json>
    <observatories-layer
      v-if="alluvialFan.geojson && zeroM.geojson"
    ></observatories-layer>
  </div>
</template>

<script>
import conf from "@/config";
import axios from "axios";
import VirtualWaterSurfaceImageLayer from "@/components/risk-map-view/layers/VirtualWaterSurfaceImageLayer";
import ObservatoriesLayer from "@/components/risk-map-view/layers/ObservatoriesLayer";
export default {
  name: "VirtualWaterSurfaceLayers",
  components: { ObservatoriesLayer, VirtualWaterSurfaceImageLayer },
  data() {
    return {
      waterMapRiver: {
        url: `${conf.urls.dataServer}/map/RisKmaDAIKASEN/{z}/{x}/{y}.png`,
        minZoom: 6,
        maxZoom: 13,
      },
      alluvialFan: {
        url: `${conf.site.apiUrl}/map/waterMap/getAlluvialFan.php`,
        geojson: null,
      },
      zeroM: {
        url: `${conf.site.apiUrl}/map/waterMap/getZeroM.php`,
        geojson: null,
      },
    };
  },
  async created() {
    const alluvialFanResponse = await axios.get(this.alluvialFan.url);
    this.alluvialFan.geojson = await alluvialFanResponse.data;

    const zeroMResponse = await axios.get(this.zeroM.url);
    this.zeroM.geojson = await zeroMResponse.data;
  },
  methods: {
    alluvialFanStyle() {
      return {
        weight: 3,
        color: "#559944",
        opacity: 1,
        fillOpacity: 0,
      };
    },
    zeroMStyle() {
      return {
        weight: 3,
        color: "#35fe23",
        opacity: 1,
        fillOpacity: 0,
      };
    },
  },
};
</script>

<style scoped></style>
