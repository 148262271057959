<template>
  <div class="renewal">
    <div class="renewal-overview">
      <h1>
        RISKMA
        <br />WEB SITE RENEWAL!
      </h1>

      <span>
        ホームページがリニューアルしました！
        <br />ブックマークの再登録をお願い致します。
      </span>

      <span>↓新しいアドレスはこちら↓</span>
    </div>

    <div class="renewal-url">
      <span>日本語</span>
      <a :href="baseJaUrl">{{ baseJaUrl }}</a>

      <span>English</span>
      <a :href="baseEnUrl">{{ baseEnUrl }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "RenewalView",
  data() {
    return {
      baseJaUrl: "https://riskma.net/ja/top",
      baseEnUrl: "https://riskma.net/ja/top",
    };
  },
};
</script>

<style lang="scss">
.renewal {
  margin: auto;
  color: #ffffff;
  h1,
  span,
  a {
    display: inline-block;
    width: 100%;
    text-align: center;
  }
}

.renewal-overview {
  h1 {
    padding-bottom: 20px;
    font-size: 4em;
  }
  span {
    padding-bottom: 20px;
    font-size: 1.5em;
  }
}

.renewal-url {
  a {
    padding-bottom: 20px;
  }
}

$sm: 400px;
@media (max-width: $sm) {
  .renewal {
    margin: 40px auto;
  }

  .renewal-overview {
    h1 {
      font-size: 2em;
    }
    span {
      font-size: 1em;
    }
  }
}
</style>
