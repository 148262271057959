<template>
  <div class="pc-risk-controller">
    <v-card dark flat color="rgba(0,0,0,0)">
      <v-list color="rgba(0,0,0,0)">
        <v-list-group
          v-for="group in groups"
          :key="group.type"
          color="white"
          :style="{
            backgroundColor: group.risks.includes(selectedRisk)
              ? '#00af82'
              : 'rgba(0,0,0,0)',
          }"
          @click="onClickGroup(group)"
          :value="group.risks.includes(selectedRisk)"
          :append-icon="null"
        >
          <template v-slot:activator>
            <v-list-item-title class="group-title font-weight-bold">
              <v-row no-gutters align="center">
                <v-col cols="auto">
                  <v-img
                    alt="twitter-icon"
                    :src="group.icon"
                    contain
                    width="30px"
                    aspect-ratio="1"
                  />
                </v-col>
                <v-spacer></v-spacer>
                <v-col v-html="$t(`group-${group.type}`)"> </v-col>
              </v-row>
            </v-list-item-title>
          </template>
          <template v-if="group.type !== '2'">
            <v-list-item
              v-for="risk in group.risks"
              :key="risk"
              class="risk"
              link
            >
              <v-list-item-title
                class="pl-2"
                :style="{ color: selectedRisk === risk ? '#00af82' : '' }"
                :class="{ 'font-weight-bold': selectedRisk === risk }"
                @click="selectedRisk = risk"
              >
                <v-row no-gutters align="center">
                  <v-col cols="auto">
                    <v-icon
                      :color="selectedRisk === risk ? '#00af82' : 'white'"
                      class="mr-2"
                    >
                      mdi-check-circle-outline
                    </v-icon>
                  </v-col>
                  <v-col v-html="$t(`risk-${risk}`)"></v-col>
                </v-row>
              </v-list-item-title>
              <v-list-item-action>
                <v-tooltip left color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon x-small v-bind="attrs" v-on="on">
                      <v-icon color="orange" @click="openDialog(risk)">
                        mdi-help-circle
                      </v-icon>
                    </v-btn>
                  </template>
                  <span class="font-weight-bold">詳細を見る</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
          </template>
          <template v-else>
            <div class="description pa-4">
              <span v-html="$t(`risk-${group.risks[0]}`)"></span>
              <v-btn icon x-small class="ml-1">
                <v-icon color="orange" @click="openDialog(group.risks[0])">
                  mdi-help-circle
                </v-icon>
              </v-btn>
            </div>
          </template>
        </v-list-group>
      </v-list>
    </v-card>
    <v-dialog v-model="dialog" width="700">
      <v-card tile>
        <v-toolbar dark dense tile absolute>
          <v-btn icon dark depressed tile @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div>
          <risk-info :contents="dialogContents"></risk-info>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<i18n>
{
  "ja": {
    "group-0": "雨量予報",
    "risk-0": "レーダー・36時間予報",
    "risk-1": "実積累加雨量",
    "group-1": "浸水リスク",
    "risk-2": "内水リスク予測",
    "risk-3": "バーチャル水面マップ",
    "group-2": "ゲリラ豪雨予報",
    "risk-4": "平日 10時～17時 1時間おきに発表関東・北陸・中部地域限定"
  },
  "en": {
    "group-0": "Sequential rainfall <br>forecast",
    "risk-0": "Radar 36 hours <br>forecast",
    "risk-1": "Actual cumulative <br>rainfall",
    "group-1": "Flood risk",
    "risk-2": "Flood risk forecast",
    "risk-3": "Virtual water’s <br>surface",
    "group-2": "\"Guerilla rainstorm\" <br>forecast",
    "risk-4": "Announced every hour, weekdays from 10:00 to 17:00 Kanto, Hokuriku, Chubu regions only"
  }
}
</i18n>
<script>
import { RISK } from "@/enums/Risk";
import { UPDATE_SELECTED_RISK } from "@/store/mutation-types";
import RiskInfo from "@/components/risk-map-view/RiskInfo";
import _ from "lodash";

export default {
  name: "PcRiskController",
  components: { RiskInfo },
  data() {
    return {
      dialog: false,
      dialogContents: null,
      groups: [
        {
          type: "0",
          icon: require("@/assets/images/views/risk-map-view/main-rainfall-icon.png"),
          risks: [RISK.RR, RISK.RT],
        },
        {
          type: "1",
          icon: require("@/assets/images/views/risk-map-view/main-water-flood-icon.png"),
          risks: [RISK.FL, RISK.VWS],
        },
        {
          type: "2",
          icon: require("@/assets/images/views/risk-map-view/main-guerrilla-rainstorm-icon.png"),
          risks: [RISK.GR],
        },
      ],
    };
  },
  created() {
    this.setRiskByUrlQuery();
  },
  computed: {
    selectedRisk: {
      get() {
        return this.$store.state.selectedRisk;
      },
      set(risk) {
        this.$store.commit(UPDATE_SELECTED_RISK, { selectedRisk: risk });
      },
    },
  },
  methods: {
    setRiskByUrlQuery() {
      const search = window.location.href.slice(
        _.lastIndexOf(window.location.href, "/") + 1
      );
      const params = new URLSearchParams(_.trimStart(search, "risk-map?"));
      const data = parseInt(params.get("data")) ? params.get("data") : "0";
      const subData = parseInt(params.get("subData"))
        ? params.get("subData")
        : "0";

      if (data && subData) {
        this.selectedRisk = this.groups[data].risks[subData];
      }
    },
    onClickGroup(group) {
      this.$nextTick(() => {
        this.selectedRisk = group.risks[0];
      });
    },
    openDialog(contents) {
      this.dialogContents = contents;
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.$nextTick(() => {
        this.dialogContents = null;
      });
    },
  },
};
</script>
<style lang="scss">
.pc-risk-controller {
  .v-list-group__items {
    background-color: white;
  }
  .v-list-group:last-of-type {
    .v-list-group__items {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
  .v-list {
    .v-list-item {
      min-height: 54px;
      height: 54px;
    }
  }
  .v-list-group:first-of-type.v-list-group--active {
    border-radius: 10px;
  }
  .v-list-group:last-of-type.v-list-group--active {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}
.v-toolbar.v-toolbar--absolute {
  position: sticky;
  top: 0;
}
</style>
<style lang="scss" scoped>
.pc-risk-controller {
  @include map-controls-base;
  padding: 0;

  .group-title {
    color: white;
  }
  .v-list {
    padding: 0;
    .v-list-item {
      min-height: 50px;
      height: 50px;
    }
  }
  .v-list-group__items {
    background-color: white;
  }
  .theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: rgba(0, 0, 0, 0.54);
  }
  .v-list-item.risk {
    min-height: 40px;
    height: 40px;
    .v-list-item__icon {
      margin: 8px 0;
    }
  }
  .risk {
    padding-left: 4px !important;
    text-align: left;
    .v-list-item__title {
      font-size: 12px;
    }
  }
  .description {
    color: black;
    font-size: 14px;
    text-align: left;
  }
}
</style>
