var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._l((_vm.imageView),function(image,index){return [_vm._l((image),function(slicedImageInfo,subIndex){return [(slicedImageInfo.visible)?_c('l-marker',{key:`flood-${index}-${subIndex}`,attrs:{"lat-lng":[slicedImageInfo.markerLat, slicedImageInfo.markerLng]}},[_c('l-icon',{attrs:{"icon-anchor":[0, 0]}},[_c('div',{style:({
              width: slicedImageInfo.divWidth + 'px',
              height: slicedImageInfo.divHeight + 'px',
              overflow: 'hidden',
              'pointer-events': 'none',
            })},[(_vm.selectedDateRiskData.images[index])?_c('img',{staticClass:"risk-image",style:({
                transform: `translate(0,${slicedImageInfo.translateY}px)`,
                width: slicedImageInfo.imgWidth + 'px',
                height: slicedImageInfo.imgHeight + 'px',
                opacity: _vm.imageOpacity,
                'pointer-events': 'none',
              }),attrs:{"alt":"risk-image","src":_vm.selectedDateRiskData.images[index].src}}):_vm._e()])])],1):_vm._e()]})]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }