<template>
  <div ref="company" class="company-page pa-12">
    <div>
      <h1 v-t="'title'"></h1>
    </div>
    <div class="table-area">
      <v-row
        no-gutters
        v-for="(item, index) in tableContents"
        :key="index"
        class="mb-2"
      >
        <v-col cols="12" md="3" sm3 class="label">{{ item.label }}</v-col>
        <v-col cols="12" md="9">{{ item.contents }}</v-col>
      </v-row>
    </div>
    <div>
      <l-map
        id="map"
        ref="map"
        :zoom="zoom"
        :center="center"
        :zoomAnimation="false"
        :options="{
          gestureHandling: 'greedy',
          disableDefaultUI: true,
        }"
      >
        <l-tile-layer :url="baseTileUrl" layer-type="base"></l-tile-layer>
      </l-map>
    </div>
  </div>
</template>

<script>
import L from "leaflet";
export default {
  name: "CompanyView",
  computed: {
    tableContents() {
      return [
        { label: this.$t("tradeName"), contents: this.$t("tradeNameContents") },
        {
          label: this.$t("establishment"),
          contents: this.$t("establishmentContents"),
        },
        {
          label: this.$t("representative"),
          contents: this.$t("representativeContents"),
        },
        {
          label: this.$t("headOfficeAddress"),
          contents: this.$t("headOfficeAddressContents"),
        },
        {
          label: this.$t("capitalStock"),
          contents: this.$t("capitalStockContents"),
        },
        {
          label: this.$t("stockExchangeListings"),
          contents: this.$t("stockExchangeListingsContents"),
        },
        {
          label: this.$t("numberOfEmployees"),
          contents: this.$t("numberOfEmployeesContents"),
        },
        {
          label: this.$t("businessProfile"),
          contents: this.$t("businessProfileContents"),
        },
      ];
    },
  },
  data() {
    return {
      zoom: 16,
      center: [35.684308, 139.7889816],
      baseTileUrl: "https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png",
    };
  },
  mounted() {
    this.$nextTick(() => {
      let icon = L.icon({
        iconUrl: require("@/assets/images/views/top-view/pin.png"),
        iconSize: [20, 40], // size of the icon
        iconAnchor: [10, 40], // point of the icon which will correspond to marker's location
        popupAnchor: [0, -40], // point from which the popup should open relative to the iconAnchor
      });
      L.marker(this.center, { icon: icon })
        .addTo(this.$refs.map.mapObject)
        .bindPopup(this.$t("currentLocation"));
    });
  },
};
</script>

<style lang="scss" scoped>
.company-page {
  color: white;
}

.table-area {
  margin: 50px 0;
  font-size: 14px;
  text-align: left;
}
.label {
  font-weight: bold;
  font-size: 20px;
}

#map {
  width: 100%;
  height: 400px;
  margin-bottom: 30px;
}
</style>

<i18n>
{
    "ja": {
        "title": "会社概要",
        "tradeName": "商号",
        "tradeNameContents": "株式会社建設技術研究所（英文名：CTI Engineering Co., Ltd.）",
        "establishment": "設立",
        "establishmentContents": "1963年 4月",
        "representative": "代表者",
        "representativeContents": "中村  哲己",
        "headOfficeAddress": "本社所在地",
        "headOfficeAddressContents": "東京都中央区日本橋浜町3-21-1(日本橋浜町Ｆタワー)",
        "capitalStock": "資本金",
        "capitalStockContents": "3,025,875,010円",
        "stockExchangeListings": "上場取引所",
        "stockExchangeListingsContents": "東京証券取引所市場第一部",
        "numberOfEmployees": "従業員数",
        "numberOfEmployeesContents": "1,656人（2019年4月1日現在）",
        "businessProfile": "事業内容",
        "businessProfileContents": "土木建設事業に関する企画、調査、計画、設計及び事業監理他",
        "currentLocation": "現在地"
    },
    "en": {
        "title": "Corporate Profile",
        "tradeName": "Trade name",
        "tradeNameContents": "CTI Engineering Co., Ltd.",
        "establishment": "Establishment",
        "establishmentContents": "1963/4/1",
        "representative": "Representative",
        "representativeContents": "Tetsumi Nakamura",
        "headOfficeAddress": "Head Office:",
        "headOfficeAddressContents": "3-21-1 Nihombashi-hamacho, Chuo-ku, Tokyo",
        "capitalStock": "Capital",
        "capitalStockContents": "3,025,875,010 JPY",
        "stockExchangeListings": "Stock Exchange Listings",
        "stockExchangeListingsContents": "First Section of the Tokyo Stock Exchange",
        "numberOfEmployees": "Number of Employees",
        "numberOfEmployeesContents": "1,656 (As of April 1, 2019)",
        "businessProfile": "Business Profile",
        "businessProfileContents": "Providing professional consulting services related to civil engineering and construction works, including planning, research, scheduling, design, and project management.",
        "currentLocation": "Your current location"
    } 
}
</i18n>
