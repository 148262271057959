<template>
  <v-row justify="center" no-gutters class="mobile-reload-button">
    <v-chip small color="primary" @click="refresh" class="date-info">
      <v-icon small left> mdi-refresh </v-icon>
      <template v-if="isLoading"> Loading... </template>
      <template v-else-if="baseDate">
        <div>
          {{ baseDate.format("YYYY/MM/DD") }}
          {{ getDay(baseDate.format("d")) }} {{ baseDate.format("HH:mm") }}
          {{ $t("update") }}
        </div>
      </template>
      <template v-else>
        {{ $t("timeOutMessage") }}
      </template>
    </v-chip>
  </v-row>
</template>
<script>
import PcCurrentInfo from "@/components/risk-map-view/pc/PcCurrentInfo";
export default {
  name: "mobile-reload-button",
  extends: PcCurrentInfo,
};
</script>
<style lang="scss">
.mobile-reload-button {
  width: 100%;
  .date-info {
    min-width: 180px;
  }
}
</style>
