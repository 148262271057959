<template>
  <div class="warning-status-layout">
    <div class="mb-1">
      <v-chip
        v-for="o in data.legend"
        :key="`legend-${o.value}`"
        :color="o.color"
        :style="{ color: getFontColor(o.value, data.legend) }"
        class="mr-1"
        small
      >
        {{ $i18n.locale === "ja" ? o.label : o.engLabel }}
      </v-chip>
    </div>
    <v-row
      v-for="(state, stateIndex) in data.status"
      :key="stateIndex"
      no-gutters
      align="stretch"
      class="font-weight-bold"
    >
      <v-col cols="4" class="prefecture">
        <v-row no-gutters align="center">
          <v-col>
            {{ $i18n.locale === "ja" ? state.prefecture : state.engPrefecture }}
          </v-col>
        </v-row>
      </v-col>
      <v-col class="areas">
        <v-chip
          v-for="(area, areaIndex) in $i18n.locale === 'ja'
            ? state.areas
            : state.engAreas"
          :key="`area-${stateIndex}-${areaIndex}`"
          :color="getColor(area.warningLevel, data.legend)"
          :style="{ color: getFontColor(area.warningLevel, data.legend) }"
          class="mr-1"
          small
        >
          {{ area.name }}
        </v-chip>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  name: "WarningStatusLayout",
  props: ["data"],
  methods: {
    getColor(level, legend) {
      return _.find(legend, { value: level }).color;
    },
    getFontColor(level, legend) {
      return _.find(legend, { value: level }).fontColor;
    },
  },
};
</script>

<style lang="scss" scoped>
$radius: 5px;
.warning-status-layout {
  .prefecture {
    font-size: 13px;
    background-color: rgba($base-color, 0.2);
    margin: 1px;
    padding: 2px;
    border-top-left-radius: $radius;
    border-bottom-left-radius: $radius;
    .row {
      height: 100%;
    }
  }
  .areas {
    background-color: rgba($base-color, 0.2);
    padding: 2px;
    margin: 1px;
    border-top-right-radius: $radius;
    border-bottom-right-radius: $radius;
  }
}
</style>
