import Vue from "vue";
import {
  LMap,
  LTileLayer,
  LImageOverlay,
  LGeoJson,
  LIcon,
  LTooltip,
  LMarker,
  LPolygon,
  LControlAttribution,
} from "vue2-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

Vue.component("l-map", LMap);
Vue.component("l-tile-layer", LTileLayer);
Vue.component("l-image-overlay", LImageOverlay);
Vue.component("l-marker", LMarker);
Vue.component("l-geo-json", LGeoJson);
Vue.component("l-icon", LIcon);
Vue.component("l-tooltip", LTooltip);
Vue.component("l-polygon", LPolygon);
Vue.component("l-control-attribution", LControlAttribution);
