<template>
  <v-tabs v-model="active" color="gray" slider-color="yellow" height="40px">
    <v-tab> バーチャル水面マップ </v-tab>
    <v-tab> よくある質問 </v-tab>
    <v-tab-item class="pa-4">
      <div class="info-header">
        <div>
          <h2 v-t="'waterMapTitle'"></h2>
        </div>
      </div>
      <div class="info-card">
        <div class="info-subject">
          {{ $t("waterMapSubject1_1") }}<br />{{ $t("waterMapSubject1_2") }}
        </div>
        <p>{{ $t("waterMapDetail1_1") }}<br /></p>
        <div>
          <img
            alt="now-icon"
            style="width: 100%"
            src="@/assets/images/views/risk-map-view/water-map-1-1.png"
          />
          <p style="text-align: center">
            1.1 {{ $t("waterMapDetail1Image1") }}
          </p>
        </div>
        <div>
          <img
            alt="now-icon"
            style="width: 100%"
            src="@/assets/images/views/risk-map-view/water-map-1-2.png"
          />
          <p style="text-align: center; font-size: 12px">
            1.2 {{ $t("waterMapDetail1Image2") }}
          </p>
        </div>
      </div>
      <div class="info-card">
        <div class="info-subject">
          {{ $t("waterMapSubject2_1") }}<br />{{ $t("waterMapSubject2_2") }}
        </div>
        <p>
          {{ $t("waterMapDetail2_1") }}<br />
          <img
            alt="now-icon"
            style="width: 100%"
            src="@/assets/images/views/risk-map-view/water-map-2-1.png"
          />
        </p>
      </div>
      <div class="info-card">
        <div class="info-subject text-red">
          {{ $t("waterMapSubject3") }}
        </div>
        <p>
          {{ $t("waterMapDetail3_1") }}<br />
          {{ $t("waterMapDetail3_1_1")
          }}<span class="text-red text-bold">{{
            $t("waterMapDetail3_1_2")
          }}</span
          >{{ $t("waterMapDetail3_1_3") }}<br />
          <img
            alt="now-icon"
            style="width: 100%"
            src="@/assets/images/views/risk-map-view/water-map-2-2.png"
          />
        </p>
      </div>
    </v-tab-item>
    <v-tab-item class="pa-4">
      <div class="info-header">
        <div>
          <h2>よくある質問</h2>
        </div>
      </div>
      <div class="info-card">
        <div>
          <div class="info-subject">ハザードマップとは何が違うのですか？</div>
          <p>
            ハザードマップや浸水想定区域図は、過去の大きな洪水などを解析したうえで、今後起こりうる最大の浸水範囲・浸水リスクを示したものです。
            バーチャル水面マップは、今、現在の河川水位の状況に対して「もし堤防がない時」に浸水が生じる可能性がある範囲を示しています。リアルタイムの降雨の状況により、河川水位も時々刻々変化するため、着色範囲もそれに応じて変わっていきます。
            また、着色される範囲は河川や海岸から近いエリアに限られています。知りたいエリアが大雨のときに浸水する可能性があるかどうかは、ハザードマップや浸水想定区域図をご確認下さい。
          </p>
        </div>
        <div>
          <div class="info-subject">
            着色されたエリアは、必ず浸水するのですか？
          </div>
          <p>
            あくまで「もし堤防がない時」の可能性を示した仮想マップですので、実際に浸水するエリアとの整合は取れておりません。バーチャル水面マップは、今、現在の河川の状況に対して「もし堤防がない時」に浸水が生じた場合のリスクです。
            また、前の質問に記載したように、着色している範囲は河川や海岸から近いエリアに限られていますので、知りたいエリアが大雨のときに浸水する可能性があるかどうかは、ハザードマップや浸水想定区域図などをご確認下さい。
          </p>
        </div>
        <div>
          <div class="info-subject">
            雨が降っていないのにいつも着色されているのですが？
          </div>
          <v-row wrap>
            <v-col cols="12" md="6">
              <p>
                雨が降っていない平常時でも、海抜ゼロメートル地帯や、天井川のような、河川水位や海水面より地盤の標高が低いエリアは、常時着色されている場合があります。そのようなエリアでは、「堤防」があるため、いつも河川や海の水位が高くても、浸水しないように守られています。
                常時着色されやすいエリアについては、マップ上に枠で囲った表示を行っています。
              </p>
            </v-col>
            <v-col cols="12" md="6" class="pa-2">
              <img
                alt="water-map-qa-1"
                style="width: 300px; height: 220px"
                src="@/assets/images/views/risk-map-view/water-map-qa-1.png"
              />
            </v-col>
          </v-row>
        </div>
        <div>
          <div class="info-subject">凡例の色は何を示しますか？</div>
          <v-row no-gutters>
            <v-col cols="12" md="6">
              <img
                alt="water-map-qa-2"
                style="width: 100%; height: 220px"
                src="@/assets/images/views/risk-map-view/water-map-qa-2.png"
              />
            </v-col>
            <v-col cols="12" md="6">
              <p>
                河川水位や海水面と河川や海岸線付近の地盤の標高値の差分[m]をとり、地盤から何m浸水するリスクがあるかを示しています。その数値によって、7段階の色に分けて表示しています。「0」より小さい数字の色の場合は、付近の河川や海岸の水位のほうが地盤高よりも低いことを示します。「0」以上の数字の色の場合は、付近の河川や海岸の水位が地盤高よりも高いことを示します。
              </p>
            </v-col>
          </v-row>
        </div>
        <div>
          <div class="info-subject">川のなかも着色してるように見えますが？</div>
          <p>
            河川の中、堤防に囲まれた範囲も、その地盤高データを使用して解析した結果を着色しています。そのため、河川敷（高水敷）やいつも水が流れている低水路もその標高によって色分けして表示されます。
          </p>
        </div>
        <div>
          <div class="info-subject">
            どんなデータを使用して解析していますか？
          </div>
          <p>
            国土交通省・都道府県が管理しているリアルタイムの河川水位データ、海上保安庁、国土交通省のリアルタイムの潮位データ、国土地理院から公開されている地盤高データを使用しています。
          </p>
        </div>
      </div>
    </v-tab-item>
  </v-tabs>
</template>
<i18n>
{
  "ja": {
    "waterMapTitle": "バーチャル水面マップ",
    "waterMapSubject1_1": "＜豪雨時にリアルタイムで＞",
    "waterMapSubject1_2": "２次元マップで水位を可視化",
    "waterMapDetail1_1": "「RisKmaバーチャル水面マップ」は、これまで「点の情報」しかなく専門的でわかりにくかった河川の観測水位、および海岸の観測潮位を、独自の水災害リスクマッピングシステムにより仮想的な水面として計算し、水位と地盤高の差を地図上に表現しています。主に１級河川本川および全国の海岸線付近を対象としています。（特許出願中）",
    "waterMapDetail1Image1": "河川水位の街側への延伸の考え方",
    "waterMapDetail1Image2": "RisKmaバーチャル水面マップによるリスク状況の再現（過去の事例）",
    "waterMapSubject2_1": "＜瞬時にわかる＞",
    "waterMapSubject2_2": "自宅付近のいまの水位は",
    "waterMapDetail2_1": "「RisKmaバーチャル水面マップ」では、リアルタイムで、地図上で近隣河川の水位や潮位と地盤高の差をご覧いただけます。 「RisKmaバーチャル水面マップ」を活用することで、氾濫（河川水位の上昇に起因する氾濫、内水氾濫、バックウォーター現象による氾濫、高潮）による浸水リスクを、パソコンやスマホなど手元の端末でいつでも簡単に確認できます。",
    "waterMapSubject3": "※ご利用上の注意",
    "waterMapDetail3_1": "１．対象範囲ついて",
    "waterMapDetail3_1_1": "全国109水系の一級河川の、主に国管理区間を河川の対象に、その",
    "waterMapDetail3_1_2": "河川から「2km」の範囲、海岸線から「5km」の範囲",
    "waterMapDetail3_1_3": "を対象エリアに着色しています。対象となる河川の範囲は、マップ上に青いラインで表示しています。 一級河川でも県や市町村が管理している中小河川などについて、現時点では対象としてませんので、知りたいエリアが大雨のときに浸水する可能性があるかどうかは、ハザードマップや浸水想定区域図をご確認下さい。",
    "waterMapDetail3_2": "２．常に着色されているエリアがあります",
    "waterMapDetail3_2_1": "雨が降っていない平常時でも、海抜ゼロメートル地帯や、天井川のような、河川水位や海水面より地盤の標高が低いエリアは、常時着色されている場合があります。そのようなエリアでは、「堤防」があるため、いつも河川や海の水位が高くても、浸水しないように守られています。 常時着色されやすいエリアについては、マップ上に枠で囲った表示を行っています。",
    "guerrillaRainstormTitle": "ゲリラ豪雨予報とは？",
    "guerrillaRainstormOverview": "関東・北陸・中部地域における2，3時間先の豪雨を予測しています。",
    "guerrillaRainstormDitails1": "短時間ゲリラ豪雨予測システムは、主に、予測が非常に困難とされるゲリラ豪雨（局所的な集中豪雨）を、２，３時間前に予測するために開発されたシステムです。予測の範囲は、関東・甲信・東海・北陸地方で、地図上で黒枠で囲んだ範囲で予測しています。",
    "guerrillaRainstormDitails2": "本システムは、名古屋大学宇宙地球環境研究所で開発された「雲解像モデルCReSS」を利用して、約1.5kmの非常に細かいメッシュサイズで大気現象をシミュレーションすることで、豪雨の予測を行っています。約1.5kmメッシュサイズは、現在気象庁が実施している最も詳細なシミュレーションよりもさらに小さいサイズで、積乱雲など小さいスケールの現象をより忠実に表現できるモデルとなっています。",
    "guerrillaRainstormDitails3": "さらに、本システムでは、気象庁の３次元レーダー情報を時々刻々と数値シミュレーションに取り込む(同化する)ことで、２、３時間先の精度の高いゲリラ豪雨の予測を目指しています。"
  },
  "en": {
    "waterMapTitle": "Water’s surface",
    "waterMapSubject1_1": "＜In real time during heavy rainfall＞",
    "waterMapSubject1_2": "Visualizing water levels with 2D maps",
    "waterMapDetail1_1": "The area under the water level lower than the current river water level is displayed in real time. Virtual underwater map is a virtual water surface created by extending the current river water level to the land around the river. Areas where the virtual water surface is higher than the land elevation are colored by the difference. The indicated area can be regarded as an area that has a potential to be flooded if the water level of a river is high and the water cannot be drained from a waterway or a tributary, or if the water overflows from a river or some other accidents occur just now. In addition, since it is a virtual map showing the potential, it is not consistent with the actual flooded area.",
    "waterMapDetail1Image1": " ",
    "waterMapDetail1Image2": " ",
    "waterMapSubject2_1": "＜Find out instantly＞",
    "waterMapSubject2_2": "Current water level near your house",
    "waterMapDetail2_1": " ",
    "waterMapSubject3": "Precautions for use",
    "waterMapDetail3_1": "Target area",
    "waterMapDetail3_1_1": "Areas with an elevation lower than the river water level even in normal times may be colored constantly. Please fully understand the above characteristics when using the virtual underwater map. Furthermore, please pay attention to the heavy rain/flood alerts issued by the Japan Meteorological Agency and Flood Risk Map that are displayed on the map.",
    "waterMapDetail3_1_2": " ",
    "waterMapDetail3_1_3": " ",
    "waterMapDetail3_2": " ",
    "waterMapDetail3_2_1": " "
  }
}
</i18n>

<script>
import RiskInfoBase from "./RiskInfoBase";
export default {
  name: "VirtualWaterSurfaceInfo",
  extends: RiskInfoBase,
  data() {
    return {
      active: null,
    };
  },
};
</script>
