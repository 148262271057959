<template>
  <div>
    <overview></overview>
    <main-contents></main-contents>
    <sub-contents></sub-contents>
    <news></news>
  </div>
</template>

<script>
import Overview from "@/components/top-view/Overview";
import MainContents from "@/components/top-view/MainContents";
import SubContents from "@/components/top-view/SubContents";
import News from "@/components/top-view/News";
export default {
  name: "MainView",
  components: { News, SubContents, MainContents, Overview },
};
</script>

<style scoped></style>
