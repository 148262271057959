<template>
  <div class="text-center">
    <h2 v-text="$t('title')"></h2>
    <v-row no-gutters justify="center">
      <v-radio-group
        v-model="currentSelected"
        dense
        :dark="$vuetify.breakpoint.mdAndUp"
        row
      >
        <v-radio
          v-for="lang in langs"
          :key="lang.value"
          :label="lang.label"
          :value="lang.value"
          color="#82b1ff"
          @click="onClick(lang)"
        ></v-radio>
      </v-radio-group>
    </v-row>
  </div>
</template>
<i18n>
{
  "ja": {
    "title": "言語設定"
  },
  "en": {
    "title": "Language Setting"
  }
}
</i18n>
<script>
import { LANG } from "@/enums/Lang";
import _ from "lodash";

export default {
  name: "Settings",
  data() {
    return {
      langs: [
        { label: "日本語", value: LANG.JA },
        { label: "English", value: LANG.EN },
      ],
      currentSelected: null,
    };
  },
  created() {
    this.currentSelected = this.$root.$i18n.locale;
  },
  methods: {
    onClick(o) {
      const selectedLang = o.value;
      const splits = _.split(this.$router.currentRoute.path, "/");
      let langIndex;
      _.forEach(splits, (text, index) => {
        if ([LANG.JA, LANG.EN].includes(text)) {
          langIndex = index;
        }
      });

      if (langIndex) {
        splits[langIndex] = selectedLang;
        this.$router.push(_.join(splits, "/"));
      }
    },
  },
};
</script>

<style scoped></style>
