<template>
  <v-menu class="lang-selector" bottom right>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :small="$vuetify.breakpoint.smAndDown"
        outlined
        dark
        v-bind="attrs"
        v-on="on"
      >
        <v-icon :small="$vuetify.breakpoint.smAndDown"> mdi-web </v-icon>
      </v-btn>
    </template>
    <v-list dense class="lang-menu">
      <v-list-item v-for="(o, index) in langs" :key="index" @click="onClick(o)">
        <v-list-item-icon>
          <v-icon v-if="$i18n.locale === o.value" small color="primary">
            mdi-check
          </v-icon>
        </v-list-item-icon>
        <v-list-item-title>
          {{ o.label }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { LANG } from "@/enums/Lang";
import _ from "lodash";

export default {
  name: "LangSelector",
  data: () => ({
    langs: [
      { label: "日本語", value: LANG.JA },
      { label: "English", value: LANG.EN },
    ],
  }),
  methods: {
    onClick(o) {
      const selectedLang = o.value;
      const splits = _.split(this.$router.currentRoute.path, "/");
      let langIndex;
      _.forEach(splits, (text, index) => {
        if ([LANG.JA, LANG.EN].includes(text)) {
          langIndex = index;
        }
      });

      if (langIndex) {
        splits[langIndex] = selectedLang;
        this.$router.push(_.join(splits, "/"));
      }
    },
  },
};
</script>

<style lang="scss">
.lang-menu {
  .v-list-item__icon:first-child {
    margin-right: 10px;
  }
}
</style>
