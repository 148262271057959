<template>
  <div class="pc-current-info text-center">
    <div class="mt-1 mb-3">
      <h5 class="risk-type" v-if="selectedRisk !== null">
        {{ $t(`risk-${selectedRisk}`) }}
      </h5>
    </div>
    <div>
      <template v-if="isLoading">
        <h1>Loading...</h1>
        <div style="height: 24px"></div>
      </template>
      <template v-else-if="baseDate">
        <h1>{{ baseDate.format("HH:mm") }} {{ $t("update") }}</h1>
        <div>
          {{ baseDate.format("YYYY/MM/DD") }}
          {{ getDay(baseDate.format("d")) }}
        </div>
      </template>
      <template v-else>
        <h1>
          {{ $t("timeOutMessage") }}
        </h1>
        <div style="height: 24px"></div>
      </template>
    </div>
    <div class="mt-6 mb-2">
      <v-progress-circular
        v-if="isLoading"
        size="40"
        indeterminate
        color="#ff951f"
      ></v-progress-circular>
      <v-tooltip v-else right color="primary">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            dark
            fab
            depressed
            small
            color="#ff951f"
            @click="refresh"
          >
            <v-icon large>mdi-refresh</v-icon>
          </v-btn>
        </template>
        <span class="font-weight-bold">最新に更新する</span>
      </v-tooltip>
    </div>
  </div>
</template>
<i18n>
{
  "ja": {
    "timeOutMessage": "予測時間外です。",
    "update": "更新",
    "risk-0": "レーダー・36時間予報",
    "risk-1": "実積累加雨量",
    "risk-2": "内水リスク予測",
    "risk-3": "バーチャル水面マップ",
    "risk-4": "ゲリラ豪雨予報"
  },
  "en": {
    "timeOutMessage": "Forecasting service currently not available",
    "update": "Update",
    "risk-0": "Radar 36 hours forecast",
    "risk-1": "Actual cumulative rainfall",
    "risk-2": "Flood risk forecast",
    "risk-3": "Virtual water’s surface",
    "risk-4": "\"Guerilla rainstorm\" forecast"
  }
}
</i18n>
<script>
import { UPDATE_CURRENT_DATE } from "@/store/mutation-types";
export default {
  name: "PcCurrentInfo",
  data() {
    return {
      week: {
        ja: ["日", "月", "火", "水", "木", "金", "土"],
        en: ["Sun.", "Mon.", "Tue.", "Wed.", "Thu.", "Fri.", "Sat."],
      },
    };
  },
  computed: {
    baseDate() {
      return this.$store.state.baseDate;
    },
    currentDate() {
      return this.$store.state.currentDate;
    },
    selectedRisk() {
      return this.$store.state.selectedRisk;
    },
    isLoading() {
      return this.$store.state.isLoading;
    },
  },
  methods: {
    getDay(index) {
      return this.week[this.$i18n.locale][index];
    },
    refresh() {
      this.$store.commit(UPDATE_CURRENT_DATE);
    },
  },
};
</script>

<style lang="scss" scoped>
.pc-current-info {
  @include map-controls-base;
  .risk-type {
    color: $point-color2;
  }
  h4 {
    margin: 0;
  }
  h1 {
    line-height: 1;
    font-size: 1.7em;
  }
}
</style>
