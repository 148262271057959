<template>
  <div class="sub-contents pa-6 text-center">
    <div :style="{ padding: $vuetify.breakpoint.mdAndUp ? '0 100px' : 0 }">
      <div class="strike-title">
        <v-row>
          <img
            alt="sub-contents-title-icon"
            src="@/assets/images/views/top-view/sub-contents-title-icon.png"
          />
          <span>{{ $t("subject") }}</span>
        </v-row>
      </div>
    </div>
    <v-row no-gutters justify="center">
      <v-col
        v-for="o in contents"
        :key="o.type"
        cols="12"
        md="4"
        class="mb-4"
        :style="{ maxWidth: $vuetify.breakpoint.mdAndUp ? '500px' : 'auto' }"
      >
        <v-card class="mx-md-6 fill-height sub-card" tile>
          <v-img
            :src="o.image.src"
            :alt="o.image.alt"
            width="100%"
            aspect-ratio="1.78"
          ></v-img>
          <v-card-text class="text-center py-8">
            <h2 class="black--text" v-html="$t(`title-${o.type}`)"></h2>
            <h5 v-html="$t(`subTitle-${o.type}`)"></h5>
          </v-card-text>
          <div class="overview text-center">
            <p v-html="$t(`overview-${o.type}`)"></p>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <div class="text-center">
      <div><i class="down-icon"></i></div>
      <v-row no-gutters justify="center" class="pa-6 pt-0">
        <v-btn
          x-large
          rounded
          dark
          color="#ff951f"
          class="mx-2 mb-2 font-weight-bold"
          width="300px"
          @click="to('enterprise2')"
        >
          水位予測・浸水予測 <v-icon right large> mdi-chevron-right </v-icon>
        </v-btn>
        <v-btn
          x-large
          rounded
          dark
          color="#ff951f"
          width="300px"
          class="mx-2 font-weight-bold"
          @click="to('enterprise')"
        >
          IoT観測パッケージ提供
          <v-icon right large> mdi-chevron-right </v-icon>
        </v-btn>
      </v-row>
      <div class="support-description pa-6 pa-md-12">
        <div
          :style="{ fontSize: $vuetify.breakpoint.mdAndUp ? '20px' : '12px' }"
        >
          {{ $t("contactMessage") }}
        </div>
        <img src="@/assets/images/views/top-view/mail.png" alt="mail-address" />
      </div>
    </div>
  </div>
</template>
<i18n>
{
  "ja": {
    "subject": "自治体・法人向けサービス",
    "title-0": "水災害リスクメール配信",
    "title-1": "水災害対策コンサルティング",
    "title-2": "水災害関連データ提供",
    "subTitle-0": "Water disaster risk email delivery",
    "subTitle-1": "Water disaster countermeasure consulting",
    "subTitle-2": "Data provision",
    "overview-0": "内水氾濫リスク等の情報をメールで配信します。",
    "overview-1": "豪雨・洪水・内水対策に対する<br/>コンサルティングを行ないます。",
    "overview-2": "当サイトで使用している情報を提供します。",
    "contactMessage": "詳しくはメールでお問い合わせください。"
  },
  "en": {
    "subject": "Services for Local Governments and Corporations",
    "title-0": "E-mail warning of<br/>water-related disaster risks.",
    "title-1": "Consulting services on<br/>water-related disaster countermeasures",
    "title-2": "Providing water-related<br/>disaster data",
    "subTitle-0": " ",
    "subTitle-1": " ",
    "subTitle-2": " ",
    "overview-0": "We distribute information regarding stormwater flooding by email.",
    "overview-1": "We provide consulting services for measures against heavy rain, flood and water-related disasters.",
    "overview-2": "We provide information on this website.",
    "contactMessage": "Please contact us by email for details."
  }
}
</i18n>
<script>
export default {
  name: "SubContents",
  data() {
    return {
      contents: [
        {
          type: "0",
          image: {
            src: require("@/assets/images/views/top-view/sub-contents-image-left.jpg"),
            alt: "sub-contents-image-left",
          },
        },
        {
          type: "1",
          image: {
            src: require("@/assets/images/views/top-view/sub-contents-image-middle.jpg"),
            alt: "sub-contents-image-middle",
          },
        },
        {
          type: "2",
          image: {
            src: require("@/assets/images/views/top-view/sub-contents-image-right.jpg"),
            alt: "sub-contents-image-right",
          },
        },
      ],
    };
  },
  methods: {
    to(url) {
      this.$router.push(`/${this.$i18n.locale}/${url}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.sub-contents {
  background-color: $base-color;

  .overview {
    font-size: 14px;
  }

  .down-icon {
    margin-bottom: 50px;
    border: dashed white;
    border-width: 0 1.5px 1.5px 0;
    display: inline-block;
    padding: 30px;
    background-color: rgba(0, 0, 0, 0);
    transform: rotate(45deg);
  }
  .support-description {
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    color: white;
    border: 1.5px dashed;
  }
  //.sub-card:hover {
  //  transform: scale(1.1);
  //  transition: transform ease 1s;
  //}
}
</style>
