<template>
  <div class="pc-map-controller">
    <div class="controls-area" :class="{ 'sidebar-hide': !selectedTab }">
      <div class="controls top left">
        <pc-logo></pc-logo>
        <pc-current-info></pc-current-info>
        <feature-controller></feature-controller>
      </div>
      <div class="controls top right">
        <pc-risk-controller></pc-risk-controller>
      </div>
      <div class="controls bottom left date-controller-area">
        <v-row no-gutters class="map-legend-area">
          <v-col cols="auto">
            <div style="width: 240px; height: 10px"></div>
          </v-col>
          <v-col class="px-6">
            <v-row no-gutters justify="center">
              <pc-map-legend></pc-map-legend>
            </v-row>
          </v-col>
        </v-row>
        <pc-date-controller></pc-date-controller>
      </div>
      <div class="controls bottom right">
        <zoom-controller></zoom-controller>
      </div>
    </div>
    <div class="sidebar-area" :class="{ hide: !selectedTab }">
      <div class="sidebar">
        <div class="sidebar-tabs">
          <v-tooltip v-for="tab in tabs" :key="tab.type" left color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                dark
                tile
                :small="selectedTab !== tab.type"
                depressed
                :color="
                  selectedTab === tab.type ? 'rgba(66, 72, 99, 0.8)' : 'orange'
                "
                class="sidebar-tab"
                @click="updateSelectedTab(tab.type)"
              >
                <v-icon
                  :small="selectedTab !== tab.type"
                  :color="selectedTab === tab.type ? 'white' : '#eeeeee'"
                >
                  {{ tab.icon }}
                </v-icon>
              </v-btn>
            </template>
            <span class="font-weight-bold">{{ tab.tooltip }}</span>
          </v-tooltip>
        </div>
        <div class="sidebar-contents">
          <v-btn small dark icon>
            <v-icon @click="updateSelectedTab(null)">mdi-close</v-icon>
          </v-btn>
          <div class="sidebar-body">
            <warning-status v-if="selectedTab === 'warning'"></warning-status>
            <settings v-if="selectedTab === 'setting'"> </settings>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PcLogo from "@/components/risk-map-view/pc/PcLogo";
import PcCurrentInfo from "@/components/risk-map-view/pc/PcCurrentInfo";
import FeatureController from "@/components/risk-map-view/FeatureController";
import PcRiskController from "@/components/risk-map-view/pc/PcRiskController";
import PcDateController from "@/components/risk-map-view/pc/PcDateController";
import WarningStatus from "@/components/risk-map-view/WarningStatus";
import Settings from "@/components/risk-map-view/Settings";
import ZoomController from "@/components/risk-map-view/ZoomController";
import PcMapLegend from "@/components/risk-map-view/pc/PcMapLegend";
export default {
  name: "PcMapController",
  components: {
    PcMapLegend,
    Settings,
    WarningStatus,
    FeatureController,
    ZoomController,
    PcDateController,
    PcRiskController,
    PcCurrentInfo,
    PcLogo,
  },
  data() {
    return {
      tabs: [
        { type: "warning", icon: " mdi-bell-alert", tooltip: "警報情報" },
        { type: "setting", icon: " mdi-cog", tooltip: "設定" },
      ],
      selectedTab: -1,
    };
  },
  created() {
    this.selectedTab = this.tabs[0].type;
  },
  methods: {
    updateSelectedTab(type) {
      this.selectedTab = type;
    },
  },
};
</script>
<style lang="scss" scoped>
$sidebar-area-width: 310px;

.pc-map-controller {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  z-index: 1000;
  .sidebar-area {
    height: 100%;
    position: relative;
    display: inline-block;
    width: $sidebar-area-width;
    pointer-events: auto;
    -webkit-transform: none;
    transform: none;
    transition: transform ease 1s;
    > div {
      position: absolute;
      width: $sidebar-area-width;
      height: 100%;
      padding: 10px;
    }
    .sidebar-tabs {
      position: absolute;
      text-align: right;
      top: 50%;
      right: 100%;
      transform: translateY(-50%);
      .sidebar-tab {
        min-width: 36px;
        height: 60px;
        padding: 0 2px;
        border-top-left-radius: 7px;
        border-bottom-left-radius: 7px;
      }

      .sidebar-tab.v-size--small {
        min-width: 30px;
        height: 50px;
      }
    }
    .sidebar {
      width: 100%;
      padding: 10px;
      padding-left: 0;
      .sidebar-contents {
        @include map-controls-base;
        height: 100%;
        width: 100%;
        > div {
          height: calc(100% - 28px - 20px);
        }
      }
      .sidebar-body {
        height: 100%;
        overflow-y: auto;
      }
    }
  }
  .sidebar-area.hide {
    -webkit-transform: translateX($sidebar-area-width);
    transform: translateX($sidebar-area-width);
    transition: transform ease 1s;
  }
  .controls-area.sidebar-hide {
    .controls.right {
      -webkit-transform: translateX($sidebar-area-width);
      transform: translateX($sidebar-area-width);
    }
  }
  .controls-area {
    height: 100vh;
    width: calc(100% - #{$sidebar-area-width});
    display: inline-block;
    position: relative;
    .controls {
      position: absolute;
      > * {
        pointer-events: auto;
        position: relative;
        clear: both;
      }
    }
    .top {
      top: 0;
      > * {
        margin: 10px 10px 0 10px;
      }
    }
    .left {
      left: 0;
      text-align: left;
      > * {
        float: left;
      }
    }
    .right {
      right: 0;
      text-align: right;
      > * {
        float: right;
      }
    }
    .bottom {
      bottom: 0;
      > * {
        margin: 0 10px 10px 10px;
      }
    }
    .controls.right {
      -webkit-transform: none;
      transform: none;
      transition: transform ease 1s;
    }
  }
  .date-controller-area {
    width: calc(100% - #{$sidebar-area-width});
  }
  .map-legend-area {
    width: 100%;
    margin: 0 !important;
  }
}
</style>
