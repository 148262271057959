<template>
  <div class="news pa-6 pa-md-12 pt-md-0 mb-md-12">
    <div :style="{ padding: $vuetify.breakpoint.mdAndUp ? '0 100px' : 0 }">
      <div class="strike-title">
        <div>
          <img
            alt="news-title-icon"
            src="@/assets/images/views/top-view/news-title-icon.png"
          />
          <span>News</span>
        </div>
      </div>
    </div>
    <v-list
      dense
      color="#424863"
      :style="{ padding: $vuetify.breakpoint.mdAndUp ? '0 100px' : 0 }"
    >
      <v-list-item v-for="o in news[$i18n.locale]" :key="o.date">
        <v-row no-gutters class="text-center white--text mb-6 mb-md-0">
          <v-col cols="12" md="4" class="date">{{ o.date }}</v-col>
          <v-col class="text-left" v-html="o.contents"></v-col>
        </v-row>
      </v-list-item>
    </v-list>
  </div>
</template>
<script>
import axios from "axios";
const NEWS_PATH =
  "https://s3.ap-northeast-1.amazonaws.com/ctie.riskma.web/hp/news.json";
export default {
  name: "News",
  data() {
    return {
      news: [],
    };
  },
  async created() {
    await this.loadNews();
  },
  methods: {
    async loadNews() {
      const response = await axios.get(NEWS_PATH);
      this.news = response.data;
    },
  },
};
</script>
<style lang="scss">
.news {
  background-color: $base-color;
  .date {
    width: 30%;
    text-align: center;
    font-weight: bold;
    font-size: 24px;
  }
  a {
    color: #5fb0ff !important;
  }
}
</style>
