<template>
  <div>
    <div class="info-header">
      <div>
        <h2 v-t="'rainFallTitle'"></h2>
      </div>
      <div>
        <p v-t="'rainFallOverview'"></p>
      </div>
    </div>
    <div class="info-card">
      <div class="info-subject" v-t="'actualRainFallTitle'"></div>
      <p>
        {{ $t("actualRainFallOverview") }}<br />
        <br />
        {{ $t("nowCastInfo") }}<br />
        {{ $t("sortTermInfo") }}<br />
        {{ $t("msmInfo") }}<br />
        <br />
        {{ $t("rainFallNotice1") }}<br /><br />
        {{ $t("rainFallNotice2") }}
      </p>
    </div>
  </div>
</template>
<i18n>
{
  "ja": {
    "rainFallTitle": "レーダー・36時間予報",
    "rainFallOverview": "36時間先までの雨量分布を５分おきに提供しています。",
    "actualRainFallTitle": "実績・予測雨量",
    "actualRainFallOverview": "本情報は、気象庁の以下の最新情報を合わせて作成しています。",
    "nowCastInfo": "・高解像度降水ナウキャスト(～60分先)",
    "sortTermInfo": "・降水短時間予報(～6時間先)",
    "msmInfo": "・メソ数値予報モデルGPV(MSM) (～36時間先)",
    "rainFallNotice1": "※60分先までは瞬間的な降雨強度を表示し、以降は前１時間の平均降雨強度を表示しています。このため雨域の見え方が異なります。",
    "rainFallNotice2": "※「メソ数値予報モデルGPV」の雨量は、5kmグリッドの数値予報モデルの結果をそのまま表示しています。局所的な豪雨の表現が難しい、系統誤差の補正がされていない等のモデル特性（詳細）を十分にご理解の上ご利用下さい。"
  },
  "en": {
    "rainFallTitle": "Sequential rainfall forecast",
    "rainFallOverview": "The rainfall distribution estimate up to 36 hours ahead provided in sequence, every 5 minutes.",
    "actualRainFallTitle": "Actual and predicted rainfall",
    "actualRainFallOverview": "Shown here includes the integrated latest information from the Japan Meteorological Agency as follows:",
    "nowCastInfo": " - High-resolution Precipitation Nowcast (up to 60 minutes ahead)",
    "sortTermInfo": " - Short-term Precipitation Forecast (up to 6 hours ahead)",
    "msmInfo": " - MesoScale Model GPV (MSM) (up to 36 hours ahead)",
    "rainFallNotice1": "*Up to 60 minutes ahead, shows the forecast of the momentary rainfall intensity. After 60 minutes, shows the average rainfall intensity for the previous 1 hour. Thus the raining area may appear differently.",
    "rainFallNotice2": "\"Meso-Scale Model GPV\" rainfall amount shows the result of numerical prediction model within the 5 km grid resolution. Note that it is complicated to depict localized heavy rain, or that the model is not thoroughly free of systematic error. Please fully understand these characteristics when using this forecast."
  }
}
</i18n>

<script>
import RiskInfoBase from "./RiskInfoBase";
export default {
  name: "RainRadarInfo",
  extends: RiskInfoBase,
};
</script>
