import Vue from "vue";
import Vuex from "vuex";
import {
  UPDATE_BASE_DATE,
  UPDATE_CURRENT_DATE,
  UPDATE_CURRENT_LOCATION,
  UPDATE_FLOOD_AREA_STATUS,
  UPDATE_GUERRILLA_RAIN_AREA_STATUS,
  UPDATE_IMAGE_OPACITY,
  UPDATE_IS_LOADING,
  UPDATE_MAP_OBJECT,
  UPDATE_META_INFO,
  UPDATE_RAIN_AREA_STATUS,
  UPDATE_RISK_DATA,
  UPDATE_SELECTED_DATE,
  UPDATE_SELECTED_FEATURES,
  UPDATE_SELECTED_RISK,
} from "@/store/mutation-types";
import axios from "axios";
import moment from "moment";
import _ from "lodash";
import conf from "@/config";
import { RISK } from "@/enums/Risk";

Vue.use(Vuex);

moment.locale("ja");
const DATE_FORMAT = "YYYY/MM/DD HH:mm";
const DATE_FORMAT_GET_IMAGE = "YYYYMMDDHHmmss";
const RESPONSE_DATE_FORMAT = "YYYY/MM/DD HH:mm:ss";

const http = () => {
  return axios.create({
    baseURL: conf.urls.dataServer,
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "json",
  });
};
const helpers = {
  generateRainRadar({ hrncBaseDate, shortTermBaseDate, msmBaseDate }) {
    const hrnc = _.concat(
      this.generatePastHrnc(hrncBaseDate),
      this.generateHrnc(hrncBaseDate)
    );
    const shortTerm = this.filterAfterDate(
      this.generateShortTerm(shortTermBaseDate),
      hrnc[hrnc.length - 1].date
    );
    const msm = this.filterAfterDate(
      this.generateMsm(msmBaseDate),
      shortTerm[shortTerm.length - 1].date
    );

    return _.concat(hrnc, shortTerm, msm);
  },
  generatePastHrnc(hrncBaseDate) {
    const tempDate = hrncBaseDate.clone().subtract(1, "hours");
    const ts = [];
    for (let i = 0; i < 6; i++) {
      const url =
        `${conf.urls.dataServer}/currentRain/meshImage/` +
        tempDate.utc().format("YYYY/MM/DD") +
        `/cM` +
        tempDate.utc().format("YYYYMMDDHHmm") +
        `_0.png`;
      const image = new Image();
      image.src = url;

      ts[i] = {
        image: image,
        date: tempDate.local().format(DATE_FORMAT),
        type: "hrnc",
      };
      tempDate.add(10, "minutes");
    }
    return ts;
  },
  generateHrnc(hrncBaseDate) {
    let n = 0;
    const results = [];
    const startDate = hrncBaseDate.clone();

    for (let i = 0; i < 7; i++) {
      const url =
        `${conf.urls.dataServer}/currentRain/meshImage/` +
        startDate.utc().format("YYYY/MM/DD") +
        `/cM` +
        startDate.utc().format("YYYYMMDDHHmm") +
        "_" +
        n +
        ".png";
      const image = new Image();
      image.src = url;
      results[i] = {
        image: image,
        date: startDate.clone().local().add(n, "minutes").format(DATE_FORMAT),
        type: "hrnc",
      };

      n += 10;
    }

    return results;
  },
  generateShortTerm(shortTermBaseDate) {
    const startDate = shortTermBaseDate.clone();
    const results = [];
    const currentTime = moment().format(DATE_FORMAT_GET_IMAGE);

    for (let i = 0; i < 6; i++) {
      const url =
        `${conf.urls.dataServer}/data/tan_Image/` +
        "t_" +
        ("0" + (i + 1)).slice(-2) +
        ".png?t=" +
        currentTime;
      const image = new Image();
      image.src = url;

      results.push({
        image,
        date: startDate.clone().local().add(i, "hours").format(DATE_FORMAT),
        type: "shortTerm",
      });
    }

    return results;
  },
  generateMsm(msmBaseDate) {
    const startDate = msmBaseDate.clone();
    const results = [];
    let currentTime = moment().format(DATE_FORMAT_GET_IMAGE);

    for (let i = 0; i < 39; i++) {
      const url =
        `${conf.urls.dataServer}/data/msm_Image/` +
        "m_" +
        ("0" + i).slice(-2) +
        ".png?t=" +
        currentTime;
      const image = new Image();
      image.src = url;

      results.push({
        image,
        date: startDate.clone().local().add(i, "hours").format(DATE_FORMAT),
        type: "msm",
      });
    }

    return results;
  },
  generateRainTotal(baseDate) {
    const numList = [1, 3, 6, 12, 24, 48];
    const imagesUrls = [];

    for (let i = 0; i < numList.length; i++) {
      const tempDate = baseDate.clone();
      const url =
        `${conf.urls.dataServer}/data/ruika_Image/` +
        tempDate.utc().format("YYYY/MM/DD") +
        `/rM` +
        tempDate.utc().format("YYYYMMDDHHmm") +
        "_" +
        numList[i] +
        ".png";
      const image = new Image();
      image.src = url;

      imagesUrls[i] = {
        image,
        date: tempDate
          .local()
          .add(numList[i], "hours")
          .add(5, "minutes")
          .format(DATE_FORMAT),
        type: "cumulative",
      };
    }

    return imagesUrls;
  },
  generateFlood(baseDate) {
    const startDate = baseDate.clone();
    const imagesUrls = [];
    let afterMinute = 0;

    for (let i = 0; i < 3; i++) {
      const images = [];
      for (let j = 1; j < 5; j++) {
        const url =
          `${conf.urls.dataServer}/data/riskm_Image/` +
          startDate.utc().format("YYYY/MM/DD") +
          "/nM" +
          startDate.utc().format("YYYYMMDDHHmm") +
          "_" +
          ("0" + afterMinute).slice(-2) +
          "_" +
          j +
          ".png";
        const image = new Image();
        image.src = url;
        images.push(image);
      }

      imagesUrls[i] = {
        images,
        date: startDate
          .clone()
          .local()
          .add(afterMinute, "minutes")
          .format(DATE_FORMAT),
        type: "flood",
      };
      afterMinute += 30;
    }
    return imagesUrls;
  },
  generateVirtualWaterSurface(baseDate) {
    const startDate = baseDate.clone().subtract(120, "minutes");
    const imagesUrls = [];
    let afterMinute = 10;

    for (let i = 0; i < 13; i++) {
      const images = [];
      for (let j = 1; j < 5; j++) {
        const url =
          `${conf.urls.dataServer}/data/water_image/` +
          startDate.utc().format("YYYY/MM/DD") +
          "/wM" +
          startDate.utc().format("YYYYMMDDHHmm") +
          "_" +
          j +
          ".png";
        const image = new Image();
        image.src = url;
        images.push(image);
      }
      imagesUrls[i] = {
        images,
        date: startDate.clone().local().format(DATE_FORMAT),
        type: "vws",
      };
      startDate.add(afterMinute, "minutes");
    }
    return imagesUrls;
  },
  generateGuerrillaRain({ responseDate, forecastDate, step }) {
    const startPoint = 30;
    const stepCount = step - startPoint;
    const baseFileName = responseDate.format("YYYYMMDDHH");
    const baseUrl = `${conf.urls.dataServer}/data/guerr_Image/${baseFileName}/`;

    return _.transform(Array(stepCount), (urls, val, i) => {
      const fileCode = ("00000000" + (startPoint * 600 + i * 600)).slice(-8);
      const fileName = baseFileName + "_" + fileCode + ".png";
      const url = baseUrl + fileName;
      const image = new Image();
      image.src = url;

      urls[i] = {
        image: image,
        date: forecastDate.clone().local().format(DATE_FORMAT),
        type: "gr",
      };
      forecastDate.add(10, "minutes");
    });
  },
  filterAfterDate(ts, filterDateString) {
    const filterDate = moment(filterDateString, DATE_FORMAT);
    return _.filter(ts, (o) => {
      return filterDate.diff(moment(o.date, DATE_FORMAT)) < 0;
    });
  },
};
export default new Vuex.Store({
  state: {
    isLoading: false,
    map: null,
    imageOpacity: 0.8,
    baseDate: null,
    selectedDate: null,
    currentDate: moment(),
    selectedRisk: null,
    selectedFeatures: [],
    riskData: null,
    rainAreaStatus: null,
    floodAreaStatus: null,
    guerrillaRainAreaStatus: null,
    currentLocation: null,
    isThirdParty: window.location !== window.parent.location,
    metaInfo: {
      title:
        "RisKma (りすくま)は内水や外水氾濫による浸水予測を行い水害対応支援を行います",
      meta: [
        {
          name: "description",
          content:
            "RisKma(りすくま)では、近年頻発するゲリラ豪雨・集中豪雨による災害の防止を目指し、リアルタイムの災害発生リスク情報を提供しています。コンテンツは３時間先のゲリラ豪雨を予測する「短時間ゲリラ豪雨予測」、長時間先の雨雲の動きを確認できる「レーダー・36時間予報」、内水はん濫の発生リスクをリアルタイムで予測する「内水リスクマップ」があります。",
        },
      ],
    },
  },
  getters: {
    selectedDateRiskData(state) {
      if (state.riskData && state.selectedDate) {
        return _.find(state.riskData, {
          date: state.selectedDate.format(DATE_FORMAT),
        });
      }
    },
    selectedDateRiskDataIndex(state) {
      if (state.riskData && state.selectedDate) {
        return _.findIndex(state.riskData, {
          date: state.selectedDate.format(DATE_FORMAT),
        });
      }
    },
    baseDateRiskDataIndex(state) {
      if (state.riskData && state.baseDate) {
        return _.findIndex(state.riskData, {
          date: state.baseDate.format(DATE_FORMAT),
        });
      }
    },
  },
  mutations: {
    [UPDATE_IS_LOADING](state, payload) {
      state.isLoading = payload.isLoading;
    },
    [UPDATE_MAP_OBJECT]: (state, payload) => {
      state.map = payload.map;
    },
    [UPDATE_IMAGE_OPACITY]: (state, payload) => {
      state.imageOpacity = payload.imageOpacity;
    },
    [UPDATE_SELECTED_RISK]: (state, payload) => {
      state.selectedRisk = payload.selectedRisk;
    },
    [UPDATE_SELECTED_FEATURES]: (state, payload) => {
      state.selectedFeatures = payload.selectedFeatures;
    },
    [UPDATE_CURRENT_DATE]: (state) => {
      state.currentDate = moment();
    },
    [UPDATE_BASE_DATE]: (state, payload) => {
      state.baseDate = payload.baseDate;
    },
    [UPDATE_SELECTED_DATE]: (state, payload) => {
      state.selectedDate = payload.selectedDate;
    },
    [UPDATE_RISK_DATA](state, payload) {
      state.riskData = payload.riskData;
    },
    [UPDATE_RAIN_AREA_STATUS](state, payload) {
      state.rainAreaStatus = payload.rainAreaStatus;
    },
    [UPDATE_FLOOD_AREA_STATUS](state, payload) {
      state.floodAreaStatus = payload.floodAreaStatus;
    },
    [UPDATE_GUERRILLA_RAIN_AREA_STATUS](state, payload) {
      state.guerrillaRainAreaStatus = payload.guerrillaRainAreaStatus;
    },
    [UPDATE_CURRENT_LOCATION](state, payload) {
      state.currentLocation = payload.currentLocation;
    },
    [UPDATE_META_INFO](state, payload) {
      state.metaInfo = payload.metaInfo;
    },
  },
  actions: {
    async loadRain({ dispatch, commit }, { riskType }) {
      commit(UPDATE_IS_LOADING, { isLoading: true });
      switch (riskType) {
        case RISK.RR:
          await dispatch("loadRainRadar");
          break;
        case RISK.RT:
          await dispatch("loadRainTotal");
          break;
      }
      commit(UPDATE_IS_LOADING, { isLoading: false });
    },
    // レーダー・36時間予報
    async loadRainRadar(context) {
      const hrncResponse = await http().get(
        `${conf.urls.dataServer}/rtcumulative/getRef.php?kbn=1`
      );
      const shortTermResponse = await http().get(
        `${conf.urls.dataServer}/data/tan_Image/getDate.php`
      );
      const msmResponse = await http().get(
        `${conf.urls.dataServer}/data/msm_Image/getDate.php`
      );

      const baseDate = ((dateString) => {
        return moment(dateString, RESPONSE_DATE_FORMAT).add(5, "minutes");
      })(hrncResponse.data);
      const hrncBaseDate = ((dateString) => {
        return moment(dateString, RESPONSE_DATE_FORMAT).add(5, "minutes");
      })(hrncResponse.data);
      const shortTermBaseDate = ((dateString) => {
        return moment.utc(dateString, RESPONSE_DATE_FORMAT).add(1, "hours");
      })(shortTermResponse.data);
      const msmBaseDate = ((dateString) => {
        return moment.utc(dateString, RESPONSE_DATE_FORMAT).add(1, "hours");
      })(msmResponse.data);

      const riskData = helpers.generateRainRadar({
        hrncBaseDate,
        shortTermBaseDate,
        msmBaseDate,
      });

      context.commit(UPDATE_BASE_DATE, { baseDate });
      context.commit(UPDATE_SELECTED_DATE, { selectedDate: baseDate });
      context.commit(UPDATE_RISK_DATA, { riskData });
    },
    // 実積累加雨量
    async loadRainTotal(context) {
      const response = await http().post(`/rtcumulative/getRef.php?kbn=1`);
      const baseDate = moment(response.data, RESPONSE_DATE_FORMAT);
      const riskData = helpers.generateRainTotal(baseDate);
      baseDate.add(5, "minutes");

      context.commit(UPDATE_BASE_DATE, { baseDate });
      context.commit(UPDATE_SELECTED_DATE, {
        selectedDate: moment(riskData[0].date, RESPONSE_DATE_FORMAT),
      });
      context.commit(UPDATE_RISK_DATA, { riskData });
    },
    async loadWater({ dispatch, commit }, { riskType }) {
      commit(UPDATE_IS_LOADING, { isLoading: true });
      switch (riskType) {
        case RISK.FL:
          await dispatch("loadFlood");
          break;
        case RISK.VWS:
          await dispatch("loadVirtualWaterSurface");
          break;
      }
      commit(UPDATE_IS_LOADING, { isLoading: false });
    },
    // 内水リスク予測
    async loadFlood(context) {
      const response = await http().post(`/rtinundrisk/getRef.php?kbn=1`);
      const baseDate = moment(response.data, DATE_FORMAT);
      const riskData = helpers.generateFlood(baseDate);

      context.commit(UPDATE_BASE_DATE, { baseDate });
      context.commit(UPDATE_SELECTED_DATE, { selectedDate: baseDate });
      context.commit(UPDATE_RISK_DATA, { riskData });
    },
    // バーチャル水面マップ
    async loadVirtualWaterSurface(context) {
      const response = await http().post(`/rtwatermap/getRef.php?kbn=1`);
      const baseDate = moment(response.data, RESPONSE_DATE_FORMAT).subtract(
        9,
        "hours"
      );

      const riskData = helpers.generateVirtualWaterSurface(baseDate);
      context.commit(UPDATE_BASE_DATE, { baseDate });
      context.commit(UPDATE_SELECTED_DATE, { selectedDate: baseDate });
      context.commit(UPDATE_RISK_DATA, { riskData });
    },
    // ゲリラ豪雨予報
    async loadGuerrillaRain(context) {
      context.commit(UPDATE_IS_LOADING, { isLoading: true });

      const response = await http().post(`/rtrainforecast/getDate.php`);
      const responseDate = moment(response.data.date, RESPONSE_DATE_FORMAT);
      const step = response.data.step;
      const baseDate = responseDate.clone();
      baseDate.add(13, "hours");
      const utcDate = moment();
      utcDate.minutes(utcDate.minutes() - utcDate.utcOffset());
      const japanDate = utcDate.clone();
      japanDate.add(9, "hours");

      let riskData = [];
      if ((japanDate.valueOf() - baseDate.valueOf()) / 60000 < 180) {
        let forecastDate = responseDate.clone();
        forecastDate.add(30 * 10 + 540, "minutes");

        riskData = helpers.generateGuerrillaRain({
          responseDate,
          forecastDate,
          step,
        });

        context.commit(UPDATE_BASE_DATE, { baseDate });
        context.commit(UPDATE_SELECTED_DATE, {
          selectedDate: moment(riskData[0].date, RESPONSE_DATE_FORMAT),
        });
      } else {
        context.commit(UPDATE_BASE_DATE, { baseDate: null });
        context.commit(UPDATE_SELECTED_DATE, { selectedDate: null });
      }
      context.commit(UPDATE_RISK_DATA, { riskData });
      context.commit(UPDATE_IS_LOADING, { isLoading: false });
    },
    async loadWarningStatus({ dispatch }) {
      await dispatch("loadRainAreaStatus");
      await dispatch("loadFloodAreaStatus");
      await dispatch("loadGuerrillaRainAreaStatus");
    },
    async loadRainAreaStatus(context) {
      const response = await http().get(`/data/riskm_Info/getWarningArea.php`);
      context.commit(UPDATE_RAIN_AREA_STATUS, {
        rainAreaStatus: response.data,
      });
    },
    async loadFloodAreaStatus(context) {
      const response = await http().get(
        `/data/riskm_Info/getWaterFloodWarningArea.php`
      );
      context.commit(UPDATE_FLOOD_AREA_STATUS, {
        floodAreaStatus: response.data,
      });
    },
    async loadGuerrillaRainAreaStatus(context) {
      const response = await http().get(
        `/rtrainforecast/infodata/getWarningArea.php`
      );
      context.commit(UPDATE_GUERRILLA_RAIN_AREA_STATUS, {
        guerrillaRainAreaStatus: response.data,
      });
    },
  },
});
