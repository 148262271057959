<template>
  <div ref="top" class="risu-kuma">
    <div class="scroll-container">
      <v-card color="white darken-2" class="risu-kuma-description">
        <v-row no-gutters>
          <v-col cols="4">
            <img
              src="@/assets/images/views/top-view/riskma_s.png"
              alt="character"
              height="100"
            />
          </v-col>
          <v-col cols="8">
            <div class="headline">リスクマ</div>
            <div class="table">
              <v-row no-gutters>
                <v-col cols="4" class="subject">習性</v-col>
                <v-col cols="8" class="description"
                  >雨が降ると穴から出てくる</v-col
                >
              </v-row>
              <v-row>
                <v-col cols="4" class="subject">好きなこと</v-col>
                <v-col cols="8" class="description">傘をさす</v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-card>
      <div class="characters-area">
        <img
          class="characters-image"
          src="@/assets/images/views/top-view/risu-kuma/risu-kuma.gif"
          alt="characters"
        />
        <div class="talk-background">
          <div class="talk">
            <div class="text" v-for="(text, i) in texts" :key="i">
              {{ text }}
            </div>
            <p class="date">
              {{ date }}
            </p>
          </div>
        </div>
      </div>
      <div class="rain">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import conf from "@/config";

export default {
  name: "RisuKumaView",
  data() {
    return {
      texts: [],
      date: "",
    };
  },
  created() {
    this.getText();
  },
  mounted: function () {
    this.$nextTick(
      function () {
        this.onResize();
        window.addEventListener("resize", this.onResize);
      }.bind(this)
    );
  },
  destroyed: function () {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    async getText() {
      //todo url
      const response = await axios.post(
        `${conf.site.apiUrl}/rtinundrisk/getRiskmaComment.php`
      );
      const texts = response.data;

      this.date = texts[texts.length - 2];
      this.texts = texts.splice(1, texts.length - 3);
    },
    onResize: function () {
      document.body.style.height = window.innerHeight + "px";
      this.$refs.top.style.height = window.innerHeight + "px";
    },
  },
};
</script>

<style lang="scss" scoped>
.risu-kuma {
  width: 100%;
  height: 100vh;
  background-image: url("@/assets/images/views/top-view/risu-kuma/risu-kuma-background.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  overflow-y: scroll;
}

.scroll-container {
  height: 100%;
  position: relative;
  min-height: 812px;
}

.risu-kuma-description {
  background-color: #2c324c;
  padding: 20px;
  float: right;
  margin: 30px;
  width: 450px;

  .headline {
    font-weight: bold;
    margin-bottom: 10px;
  }

  .table {
    > div {
      margin-bottom: 5px;
    }
  }

  .subject {
    background-color: #4b68bf;
    color: white;
    text-align: center;
    border-radius: 10px;
    padding: 2px;
    font-weight: bold;
  }

  .description {
    padding: 2px 5px;
    font-weight: bold;
  }
}

.characters-area {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50%;
  background-color: #99cbff;
  padding-bottom: 50px;

  .talk-background {
    position: absolute;
    background-color: white;
    border-radius: 50% 50% 50% 50%;
    height: 450px;
    width: 550px;
    left: calc(50%);
    top: -250px;
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);

    .talk {
      height: 100%;
      width: 100%;
      padding: 140px 80px 0 80px;
      background-image: url("@/assets/images/views/top-view/risu-kuma/speech-bubble-left.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      font-size: 15px;

      .date {
        text-align: right;
        font-size: 20px;
        font-family: sans-serif;
        font-weight: bold;
      }

      p {
        margin: 0;
      }
    }
  }

  .characters-image {
    position: absolute;
    bottom: 50px;
    left: calc(50% - 150px);
    max-height: calc(100% - 50px);
    max-width: 80%;
  }
}

@media (max-width: 600px) {
  .risu-kuma {
    background-size: 700px;
  }

  .risu-kuma-description {
    width: 100%;
    margin: 0;
    padding: 10px;

    .subject {
      font-size: 12px;
    }

    .description {
      font-size: 12px;
    }
  }

  .characters-area {
    .talk-background {
      height: 420px;
      width: 90%;
      bottom: 0;
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);

      .talk {
        background-image: url("@/assets/images/views/top-view/risu-kuma/speech-bubble-right.png");
        font-size: 12px;
        padding: 130px 40px 0 40px;

        .date {
          font-size: 14px;
        }
      }
    }
  }
}

.rain {
  $rain_drop_height: 20vh;

  position: fixed;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  & > div {
    animation: rain 500ms linear infinite;
    background-color: rgba(#ffffff, 0.25);
    position: absolute;
    left: 0;
    top: -$rain_drop_height;
    width: 2px;
    height: $rain_drop_height;
    transform-origin: center center;
  }

  @for $i from 1 through 25 {
    & > div:nth-child(#{$i}) {
      animation-delay: #{$i * 200}ms;
      animation-duration: random(250) + 575ms;
      left: random(100) + vw;
    }
  }

  @keyframes rain {
    from {
      transform: rotate(-5deg) translateY(-$rain_drop_height);
    }
    to {
      transform: rotate(-5deg) translateY(100vh + $rain_drop_height);
    }
  }
}
</style>
