import { render, staticRenderFns } from "./ObservatoriesLayer.vue?vue&type=template&id=74591f45&"
import script from "./ObservatoriesLayer.vue?vue&type=script&lang=js&"
export * from "./ObservatoriesLayer.vue?vue&type=script&lang=js&"
import style0 from "./ObservatoriesLayer.vue?vue&type=style&index=0&id=74591f45&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports