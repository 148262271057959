<template>
  <div class="enterprise">
    <header>
      <h1>
        <a :href="`/${$i18n.locale}/top`">
          <img
            src="@/assets/images/views/enterprise-view/logo_riskma.svg"
            alt="RisKma(りすくま)水災害リスクマッピングシステム"
          />
        </a>
      </h1>
      <h2>自治体・企業防災担当の方へ</h2>
      <p>気になる水災害発生リスク情報をご覧いただけます</p>
    </header>
    <section class="check_area">
      <p class="check_title f_34">お困りではありませんか？</p>
      <div class="checks">
        <p class="delay"><span></span>河川の近くに出張所・事業所がある</p>
        <p class="delay_2"><span></span>所有する施設の浸水リスクを把握したい</p>
        <p class="delay_3"><span></span>職員や社員にすばやい水害対応が必要だ</p>
      </div>
    </section>
    <section class="dev_area">
      <div class="pre_block">河川の水位の予測</div>
      <div class="pre_block">浸水の予測</div>
      <p class="f_34">
        予測システム<sup
          ><small><small>※</small></small></sup
        >を開発しています<br /><small
          ><small
            ><small
              >※契約先の企業内で自らが利用するためのシステムのこと</small
            ></small
          ></small
        >
      </p>
    </section>
    <section class="white_area">
      <p class="f_28 sp_2line">
        <span>〈 無料版 〉 36時間先雨予測</span
        >と<span>浸水予測</span>ページはこちら
      </p>
      <img
        src="@/assets/images/views/enterprise-view/arrow_o.png"
        alt=""
        class="arrow"
      />
      <a href="https://www.riskma.net" target="_blank" class="btn_b"
        >水災害リスクマッピングシステム</a
      >
      <p class="maps_title f_34">
        降っている・予測される雨でどのあたりが危ないかひと目でわかる
      </p>
      <div class="img_area">
        <img
          src="@/assets/images/views/enterprise-view/graph.png"
          alt="36時間先の予測が可能"
        />
        <img
          src="@/assets/images/views/enterprise-view/map.png"
          alt="ゲリラ豪雨予想マップ"
        />
      </div>
      <div class="green_border">
        <p class="f_green f_34 except_sp">
          洪水浸水予測ができるプラットフォーム
        </p>
        <p class="f_green f_34 only_sp">
          洪水浸水予測ができる<br />プラットフォーム
        </p>
        <p class="f_yellow f_34 only_pc">
          <span>AI</span>と<span>水理解析</span>のハイブリッドモデルによる予測
        </p>
        <p class="f_yellow f_34 except_pc">
          <span>AI</span>と<span>水理解析</span>の
        </p>
        <p class="f_yellow f_34 except_pc">ハイブリッドモデルによる予測</p>
      </div>
      <p class="f_40">自治体・企業防災担当の方へ</p>
      <p class="f_bold">導入例もご紹介させていただきます</p>
      <img
        src="@/assets/images/views/enterprise-view/arrow_g.png"
        alt=""
        class="arrow"
      />
      <a
        href="https://www.cti-rains.net/riskma-flood/mailform/form.html"
        class="btn_g f_40"
        >お問い合わせはこちら</a
      >
      <p class="f_28 sp_2line">
        <span>〈 無料版 〉 36時間先雨予測</span
        >と<span>浸水予測</span>ページはこちら
      </p>
      <img
        src="@/assets/images/views/enterprise-view/arrow_o.png"
        alt=""
        class="arrow"
      />
      <a href="https://www.riskma.net" target="_blank" class="btn_b"
        >水災害リスクマッピングシステム</a
      >
    </section>
    <section>
      <div class="performance_area f_34">
        <p>２０２０年洪水において河川水位予測</p>
        <p>浸水リスクを的確に表現。</p>
      </div>
    </section>
    <section class="icons_area white_area">
      <div class="icons_box">
        <div class="each">
          <img
            src="@/assets/images/views/enterprise-view/icon_glass.svg"
            alt=""
          />
          <p>
            河川の水位予測だけでなく、<br /><span class="f_yellow"
              >浸水リスク範囲を予測</span
            >できる
          </p>
        </div>
        <div class="each">
          <img
            src="@/assets/images/views/enterprise-view/icon_japan.svg"
            alt=""
          />
          <p>
            浸水リスク予測、河川水位予測は<br /><span class="f_yellow"
              >全国で対応可能</span
            >
          </p>
        </div>
        <div class="each">
          <img src="@/assets/images/views/enterprise-view/icon_36.svg" alt="" />
          <p>
            <span class="f_yellow two_line">36時間先(明日)までの</span
            ><br /><span class="f_yellow">長期の予測が可能</span>
          </p>
        </div>
        <div class="each">
          <img
            src="@/assets/images/views/enterprise-view/icon_drop.svg"
            alt=""
          />
          <p>
            外水リスクだけでなく<br /><span class="f_yellow"
              >内水リスクまでわかる</span
            >
          </p>
        </div>
        <div class="each">
          <img
            src="@/assets/images/views/enterprise-view/icon_mail.svg"
            alt=""
          />
          <p>
            リスクをキャッチしていち早く<br /><span class="f_yellow"
              >メールでアラート</span
            >
          </p>
        </div>
        <div class="each">
          <img
            src="@/assets/images/views/enterprise-view/icon_brain.svg"
            alt=""
          />
          <p>
            <span class="f_yellow">AIを活用した</span><br /><span
              class="f_yellow"
              >水位予測</span
            >
          </p>
        </div>
      </div>
    </section>
    <section class="white_area">
      <div class="green_border">
        <p class="f_green f_34 except_sp">
          洪水浸水予測ができるプラットフォーム
        </p>
        <p class="f_green f_34 only_sp">
          洪水浸水予測ができる<br />プラットフォーム
        </p>
        <p class="f_yellow f_34 only_pc">
          <span>AI</span>と<span>水理解析</span>のハイブリッドモデルによる予測
        </p>
        <p class="f_yellow f_34 except_pc">
          <span>AI</span>と<span>水理解析</span>の
        </p>
        <p class="f_yellow f_34 except_pc">ハイブリッドモデルによる予測</p>
      </div>
      <p class="f_40">自治体・企業防災担当の方へ</p>
      <p class="f_bold">導入例もご紹介させていただきます</p>
      <img
        src="@/assets/images/views/enterprise-view/arrow_g.png"
        alt=""
        class="arrow"
      />
      <a
        href="https://www.cti-rains.net/riskma-flood/mailform/form.html"
        class="btn_g f_40"
        >お問い合わせはこちら</a
      >
      <p class="f_28 sp_2line">
        <span>〈 無料版 〉 36時間先雨予測</span
        >と<span>浸水予測</span>ページはこちら
      </p>
      <img
        src="@/assets/images/views/enterprise-view/arrow_o.png"
        alt=""
        class="arrow"
      />
      <a href="https://www.riskma.net" target="_blank" class="btn_b"
        >水災害リスクマッピングシステム</a
      >
    </section>
    <section class="about">
      <a href="http://www.ctie.co.jp/index.html" target="_blank"
        ><img
          src="@/assets/images/views/enterprise-view/logo_cti.png"
          alt="株式会社建設技術研究所"
      /></a>
      <div class="is_green only_pc">
        <span>日本で初めてリアルタイム内水リスク予測システム</span
        >を開発・公開した民間企業です
      </div>
      <div class="is_green except_pc">
        <span>日本で初めてリアルタイム<br />内水リスク予測システム</span
        >を<br />開発・公開した民間企業です
      </div>
      <div class="is_white f_bold f_18">
        「RisKma(水災害リスクマッピングシステム)」とは
      </div>
      <p class="is_text f_18">
        従来の気象情報からではわからなかった浸水リスクの予測情報を法人・個人問わずご活用いただけるよう、<br />
        株式会社建設技術研究所が2017年より無料配信しているサービスです。<br />
        雨雲レーダー情報や短時間ゲリラ豪雨情報も「RisKma」内で閲覧可能です。<br />
        「RisKma」は株式会社建設技術研究所の登録商標です。
        <br /><br />
        詳細なマップ、アラートメール、コンテンツなどのデータ提供、<br />
        および気象データ・マッピングシステムを活かした法人のご相談など、ぜひお問い合わせください。
      </p>
    </section>
    <footer class="white_area">
      <a href="http://www.ctie.co.jp/index.html" target="_blank"
        ><img
          src="@/assets/images/views/enterprise-view/logo_cti.png"
          alt="株式会社建設技術研究所"
      /></a>
      <ul>
        <li>
          <a :href="`/${$i18n.locale}/top/company`"
            >会社概要（株式会社建設技術研究所）</a
          >
        </li>
        <li><a :href="`/${$i18n.locale}/top/agreement`">利用規約</a></li>
      </ul>
      <span class="copy">
        Copyright RisKma - All Rights Reserved.
        「RisKma」は株式会社建設技術研究所の登録商標です。
      </span>
    </footer>
  </div>
</template>

<script>
export default {
  name: "EnterpriseView",
  metaInfo: {
    title: "洪水予測・浸水予測 | 建設技術研究所",
  },
};
</script>

<style lang="scss" scoped>
.enterprise {
  height: 100vh;
  background-color: #4c526e;
  background-image: url("@/assets/images/views/enterprise-view/bk_top.jpg");
  background-size: auto 500px;
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    background-size: 150%;
  }
  background-attachment: fixed;
}
* {
  margin: 0;
  padding: 0;
  background-repeat: no-repeat;
}
img {
  border: none;
  vertical-align: bottom;
}
a {
  text-decoration: none;
  color: #000;
}
body .enterprise {
  width: 100%;
  font-family: YuGothic, "Yu Gothic medium", "Hiragino Sans", Meiryo,
    "sans-serif";
  font-size: 20px;
  line-height: 1.5;
  text-align: center;
}
.only_pc,
.except_sp {
  display: block;
}
.except_pc,
.only_sp {
  display: none;
}
.f_bold {
  font-weight: 700;
  -webkit-text-stroke: 0.5px #000;
}
.f_18 {
  font-size: 15px;
}
.f_28 {
  font-size: 22px;
}
.f_34 {
  font-size: 28px;
  font-weight: 700;
}
.f_40 {
  font-size: 32px;
  font-weight: 700;
}
.f_green {
  color: #00af82;
}
.f_yellow {
  font-weight: 700;
  -webkit-text-stroke: 0.5px #000;
  position: relative;
  line-height: 1;
  box-shadow: inset 0 -10px #ff0;
}
.f_yellow.f_34 {
  width: 25em;
  margin: 5px auto 25px;
}
.f_yellow.f_34 span {
  font-size: 44px;
}
.delay {
  animation: fadeIn 800ms linear;
  animation-fill-mode: both;
}
.delay_2 {
  animation: fadeIn 1000ms linear;
}
.delay_3 {
  animation: fadeIn 1200ms linear;
}

header {
  padding: 80px 0;
  color: #fff;
}
header img {
  width: 320px;
  height: auto;
}
header h2 {
  margin: 30px 0 10px;
  font-size: 48px;
}

/****************
 セクション設定
****************/
section {
  padding: 40px 0;
}
section.check_area {
  background: #4c526e;
  color: #fff;
}

.check_area .check_title:before,
.check_area .check_title:after {
  content: "";
  display: inline-block;
  background-image: url("@/assets/images/views/enterprise-view/border.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 220px;
  height: 15px;
  margin: 0 40px;
}
.checks {
  width: 50%;
  margin: 20px auto 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 2.2;
  text-align: center;
}
.checks p {
  position: relative;
  text-indent: -1em;
  white-space: nowrap;
}
.checks p:before {
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 2px solid #fff;
  margin: 0 15px;
}
.checks p span {
  position: relative;
}
.checks p span:before {
  content: "";
  display: inline-block;
  background-image: url("@/assets/images/views/enterprise-view/check.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 30px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -34px;
}
section.dev_area {
  background: #f0f0f0;
  font-size: 34px;
  font-weight: 700;
}
section.dev_area .pre_block {
  display: inline-block;
  background: #fff;
  width: 430px;
  height: 120px;
  margin: 70px 25px 20px;
  border-top: 4px solid #00af82;
  font-size: 34px;
  -webkit-text-stroke: 1.2px #000;
  line-height: 124px;
  position: relative;
}
.pre_block:before {
  content: "";
  display: block;
  background-image: url("@/assets/images/views/enterprise-view/icon_rain.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 170px;
  height: 100px;
  position: absolute;
  top: -88px;
  left: calc(50% - 85px);
}
.pre_block:last-of-type:before {
  background-image: url("@/assets/images/views/enterprise-view/icon_flood.svg");
}
.img_area img {
  height: 350px;
  margin: 20px 10px;
}

section.icons_area {
  padding-bottom: 0;
}
.performance_area {
  /*background-image: url("@/assets/images/views/enterprise-view/bk_md.jpg");*/
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 40px;
  color: #fff;
}
.icons_box {
  width: 1060px;
  margin: 0 auto;
  overflow: hidden;
}
.each {
  width: 340px;
  margin: 10px 20px 10px 0;
  font-size: 22px;
  float: left;
}
.each:nth-of-type(3n) {
  margin: 10px 0 10px 0;
}
.each img {
  width: 150px;
  margin: 0 0 20px;
}
.each .f_yellow:before {
  bottom: -3px;
}

section.about {
  background: #f0f0f0;
  padding: 60px 0;
  border-bottom: 2px solid #4c526e;
}
.about img {
  width: 25%;
}
.about .is_green.except_pc {
  font-size: 16px;
  line-height: 1.2;
}
.about .except_pc.is_green {
  height: inherit;
}
.is_green {
  width: 900px;
  height: 70px;
  margin: 30px auto;
  background: #00af82;
  border-radius: 40px;
  color: #fff;
  font-weight: 700;
  line-height: 70px;
}
.is_green span {
  color: #fff100;
}
.is_white {
  width: 480px;
  height: 40px;
  margin: 0 auto 20px;
  background: #fff;
  font-size: 18px;
  line-height: 40px;
}
.about p {
  line-height: 2.2;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    line-height: 1.2;
  }
}

/****************
 問い合わせエリア
****************/
.green_border {
  width: 1000px;
  margin: 30px auto;
  border: 2px solid #00af82;
  border-radius: 10px;
  padding: 30px 0;
  position: relative;
}

.btn_g,
.btn_b {
  display: block;
  width: 40rem;
  height: 120px;
  margin: 0 auto 60px;
  background: #00af82;
  border-radius: 10px;
  box-shadow: 3px 3px 6px 3px rgba(0, 0, 0, 0.6);
  color: #fff;
  line-height: 120px;
  text-indent: 3em;
  position: relative;
}
.btn_g:before,
.btn_b:before {
  content: "";
  display: inline-block;
  background-image: url("@/assets/images/views/enterprise-view/icon_mail_w.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 70px;
  height: 50px;
  position: absolute;
  top: 35px;
  left: 85px;
}
.btn_g:hover,
.btn_b:hover {
  -webkit-transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  transition: all 0.6s ease;
  transform: translate3d(0, 4px, 0);
}
.btn_g.f_40 {
  -webkit-text-stroke: 0.1px #fff;
}
.arrow {
  margin: 20px 0;
}
.f_28 span {
  -webkit-text-stroke: 1px #000;
}
.btn_b {
  height: 80px;
  background: #414141;
  color: #29abe2;
  font-weight: 700;
  text-indent: 10em;
  line-height: 84px;
}
.btn_b:before {
  background-image: url("@/assets/images/views/enterprise-view/logo_riskma_btn.svg");
  width: 200px;
  top: 22px;
  left: 20px;
}

/****************
 フッター
****************/
footer {
  padding: 50px 0;
}
footer img {
  width: 20%;
  margin: 0 auto 20px;
}
ul {
  overflow: hidden;
  margin: 20px 0;
  font-size: 16px;
  font-weight: 600;
}
li {
  list-style: none;
  display: inline;
  margin: 0 1em;
  position: relative;
}
li:nth-of-type(2n):before,
li:last-of-type:before {
  content: "";
  display: inline-block;
  width: 1.5px;
  height: 16px;
  background: #000;
  position: absolute;
  top: 2px;
  left: -1.5em;
}
li:last-of-type:before {
  left: -1em;
}
span.copy {
  font-size: 13px;
}

@media screen and (max-width: 1024px) {
  body {
    font-size: 20px;
  }
  .only_pc {
    display: none;
  }
  .except_pc {
    display: block;
  }
  .f_18 {
    font-size: 12px;
  }
  .f_28 {
    font-size: 24px;
  }
  .f_34 {
    font-size: 24px;
    font-weight: 700;
  }
  .f_40 {
    font-size: 32px;
    font-weight: 700;
  }
  .fadein,
  .fadein_left,
  .fadein_right,
  .fadein_top {
    opacity: 0;
    transform: translate(0, 300px);
    transition: all 600ms;
  }

  header {
    background-size: 150%;
  }
  header h2 {
    font-size: 40px;
  }
  .checks {
    width: 100%;
    font-size: 22px;
  }
  .check_area .check_title:before,
  .check_area .check_title:after {
    width: 20%;
    height: 10px;
    margin: 0 15px;
  }
  .checks p span:before {
    top: -12px;
    left: -35px;
  }
  section.dev_area .pre_block {
    margin: 100px 25px 40px;
  }

  .maps_title {
    width: 70%;
    margin: 0 auto;
  }
  .green_border {
    width: 95%;
  }
  .green_border:before,
  .green_border:after {
    width: 40px;
  }
  .green_border:before {
    top: 68px;
  }
  .f_yellow.f_34 {
    width: 15em;
  }
  .green_border .except_pc:first-of-type {
    width: 10em;
  }
  .f_yellow.f_34 span {
    font-size: 38px;
  }
  .btn_g,
  .btn_b {
    width: 34rem;
  }
  .btn_g:before {
    left: 65px;
  }
  .btn_b:before {
    left: 20px;
  }

  .performance_area {
    background-size: 185%;
  }
  .icons_box {
    width: 100%;
  }
  .each,
  .each:nth-of-type(3n) {
    width: calc(50% - 20px);
    margin: 10px;
    font-size: 18px;
  }

  .about img {
    width: 24rem;
  }
  .is_green {
    width: 93%;
    height: 60px;
    line-height: 30px;
    padding: 10px 0;
  }
  footer img {
    width: 18rem;
  }
}

@media screen and (max-width: 540px) {
  body {
    font-size: 16px;
  }
  header {
    background-size: 250%;
  }
  header h2 {
    font-size: 27px;
  }
  .except_sp {
    display: none;
  }
  .only_sp {
    display: block;
  }
  .f_18 {
    font-size: 12px;
  }
  .f_28 {
    font-size: 20px;
  }
  .f_34 {
    font-size: 22px;
  }
  .f_40 {
    font-size: 24px;
  }

  .check_title {
    font-size: 22px;
  }
  .check_area .check_title:before,
  .check_area .check_title:after {
    display: block;
    width: 50%;
    height: 8px;
    margin: 10px auto;
  }
  .checks {
    font-size: 15px;
  }
  .checks p:before {
    width: 14px;
    height: 14px;
    margin: -3px 8px;
  }
  .checks p span:before {
    width: 23px;
    height: 25px;
    top: -9px;
    left: -24px;
  }

  section.dev_area .pre_block {
    width: 85%;
  }
  .img_area img {
    width: 90%;
    height: auto;
  }
  .each,
  .each:nth-of-type(3n) {
    width: 80%;
    margin: 20px auto;
    font-size: 20px;
    float: none;
  }
  .sp_2line {
    width: 75%;
    margin: 0 auto;
  }
  .performance_area {
    background-size: 360%;
  }

  .green_border:before,
  .green_border:after {
    width: 20px;
  }
  .green_border:before {
    top: 90px;
  }
  .btn_g,
  .btn_b {
    width: 20rem;
  }
  .btn_g {
    padding: 30px 0 0;
    box-sizing: border-box;
    text-indent: 0;
  }
  .btn_g:before {
    top: 15px;
    left: calc(50% - 35px);
  }
  .btn_b {
    padding: 50px 0 0;
    text-indent: 0;
    font-size: 20px;
  }
  .btn_b:before {
    left: calc(50% - 100px);
  }

  .is_white {
    width: 80%;
  }
  .is_text {
    padding: 0 30px;
  }
  .about img {
    width: 20rem;
  }

  li {
    display: block;
    line-height: 2.2;
  }
  li:nth-of-type(2n):before,
  li:last-of-type:before {
    display: none;
  }
  span.copy {
    display: block;
    margin: 0 auto;
    width: 70%;
    font-size: 10px;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translate(0, 300px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
.white_area {
  background-color: white;
}
</style>
