<template>
  <div ref="top" class="top-view">
    <v-row class="header pa-4 pa-md-12" no-gutters align="center">
      <v-col cols="auto">
        <a :href="`/${$i18n.locale}/top`">
          <v-img
            class="header-logo"
            :src="require('@/assets/images/views/common/header-logo.svg')"
            contain
            aspect-ratio="3"
            :width="$vuetify.breakpoint.mdAndUp ? 320 : 160"
          ></v-img>
        </a>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="auto">
        <lang-selector></lang-selector>
      </v-col>
    </v-row>
    <router-view></router-view>
    <div class="footer pa-12">
      <v-row no-gutters align="center">
        <v-col cols="auto">
          <v-img
            class="header-logo"
            :src="require('@/assets/images/views/top-view/footer-logo.svg')"
            contain
            aspect-ratio="3"
          ></v-img>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="links" cols="auto">
          <v-row no-gutters align="center">
            <v-col cols="12" md="auto"><a href="top">・TOP</a></v-col>
            <v-col cols="12" md="auto">
              <a
                :href="`https://www.ctie.co.jp/company/outline/`"
                target="_blank"
                v-t="'corporateProfile'"
              >
              </a>
            </v-col>
            <v-col cols="12" md="auto">
              <a
                :href="`/${$i18n.locale}/top/agreement`"
                v-t="'termsOfUse'"
              ></a>
            </v-col>
            <v-col cols="10" md="auto" class="mr-md-4">
              <a
                class="risu-kuma-button"
                :href="`/${$i18n.locale}/top/risu-kuma`"
              >
                <v-img
                  alt="risu-kuma-icon"
                  src="@/assets/images/views/top-view/riskma_s.png"
                  contain
                  width="50px"
                  aspect-ratio="1"
                />
              </a>
            </v-col>
            <v-col cols="2" md="auto">
              <a class="twitter-button" href="https://twitter.com/riskma_ctie">
                <v-img
                  alt="twitter-icon"
                  src="@/assets/images/views/top-view/footer-twitter-icon.png"
                  contain
                  width="50px"
                  aspect-ratio="1"
                />
              </a>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div
        class="mt-0 mt-md-6 white--text"
        :style="{ fontSize: $vuetify.breakpoint.mdAndUp ? '14px' : '12px' }"
      >
        <div>Copyright RisKma - All Rights Reserved.</div>
        <div>
          {{ $t("trademark") }}
        </div>
      </div>
    </div>
    <scroll-top-button :container="topEl"></scroll-top-button>
  </div>
</template>
<i18n>
{
  "ja": {
    "corporateProfile": "・会社概要（株式会社建設技術研究所）",
    "termsOfUse": "・利用規約",
    "trademark": "「RisKma」は株式会社建設技術研究所の登録商標です。"
  },
  "en": {
    "corporateProfile": "・Corporate Profile (CTI Engineering, Ltd.)",
    "termsOfUse": "・Terms of Use",
    "trademark": "\"RisKma\" is a registered trademark of CTI Engineering Corporation."
  }
}
</i18n>
<script>
import LangSelector from "@/components/top-view/LangSelector";
import ScrollTopButton from "@/components/top-view/ScrollTopButton";
export default {
  name: "TopView",
  components: { ScrollTopButton, LangSelector },
  data() {
    return {
      topEl: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.topEl = this.$refs.top;
    });
  },
};
</script>
<style lang="scss">
body {
  background-color: $base-color;
}
</style>
<style lang="scss" scoped>
.top-view {
  width: 100vw;
  height: 100vh;
  background-color: $base-color;
  background-image: url("../../assets/images/views/top-view/background.svg");
  background-size: 2000px 2000px;
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    background-size: 150%;
  }
  -webkit-overflow-scrolling: touch;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;

  .footer {
    background-color: black;
    .links {
      a {
        color: white;
        padding: 0;
        text-decoration: none;
        font-size: 18px;
        cursor: pointer;
        margin-right: 15px;
        @media #{map-get($display-breakpoints, 'sm-and-down')} {
          font-size: 12px;
        }
      }
    }
  }
}
</style>
