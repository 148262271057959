<template>
  <v-row class="main-contents mt-12 pa-6" no-gutters justify="center">
    <v-col
      v-for="o in contents"
      :key="o.dataType"
      cols="12"
      md="4"
      class="mb-4"
      :style="{ maxWidth: $vuetify.breakpoint.mdAndUp ? '500px' : 'auto' }"
    >
      <v-card tile flat color="rgba(0,0,0,0)" class="mx-md-6 text-center">
        <div class="text-center">
          <v-img
            class="main-icon"
            :src="o.image.src"
            :alt="o.image.alt"
            contain
            aspect-ratio="1.7"
            @click="to(`/${$i18n.locale}${o.to}`)"
          ></v-img>
        </div>
        <div
          class="speech-bubble text-center mt-6 pa-4"
          @click="to(`/${$i18n.locale}${o.to}`)"
        >
          <h3>
            {{ $t(`title-${o.type}`) }}
          </h3>
          <h5>
            {{ $t(`subTitle-${o.type}`) }}
          </h5>
        </div>
        <div
          class="description mt-2 white--text"
          v-html="$t(`description-${o.type}`)"
        ></div>
      </v-card>
    </v-col>
  </v-row>
</template>
<i18n>
{
  "ja": {
    "title-0": "雨量予報",
    "title-1": "浸水リスク",
    "title-2": "ゲリラ豪雨予報",
    "subTitle-0": "Rainfall forecast",
    "subTitle-1": "Flood risk",
    "subTitle-2": "Guerrilla heavy rainfall forecast",
    "description-0": "36時間先までの雨量分布予報、<br/>累加雨量分布を5分間隔で配信。",
    "description-1": "ゲリラ豪雨による局所的な内水や、<br/>河川水位上昇に伴うバーチャル水面マップを表示",
    "description-2": "2ー3時間先までの豪雨情報。<br/>【関東・北陸・中部地域】"
  },
  "en": {
    "title-0": "Sequential rainfall forecast",
    "title-1": "Flood risk",
    "title-2": "\"Guerilla rainstorm\" forecast",
    "subTitle-0": " ",
    "subTitle-1": " ",
    "subTitle-2": " ",
    "description-0": "Delivering every 5 minutes, rainfall distribution forecast and cumulative rainfall distribution 36 hours ahead.",
    "description-1": "Real-time map of areas with a risk of storm water flooding or flooding due to rising river water levels.",
    "description-2": "Information on heavy rain for 2-3 hours ahead. <br/>[Kanto, Hokuriku, Chubu regions] "
  }
}
</i18n>
<script>
export default {
  name: "MainContents",
  data() {
    return {
      contents: [
        {
          type: "0",
          to: `/risk-map/@35.5,136,7z/data=0&subData=0`,
          image: {
            src: require("@/assets/images/views/top-view/main-contents-icon-rainfall.svg"),
            alt: "main-contents-icon-rainfall",
          },
        },
        {
          type: "1",
          to: `/risk-map/@35.5,136,7z/data=1&subData=0`,
          image: {
            src: require("@/assets/images/views/top-view/main-contents-icon-water-flood.svg"),
            alt: "main-contents-icon-water-flood",
          },
        },
        {
          type: "2",
          to: `/risk-map/@35.5,136,7z/data=2&subData=0`,
          image: {
            src: require("@/assets/images/views/top-view/main-contents-icon-guerrilla-rainstrom.svg"),
            alt: "main-contents-icon-guerrilla-rainstorm",
          },
        },
      ],
    };
  },
  methods: {
    to(location) {
      this.$router.push(
        location,
        () => {},
        () => {}
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.main-contents {
  .main-icon {
    cursor: pointer;
    filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.6));
  }
  .main-icon:hover {
    transform: scale(1.1);
    transition: transform ease 1s;
  }

  .speech-bubble {
    position: relative;
    cursor: pointer;
    background-color: #dbdce2;
    h3 {
      display: inline-block;
      box-shadow: inset 0px -10px yellow;
    }
    h5 {
      color: #6c757d;
    }
  }
  .speech-bubble:after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 1em solid transparent;
    border-bottom-color: rgb(219, 220, 226);
    border-top: 0;
    margin-left: -1em;
    margin-top: -1em;
  }
  .description {
    font-size: 14px;
    white-space: pre-line;
  }
}
</style>
