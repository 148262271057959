import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const browserLanguage = (() => {
  const lang = (
    navigator.userLanguage ||
    navigator.browserLanguage ||
    navigator.language
  ).substr(0, 2);

  switch (lang) {
    case "ja":
    case "en":
      return lang;
    default:
      return "en";
  }
})();

export default new VueI18n({
  locale: browserLanguage,
  fallbackLocale: "en",
});
