<template>
  <div>
    <l-polygon
      v-for="marker in observatoryMarkers"
      :key="marker.key"
      :lat-lngs="marker.marker"
      :fill-color="marker.color"
      :stroke="false"
      :fillOpacity="1"
      @click="onClickMarker(marker)"
    >
      <l-tooltip :options="{ direction: 'top' }">{{ marker.name }}</l-tooltip>
    </l-polygon>
    <observatories-chart-dialog ref="dialog"></observatories-chart-dialog>
  </div>
</template>

<script>
import observatories from "@/assets/json/observatories";
import ObservatoriesChartDialog from "@/components/risk-map-view/layers/ObservatoriesChartDialog";
export default {
  name: "ObservatoriesLayer",
  components: { ObservatoriesChartDialog },
  data() {
    return {
      observatoryMarkers: [],
    };
  },
  computed: {
    map() {
      return this.$store.state.map;
    },
  },
  created() {
    this.generateObservatoryMarkers();
  },
  mounted() {
    this.map.on("zoomend", () => {
      for (const m of this.observatoryMarkers) {
        m.marker = this.drawTriangleMarker(m.lat, m.lng);
      }
    });
  },
  methods: {
    generateObservatoryMarkers() {
      for (const ob of observatories) {
        const lat = parseFloat(ob.lat);
        const lng = parseFloat(ob.lng);
        const marker = this.drawTriangleMarker(lat, lng);

        this.observatoryMarkers.push({
          id: `${ob.observatory_id}`,
          name: ob.name,
          lat: lat,
          lng: lng,
          marker: marker,
          color: "#233559",
          river: ob.river,
        });
      }
    },
    drawTriangleMarker(lat, lng) {
      const length = this.$mq === "sm" ? 25 : 15;

      const basePoint = this.map.project([lat, lng], this.map.getZoom());
      const point1 = { x: basePoint.x - length / 2, y: basePoint.y };
      const point2 = { x: basePoint.x + length / 2, y: basePoint.y };
      const point3 = {
        x: basePoint.x,
        y: point1.y - (Math.sqrt(3) / 2) * length,
      };

      const latlng1 = this.map.unproject(point1, this.map.getZoom());
      const latlng2 = this.map.unproject(point2, this.map.getZoom());
      const latlng3 = this.map.unproject(point3, this.map.getZoom());

      return [latlng1, latlng2, latlng3];
    },
    onClickMarker(marker) {
      this.$refs.dialog.updateSelectedObservatory(marker);
    },
  },
};
</script>

<style>
.leaflet-tooltip-pane {
  z-index: 1000;
}
</style>
