const confs = {
    staging: {
      ctieChart: {
        key: "Xu8MChb7YM29GgMrOanCm1Yu2HKTb6cU5cAYGVqG",
      },
    },
    production: {
      ctieChart: {
        key: "7bYdZKi7IRauLWnQ6yaF86x0AcKeLtKQ1ShSoItW",
      },
    },
  },
  staticConf = {
    urls: {
      dataServer: "https://www.cti-rains.net",
    },
    site: {
      apiUrl: "https://www.cti-rains.net",
    },
    cyber: {
      apiUrl: "https://cyberjapandata.gsi.go.jp",
    },
  },
  env = require("./current.config"),
  current = env.current;
delete env.current;

module.exports = Object.assign(
  staticConf,
  env.custom ? env.custom : confs[current]
);
