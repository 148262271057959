<script>
export default {
  name: "RiskInfoBase",
};
</script>
<style lang="scss" scoped>
.info-header {
  padding-bottom: 20px;
  text-align: center;

  img {
    height: 50px;
    margin: 5px 0;
  }
}

.info-card {
  .info-subject {
    color: white;
    text-align: center;
    background-color: rgba(66, 72, 99, 1);
    border-radius: 10px;
    padding: 3px;
    margin-bottom: 15px;
    font-weight: bold;
  }
  p {
    text-align: left;
  }

  .level3 {
    padding: 2px;
    background: #cf4d4d;
  }
  .level2 {
    padding: 2px;
    background: #f2a007;
  }
  .level1 {
    padding: 2px;
    background: #4b68bf;
  }
}
</style>
