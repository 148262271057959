<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: "App",
  computed: {
    metaInfo() {
      return this.$store.state.metaInfo;
    },
  },
  metaInfo() {
    return this.metaInfo;
  },
};
</script>
<style>
body {
  height: 100vh;
  width: 100%;
}
</style>
