<template>
  <div
    class="mobile-bottom-sheet"
    :class="{ hide: !selectedTab }"
    v-click-outside="onClickOutside"
  >
    <v-row no-gutters align="end" justify="end">
      <v-btn
        v-for="tab in tabs"
        :key="tab.type"
        tile
        :small="selectedTab !== tab.type"
        depressed
        class="tabs"
        @click="onClickTab(tab.type)"
        :color="selectedTab === tab.type ? 'white' : 'rgba(66,72,99,0.8)'"
      >
        <v-icon
          small
          :color="selectedTab === tab.type ? 'rgba(66,72,99,0.8)' : 'white'"
        >
          {{ tab.icon }}
        </v-icon>
      </v-btn>
    </v-row>
    <div class="contents pa-6">
      <div v-if="selectedTab === 'tile'">
        <feature-controller></feature-controller>
      </div>
      <div v-if="selectedTab === 'warning'">
        <warning-status></warning-status>
      </div>
      <div v-if="selectedTab === 'info'">
        <risk-info :contents="selectedRisk"></risk-info>
      </div>
      <div v-if="selectedTab === 'setting'">
        <settings></settings>
      </div>
    </div>
  </div>
</template>

<script>
import FeatureController from "@/components/risk-map-view/FeatureController";
import WarningStatus from "@/components/risk-map-view/WarningStatus";
import RiskInfo from "@/components/risk-map-view/RiskInfo";
import Settings from "@/components/risk-map-view/Settings";
export default {
  name: "MobileBottomSheet",
  components: { Settings, RiskInfo, WarningStatus, FeatureController },
  data() {
    return {
      tabs: [
        { type: "tile", icon: " mdi-map" },
        { type: "warning", icon: " mdi-bell-alert" },
        { type: "info", icon: " mdi-help-circle" },
        { type: "setting", icon: " mdi-cog" },
      ],
      selectedTab: null,
    };
  },
  computed: {
    selectedRisk() {
      return this.$store.state.selectedRisk;
    },
  },
  methods: {
    onClickOutside() {
      this.selectedTab = null;
    },
    onClickTab(type) {
      if (this.selectedTab === type) {
        this.$nextTick(() => {
          this.selectedTab = null;
        });
      }
      this.selectedTab = type;
    },
  },
};
</script>

<style lang="scss" scoped>
$bottom-sheet-height: min(calc(100vh - 28px - 60px), 300px);

.mobile-bottom-sheet {
  -webkit-transform: none;
  transform: none;
  transition: transform ease 1s;
  .contents {
    height: $bottom-sheet-height;
    background-color: rgba(white, 0.92);
    overflow-y: scroll;
  }
}
.mobile-bottom-sheet.hide {
  -webkit-transform: translateY($bottom-sheet-height);
  transform: translateY($bottom-sheet-height);
}
.tabs {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
</style>
