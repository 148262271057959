<script>
export default {
  name: "ImageLayerBase",
  data() {
    return {
      riskType: null,
      imageInfo: {},
      imageView: [],
      mobileMaxZoom: 9,
    };
  },
  computed: {
    currentDate() {
      return this.$store.state.currentDate;
    },
    map() {
      return this.$store.state.map;
    },
    imageOpacity() {
      return this.$store.state.imageOpacity;
    },
    selectedDateRiskData() {
      return this.$store.getters.selectedDateRiskData;
    },
  },
  watch: {
    selectedDateRiskData(after, before) {
      if (after && before && after.type === before.type) {
        return;
      }
      this.updateImageView();
    },
    currentDate() {
      this.loadData();
    },
  },
  created() {
    this.loadData();
  },
  mounted() {
    this.map.on("zoomend", this.updateImageView);
    this.map.on("moveend", this.updateVisible);
  },
  beforeDestroy() {
    if (this.map) {
      this.map.off("zoomend", this.updateImageView);
      this.map.off("moveend", this.updateVisible);
    }
  },
  methods: {
    getScreenBottomLat() {
      const centerLatLng = this.map.getCenter();
      const pointC = this.map.latLngToContainerPoint(centerLatLng);
      const pointMinY = [pointC.x, pointC.y - window.innerHeight / 2];
      const latlng = this.map.containerPointToLatLng(pointMinY);
      return latlng.lat;
    },
    getScreenTopLat() {
      const centerLatLng = this.map.getCenter();
      const pointC = this.map.latLngToContainerPoint(centerLatLng);
      const pointMaxY = [pointC.x, pointC.y + window.innerHeight / 2];
      const latlng = this.map.containerPointToLatLng(pointMaxY);
      return latlng.lat;
    },
    getImgDivTopLat(divTopLatLng, divHeight) {
      const pointDivTop = this.map.latLngToContainerPoint(divTopLatLng); // convert to containerpoint (pixels)
      const pointY = [pointDivTop.x, pointDivTop.y + divHeight];

      const latlng = this.map.containerPointToLatLng(pointY);
      return latlng.lat;
    },
    isVisible(imgDivBottomLat, imgDivBottomLng, imgDivHeight) {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return true;
      }

      const imgDivTopLat = this.getImgDivTopLat(
        [imgDivBottomLat, imgDivBottomLng],
        imgDivHeight
      );
      const screenTopLat = this.getScreenTopLat();
      const screenBottomLat = this.getScreenBottomLat();

      if (imgDivTopLat < screenBottomLat && imgDivTopLat > screenTopLat) {
        return true;
      }
      if (imgDivBottomLat < screenBottomLat && imgDivBottomLat > screenTopLat) {
        return true;
      }
      if (imgDivBottomLat >= screenBottomLat && imgDivTopLat <= screenTopLat) {
        return true;
      }

      return false;
    },
  },
};
</script>

<style scoped>
.risk-image {
  /*image-rendering:-webkit-optimize-contrast;*/
  image-rendering: pixelated;
  -ms-interpolation-mode: nearest-neighbor;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: crisp-edges;
}
</style>
