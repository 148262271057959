<template>
  <div class="overview">
    <v-row no-gutters justify="center">
      <v-img
        class="risk-map-text mb-4 mt-12"
        alt="risk-map-text"
        :src="require('@/assets/images/views/top-view/risk-map-text.png')"
        contain
        :max-width="$vuetify.breakpoint.mdAndUp ? 500 : 270"
      />
    </v-row>
    <v-row no-gutters justify="center">
      <v-img
        class="pin-icon"
        alt="pin-icon"
        :src="require('@/assets/images/views/top-view/pin.png')"
        contain
        :max-width="$vuetify.breakpoint.mdAndUp ? 20 : 12"
      />
      <span v-t="'title'" class="text-h6 text-md-h5 font-weight-bold"></span>
    </v-row>
    <p
      v-t="'overview'"
      class="px-4 pa-md-0"
      :style="{ fontSize: $vuetify.breakpoint.mdAndUp ? '20px' : '12px' }"
    ></p>
  </div>
</template>
<i18n>
{
  "ja": {
    "title": "水災害発生リスクマップ",
    "overview": "ゲリラ豪雨・集中豪雨時等に、気になる水災害発生リスク情報をリアルタイムにマップ上でご覧いただけます。"
  },
  "en": {
    "title": "Water-related disaster Risk Map",
    "overview": "In case of extreme rain events, you can check out for the information on water-related disaster risk, real-time on the map."
  }
}
</i18n>

<script>
export default {
  name: "Overview",
};
</script>

<style lang="scss" scoped>
.overview {
  color: $base-font-color;
  text-align: center;
  .risk-map-text {
    animation: 0.5s linear slideInFromTop;
  }
  @keyframes slideInFromTop {
    0% {
      transform: translateY(-70%);
    }
    100% {
      transform: translateY(0);
    }
  }
  .pin-icon {
    height: 40px;
    margin-right: 5px;
    animation: floating 2s ease-in-out infinite;
    vertical-align: middle;
    -ms-animation: none;
  }
  @keyframes floating {
    from {
      transform: translate(0, 0px);
    }
    50% {
      transform: translate(0, -15px);
    }
    to {
      transform: translate(0, -0px);
    }
  }
}
</style>
