<template>
  <div>
    <l-geo-json
      v-if="damWB"
      :geojson="damWB"
      :options="{ onEachFeature: onEachFeature }"
      :optionsStyle="getWBStyle"
    ></l-geo-json>
    <l-geo-json
      v-if="damPoint"
      :geojson="damPoint"
      :options="{ onEachFeature: onEachFeature, pointToLayer: pointToLayer }"
    ></l-geo-json>
  </div>
</template>

<script>
import L from "leaflet";

import axios from "axios";
import conf from "@/config";

export default {
  name: "RiskmaModeLayer",
  props: {
    riskmaMode: {
      default: null,
      type: String,
      require: false,
    },
  },
  data() {
    return {
      damPoint: null,
      damWB: null,
    };
  },
  async created() {
    const damWB = await axios.get(
      `${conf.site.apiUrl}/data/riskma_mode/getDamWB.php`,
      { params: { area: this.riskmaMode } }
    );

    const damPoint = await axios.get(
      `${conf.site.apiUrl}/data/riskma_mode/getDamPoint.php`,
      { params: { area: this.riskmaMode } }
    );

    this.damWB = await damWB.data;
    this.damPoint = await damPoint.data;
  },
  methods: {
    async onEachFeature(feature, layer) {
      layer.on("click", async (e) => {
        const temp =
          e.target.feature.properties["ダム名"] ||
          e.target.feature.properties["NAME"] ||
          e.target.feature.properties["name"];

        const popup = L.popup().setContent(temp);
        popup.on("remove", () => {
          layer.unbindPopup();
        });
        layer.bindPopup(popup).openPopup();
      });
    },
    pointToLayer(feature, latlng) {
      let iconUrl = require("@/assets/images/views/risk-map-view/dam.png");
      if (
        feature.properties.category &&
        feature.properties.category === "gauge"
      ) {
        iconUrl = require("@/assets/images/views/risk-map-view/gauge.png");
      }

      let icon = L.icon({
        iconUrl: iconUrl,
        iconSize: [20, 20],
        popupAnchor: [0, -20],
      });

      return L.marker(latlng, { icon: icon });
    },
    getWBStyle() {
      return {
        color: "black",
        opacity: 1,
        fillOpacity: 0.1,
      };
    },
  },
};
</script>

<style>
.leaflet-overlay-pane {
  z-index: 500 !important;
}
</style>
