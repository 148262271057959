<template>
  <div>
    <l-marker v-if="currentLocation" :lat-lng="currentLocation">
      <l-icon :icon-size="[20, 40]" :icon-anchor="[10, 40]">
        <div>
          <v-icon x-large class="current-location" color="warning">
            mdi-map-marker
          </v-icon>
        </div>
      </l-icon>
    </l-marker>
  </div>
</template>

<script>
export default {
  name: "CurrentLocationMarker",
  computed: {
    map() {
      return this.$store.state.map;
    },
    currentLocation() {
      return this.$store.state.currentLocation;
    },
  },
  watch: {
    currentLocation() {
      this.map.panTo(this.currentLocation);
    },
  },
};
</script>

<style scoped>
.current-location {
  filter: drop-shadow(0 10px 3px black);
}
</style>
