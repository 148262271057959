<template>
  <div class="pc-map-legend">
    <div v-if="selectedRisk !== RISK.VWS">
      <div v-if="selectedRiskLegend" class="text-right">
        {{ selectedRiskLegend.unit }}
      </div>
      <v-row v-if="selectedRisk !== null" no-gutters>
        <template v-if="selectedRisk === RISK.RT">
          <v-col
            v-for="(o, index) in selectedRiskLegend.legend[
              selectedDateRiskDataIndex
            ]"
            :key="index"
            class="color-box"
            :style="{ backgroundColor: o.color }"
          >
            {{ o.value }}
          </v-col>
        </template>
        <v-col
          v-else
          v-for="(o, index) in selectedRiskLegend.legend"
          :key="index"
          class="color-box"
          :style="{ backgroundColor: o.color }"
        >
          {{ o.value }}
        </v-col>
      </v-row>
    </div>
    <div v-else class="vws-area pa-2">
      <v-row no-gutters>
        <v-col cols="6">
          <v-img
            :src="
              require('@/assets/images/views/risk-map-view/virtual-water-surface-legend.png')
            "
            contain
            height="80px"
          ></v-img>
        </v-col>
        <v-col>
          <v-row no-gutters align="center">
            <v-icon color="#559944">mdi-crop-square</v-icon>
            <span v-t="'alluvialFan'"></span>
          </v-row>
          <v-row no-gutters align="center">
            <v-icon color="#35fe23">mdi-crop-square</v-icon>
            <span v-t="'AreasBelowSeaLevel'"></span>
          </v-row>
          <v-row no-gutters align="center">
            <v-icon color="#2824f2">mdi-minus</v-icon>
            <span v-t="'targetRiver'"></span>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<i18n>
{
  "ja": {
    "low": "低",
    "high": "高",
    "risk": "危険度",
    "targetRiver": "対象河川",
    "alluvialFan": "扇状地",
    "AreasBelowSeaLevel": "海抜0m地帯"
  },
  "en": {
    "low": "Low",
    "high": "High",
    "risk": "Risk",
    "targetRiver": "target river",
    "alluvialFan": "alluvial fan",
    "AreasBelowSeaLevel": "areas below sea level"
  }
}
</i18n>
<script>
import { RISK } from "@/enums/Risk";
import _ from "lodash";

export default {
  name: "PcMapLegend",
  data() {
    return {
      RISK: RISK,
      legends: [
        {
          type: RISK.RR,
          legend: [
            { color: "#ba206e", value: 80 },
            { color: "#ff3700", value: 50 },
            { color: "#ffa021", value: 30 },
            { color: "#fbf43c", value: 20 },
            { color: "#004efb", value: 10 },
            { color: "#2595fd", value: 5 },
            { color: "#a6d5fe", value: 1 },
            { color: "#ffffff", value: 0 },
          ],
          unit: "mm/hr",
        },
        {
          type: RISK.RT,
          legend: [
            [
              { color: "#840140", value: 100 },
              { color: "#f422ac", value: 75 },
              { color: "#e3081e", value: 50 },
              { color: "#f56538", value: 30 },
              { color: "#f3f505", value: 20 },
              { color: "#3606ec", value: 10 },
              { color: "#5eedfa", value: 1 },
            ],
            [
              { color: "#840140", value: 300 },
              { color: "#f422ac", value: 250 },
              { color: "#e3081e", value: 200 },
              { color: "#f56538", value: 150 },
              { color: "#f3f505", value: 100 },
              { color: "#3606ec", value: 50 },
              { color: "#5eedfa", value: 0 },
            ],
            [
              { color: "#840140", value: 450 },
              { color: "#f422ac", value: 375 },
              { color: "#e3081e", value: 300 },
              { color: "#f56538", value: 225 },
              { color: "#f3f505", value: 150 },
              { color: "#3606ec", value: 75 },
              { color: "#5eedfa", value: 0 },
            ],
            [
              { color: "#840140", value: 600 },
              { color: "#f422ac", value: 500 },
              { color: "#e3081e", value: 400 },
              { color: "#f56538", value: 300 },
              { color: "#f3f505", value: 200 },
              { color: "#3606ec", value: 100 },
              { color: "#5eedfa", value: 0 },
            ],
            [
              { color: "#840140", value: 900 },
              { color: "#f422ac", value: 750 },
              { color: "#e3081e", value: 600 },
              { color: "#f56538", value: 450 },
              { color: "#f3f505", value: 300 },
              { color: "#3606ec", value: 150 },
              { color: "#5eedfa", value: 0 },
            ],
            [
              { color: "#840140", value: 1200 },
              { color: "#f422ac", value: 1000 },
              { color: "#e3081e", value: 800 },
              { color: "#f56538", value: 600 },
              { color: "#f3f505", value: 400 },
              { color: "#3606ec", value: 200 },
              { color: "#5eedfa", value: 0 },
            ],
          ],
          unit: "mm",
        },
        {
          type: RISK.FL,
          legend: [
            { color: "#fd2100", value: "高" },
            { color: "#faf735", value: "" },
            { color: "#2589fc", value: "" },
            { color: "#ffffff", value: "低" },
          ],
          unit: "危険度",
        },
        {
          type: RISK.VWS,
          legend: [
            { color: "#8c32f0", value: 5.0 },
            { color: "#f032f0", value: 4.0 },
            { color: "#f0328c", value: 3.0 },
            { color: "#f03c32", value: 2.0 },
            { color: "#f1a031", value: 1.0 },
            { color: "#dcf032", value: 0 },
            { color: "#32c8f0", value: -1.0 },
          ],
          unit: "m",
        },
        {
          type: RISK.GR,
          legend: [
            { color: "#ba206e", value: 80 },
            { color: "#ff3700", value: 50 },
            { color: "#ffa021", value: 30 },
            { color: "#fbf43c", value: 20 },
            { color: "#004efb", value: 10 },
            { color: "#2595fd", value: 5 },
            { color: "#a6d5fe", value: 1 },
            { color: "#ffffff", value: 0 },
          ],
          unit: "mm/hr",
        },
      ],
    };
  },
  computed: {
    selectedRisk() {
      return this.$store.state.selectedRisk;
    },
    selectedRiskLegend() {
      return _.find(this.legends, { type: this.selectedRisk });
    },
    selectedDateRiskDataIndex() {
      return this.$store.getters.selectedDateRiskDataIndex;
    },
  },
};
</script>

<style lang="scss" scoped>
.pc-map-legend {
  font-size: 10px;
  max-width: 300px;
}
.color-box {
  text-align: center;
  line-height: 1.5;
  font-size: 10px;
  height: 15px;
  width: 30px;
  font-weight: bold;
}
.vws-area {
  background-color: rgba(white, 0.8);
  font-weight: bold;
  font-size: 12px;
}
</style>
