<template>
  <div class="logo">
    <a :href="`/${$i18n.locale}/top`">
      <v-img
        class="header-logo"
        :src="require('@/assets/images/views/common/header-logo.svg')"
        contain
        aspect-ratio="4"
      ></v-img>
    </a>
  </div>
</template>
<script>
export default {
  name: "PcLogo",
};
</script>

<style lang="scss" scoped>
.logo {
  @include map-controls-base;
}
</style>
