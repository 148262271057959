<template>
  <l-tile-layer
    :url="baseTileUrl()"
    layer-type="base"
    class="base-tile-filter"
  ></l-tile-layer>
</template>

<script>
export default {
  name: "BaseTileLayer",
  computed: {
    map() {
      return this.$store.state.map;
    },
  },
  methods: {
    baseTileUrl() {
      let baseTileUrl = "";
      if (
        this.$i18n.locale === "ja" ||
        (this.$i18n.locale !== "ja" && this.map.getZoom() >= 12)
      ) {
        baseTileUrl =
          "https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png";
      } else {
        baseTileUrl =
          "https://cyberjapandata.gsi.go.jp/xyz/english/{z}/{x}/{y}.png";
      }
      return baseTileUrl;
    },
  },
};
</script>

<style scoped></style>
