<template>
  <div>
    <template v-for="feature in featureLayers">
      <l-tile-layer
        v-if="selectedFeatures.includes(feature.type)"
        :key="feature.type"
        :url="feature.url"
        :maxZoom="feature.maxZoom"
        :minZoom="feature.minZoom"
        layer-type="overlay"
      ></l-tile-layer>
    </template>
  </div>
</template>

<script>
import { FEATURE } from "@/enums/Feature";
import conf from "@/config";
export default {
  name: "FeatureLayers",
  data() {
    return {
      featureLayers: [
        {
          type: FEATURE.RIVER,
          url: `${conf.urls.dataServer}/map/river_map/{z}/{x}/{y}.png`,
          minZoom: 6,
          maxZoom: 13,
        },
        {
          type: FEATURE.WATERSHED,
          url: `${conf.urls.dataServer}/map/basin_map/{z}/{x}/{y}.png`,
          minZoom: 6,
          maxZoom: 13,
        },
        {
          type: FEATURE.ADMIN,
          url: `${conf.urls.dataServer}/map/Admin_map/{z}/{x}/{y}.png`,
          minZoom: 6,
          maxZoom: 13,
        },
      ],
    };
  },
  computed: {
    selectedFeatures() {
      return this.$store.state.selectedFeatures;
    },
  },
};
</script>
