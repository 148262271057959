<script>
import { RISK } from "@/enums/Risk";
import RainLayerBase from "./base/RainLayerBase";

export default {
  name: "RainRadarLayer",
  extends: RainLayerBase,
  data() {
    return {
      riskType: RISK.RR,
      imageInfo: {
        hrnc: {
          coordinate: {
            east: 149,
            north: 46.666666666666666666666666666666,
            west: 120,
            south: 20.666666666666666666666666666666,
          },
          slicedLatHeight: 0.5,
          sliceCount: 52,
          mobileSlicedLatHeight: 5.213,
          mobileSliceCount: 5,
        },
        shortTerm: {
          coordinate: {
            east: 150,
            north: 48,
            west: 118,
            south: 20,
          },
          slicedLatHeight: 0.5,
          sliceCount: 56,
          mobileSlicedLatHeight: 5.588,
          mobileSliceCount: 5,
        },
        msm: {
          coordinate: {
            east: 150.03125,
            north: 47.625,
            west: 119.96875,
            south: 47.625,
          },
          slicedLatHeight: 0.505,
          sliceCount: 50,
          mobileSlicedLatHeight: 5.053,
          mobileSliceCount: 5,
        },
      },
    };
  },
};
</script>

<style scoped>
.risk-image {
  /*image-rendering:-webkit-optimize-contrast;*/
  image-rendering: pixelated;
  -ms-interpolation-mode: nearest-neighbor;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: crisp-edges;
}
</style>
