<template>
  <v-btn
    depressed
    tile
    icon
    color="#1474CE"
    class="gps-button"
    @click="moveCurrentLocation"
  >
    <v-progress-circular v-if="isLoading" indeterminate></v-progress-circular>
    <v-icon v-else>mdi-crosshairs-gps</v-icon>
  </v-btn>
</template>

<script>
import { UPDATE_CURRENT_LOCATION } from "@/store/mutation-types";

export default {
  name: "CurrentLocationButton",
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    updateCurrentLocation(currentLocation) {
      this.$store.commit(UPDATE_CURRENT_LOCATION, { currentLocation });
    },
    moveCurrentLocation() {
      this.isLoading = true;

      const success = (position) => {
        this.$root.$emit(
          "systemMessage",
          "info",
          this.$t("locationInfoSuccess")
        );
        const latlng = [position.coords.latitude, position.coords.longitude];
        this.updateCurrentLocation(latlng);

        this.isLoading = false;
      };

      const error = () => {
        this.$root.$emit(
          "systemMessage",
          "error",
          this.$t("locationInfoFailed")
        );
        this.updateCurrentLocation(null);

        this.isLoading = false;
      };

      const options = {
        maximumAge: Infinity,
        timeout: 5000,
        enableHighAccuracy: false,
      };

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(success, error, options);
      } else {
        this.$root.$emit(
          "systemMessage",
          "error",
          this.$t("unableGetLocationInfo")
        );
      }
    },
  },
};
</script>

<style scoped></style>
