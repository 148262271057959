<template>
  <div>
    <div class="info-header">
      <div>
        <h2 v-t="'cumulativeRainfallTitle'"></h2>
      </div>
    </div>
    <div class="info-card">
      <div class="info-subject" v-t="'cumulativeRainfallTitle'"></div>
      <p v-t="'cumulativeRainfallOverview'"></p>
    </div>
  </div>
</template>
<i18n>
{
  "ja": {
    "cumulativeRainfallTitle": "累加雨量表示",
    "cumulativeRainfallOverview": "本ページの累加雨量値は、気象庁の高解像度ナウキャスト解析値(気象レーダから推定した実績値)を積算したものになります。このため、あるメッシュにおける累加雨量値は、同じ場所の地上雨量観測所の累加雨量値と比較して、過大もしくは過小となる場合があります。ご覧の際は、この点につきまして十分ご理解の上、ご利用下さい。"
  },
  "en": {
    "cumulativeRainfallTitle": "The cumulative rainfall",
    "cumulativeRainfallOverview": "The cumulative rainfall on this page is the total sum of data (the actual sum estimated from the meteorological radar) collected on the High-Resolution Precipitation Nowcast by the Japan Meteorological Agency. Therefore, the cumulative rainfall data estimate within the specific mesh may vary compared to the data collected at the actual rain gauge station of the same location. Please note these characteristics when using this forecast."
  }
}
</i18n>

<script>
import RiskInfoBase from "./RiskInfoBase";
export default {
  name: "RainTotalInfo",
  extends: RiskInfoBase,
};
</script>
