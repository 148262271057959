<template>
  <button id="go-to-top-button" @click="onGoToTopButton">
    <div id="water">
      <img
        alt=""
        class="pc-image"
        src="../../assets/images/views/top-view/go-to-top-button.png"
      />
    </div>
  </button>
</template>

<script>
export default {
  name: "ScrollTopButton",
  props: ["container"],
  methods: {
    onGoToTopButton() {
      this.container.scrollIntoView({ block: "start", behavior: "smooth" });
    },
  },
};
</script>

<style lang="scss" scoped>
#go-to-top-button {
  width: 70px;
  height: 70px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  padding: 0;
  z-index: 1000;
}

#go-to-top-button #water {
  width: 70px;
  height: 70px;
  position: absolute;
  opacity: 0.5;
  bottom: 0;
  background-image: url("@/assets/images/views/top-view/waves.png");
  background-position: top right;
  background-position-y: 3em;
  background-repeat: no-repeat;

  -webkit-transition: all 2s ease;
  -moz-transition: all 2s ease;
  -o-transition: all 2s ease;
  transition: all 2s ease;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

#go-to-top-button:hover #water {
  bottom: 0;
  background-position: top left;
  background-position-y: -6em;
  -webkit-transition: all 2s ease;
  -moz-transition: all 2s ease;
  -o-transition: all 2s ease;
  transition: all 2s ease;
}

#go-to-top-button #water {
  opacity: 1;
}
</style>
