<template>
  <div ref="agreement" class="agreement-page pa-12">
    <div class="contents">
      <h1 v-t="'title'"></h1>
      <p v-t="'overview'"></p>
      <br />

      <h3 v-t="'term1Title'"></h3>
      <p v-t="'term1Text'"></p>

      <h3 v-t="'term2Title'"></h3>
      <p v-t="'term2Text'"></p>

      <h3 v-t="'term3Title'"></h3>
      <p v-t="'term3Text'"></p>

      <h3 v-t="'term4Title'"></h3>
      <p v-t="'term4Text'"></p>

      <h3 v-t="'term5Title'"></h3>
      <p v-html="$t('term5Text')"></p>

      <h3 v-t="'term6Title'"></h3>
      <p v-html="$t('term6Text')"></p>

      <h3 v-t="'term7Title'"></h3>
      <p v-t="'term7Text'"></p>

      <h3 v-t="'term8Title'"></h3>
      <p v-t="'term8Text'"></p>

      <h3 v-t="'term9Title'"></h3>
      <p v-html="$t('term9Text')"></p>

      <h3 v-t="'term10Title'"></h3>
      <p v-t="'term10Text'"></p>

      <h3 v-t="'term11Title'"></h3>
      <p v-t="'term11Text'"></p>

      {{ $t("enactmentDate") }}
    </div>
  </div>
</template>

<script>
export default {
  name: "AgreementView",
};
</script>
<style scoped>
.agreement-page {
  color: #ffffff;
}
</style>

<i18n>
{
    "ja": {
        "title": "RisKma利用規約",
        "overview": "株式会社建設技術研究所（以下、「当社」といいます）が運営するRisKma（http://www.riskma.net/、以下、「本ウェブサイト」といいます）をご利用いただくにあたっては、ユーザーのみなさま（以下、「利用者」といいます）に、当社が定める以下の利用規約（以下、「本規約」といいます）にご同意いただく必要があります。利用者が、本ウェブサイトを利用されたときは、利用者は本規約に同意したものとみなします。",
        "term1Title": "第1条（目的）",
        "term1Text": "本規約は、利用者が本ウェブサイトを利用するにあたって必要な事項を定めるものです。",
        "term2Title": "第2条（免責事項）",
        "term2Text": "利用者は、本ウェブサイトが提供する気象予測などのコンテンツ（以下、「本コンテンツ」といいます）について、当社がその正確性を保証できないものであることを承諾し、自己の判断と責任において本ウェブサイトを利用するものとします。このため、当社は、利用者が本ウェブサイト及び本コンテンツを利用したことにより、当該利用者または第三者に直接または間接を問わず損害が発生したとしても、一切の責任を負いません。また、当社は、理由の如何にかかわらず、本コンテンツの内容の変更、または本コンテンツの提供の遅延・中断もしくは終了によって利用者に直接または間接を問わず損害が発生したとしても、一切の責任を負いません。",
        "term3Title": "第3条（著作権等）",
        "term3Text": "本ウェブサイト及び本コンテンツに関する著作権等の権利は、特に記載のない限り当社に帰属します。本ウェブサイトで表示される河川、流域界、行政界データは、国土交通省の「国土数値情報」のデータを当社の責任において編集・加工したものです。",
        "term4Title": "第4条（リンクについて）",
        "term4Text": "本ウェブサイトへのリンクは、原則として自由です。ただし、事業提携や事業主体の誤認などを生じさせるような形式でのリンクはできません。なお、トップページ（http://www.riskma.net/）以外は、事前の通知なく変更されることがあります。また、営利を目的としている場合、当社のイメージまたは名誉を棄損するものと当社が判断したときは、当社は当該リンクをお断りすることがあります。",
        "term5Title": "第5条（禁止行為）",
        "term5Text": "利用者は、本ウェブサイトの利用にあたって、以下の各号の行為を行わないものとします。 法令に違反する行為またはそのおそれのある行為 当社または第三者の著作権、知的財産権、その他の権利等を侵害し、またはそのおそれのある行為 有害なコンピュータプログラム等を送信するなど本ウェブサイトの運営を妨げる行為 本ウェブサイトにおいて保有し管理する情報、本ウェブサイト本コンテンツ及び本コンテンツ本ウェブサイトの画面を、当社の事前の書面による承諾なく、複製、翻訳、翻案、放送、出版、販売、貸与等私的利用の範囲を超えて使用する行為 本ウェブサイト及び本コンテンツを改ざんする行為 公序良俗に反する情報を掲載する行為 営利目的の投稿行為 その他、当社が不適切と判断する行為",
        "term6Title": "第6条（当社による利用の停止措置）",
        "term6Text": "当社は、利用者が以下の各号のいずれかに該当する場合は、事前に通知なく、当該利用者の本ウェブサイトの利用を停止する場合があります。利用者が本規約に違反する行為を行った場合 利用者が本ウェブサイト利用にあたり必要な当社からの指示に従わない場合 その他、不適切な利用であると当社が判断した場合",
        "term7Title": "第7条（損害賠償）",
        "term7Text": "利用者は、本ウェブサイトの利用に関して第三者に対して損害を発生させた場合は、自己の費用と責任においてこれを解決するものします。利用者が当社に損害を与えた場合は、利用者は当社に対し損害賠償義務を負うものとします。",
        "term8Title": "第8条（個人情報保護）",
        "term8Text": "当社は、本ウェブサイトのメールサービスに利用するため、利用者のメールアドレスを取得することがあります。当社は、取得した個人情報を法令の定めに従い、厳重な管理下のもと取り扱うこととし、法令の定めによる場合を除き、利用者の承諾なく第三者に開示・提供することはありません。",
        "term9Title": "第9条（本ウェブサイトの一時停止）",
        "term9Text": "当社は、以下の各号のいずれかの事由が生じた場合には、利用者に事前に通知することなく一時的に本ウェブサイトの運営を一時的に停止できるものとします。なお、当社は、これを起因として、当該利用者または第三者に直接または間接を問わず損害が発生したとしても、一切の責任を負いません。本ウェブサイトに関するシステムの保守を定期的にまたは緊急に行う場合 障害等により通信回線の利用ができない場合 火災、停電、地震、噴火、洪水及び津波等の不可抗力により本ウェブサイトの運用ができなくなった場合 その他、運用上あるいは技術上、本ウェブサイトの一時的な中断が必要と判断した場合",
        "term10Title": "第10条（本ウェブサイトの終了）",
        "term10Text": "当社は、事前に本ウェブサイト上で利用者に通知の上、本ウェブサイトの運営を終了することができるものとします。なお、当社は、これを起因として、当該利用者または第三者に直接または間接を問わず損害が発生したとしても、一切の責任を負いません。",
        "term11Title": "第11条（規約の変更）",
        "term11Text": "当社は、本規約を随時変更・改訂することができることとします。変更した規約の効力は、特段の定めをした場合を除き、即時に発効するものとします。",
        "enactmentDate": "制定日 2017年 3月 16日"
    },
    "en": {
        "title": "RisKma TERMS AND CONDITIONS",
        "overview": "To use RisKma (http://www.riskma.net/, hereinafter referred to as “Website”) operated by CTI Engineering Co., Ltd. (“CTIE”), all users (hereinafter referred to as “User”) are required to agree to the following terms and conditions (“Terms”) stipulated by CTIE. By accessing or using the Website, the User agrees to be bound by the Terms herein.",
        "term1Title": "1. PURPOSE",
        "term1Text": "The Terms stipulate the terms and conditions for the use of the Website.",

        "term2Title": "2. DISCLAIMER",
        "term2Text": "The User acknowledges that CTIE does not guarantee the accuracy of the content provided by the Website such as weather forecast (“Content”) and agrees to use the Website at the User’s own discretion and responsibility. CTIE shall not be liable for any direct or indirect damage caused to the User or a third party using the Website or the Content. In addition, regardless of the reason, CTIE shall not be liable for any direct or indirect damage caused to the User by any changes to the Content, or delays, interruptions or termination of the provision of the Content.",

        "term3Title": "3. COPYRIGHT AND OTHER RIGHTS",
        "term3Text": "Copyrights and other rights relating to the Website and the Content belong to CTIE unless otherwise stated. Rivers, watersheds, administrative districts data displayed on the Website are compiled and processed on CTIE’s responsibility from the “National Land Information” published by the Ministry of Land, Infrastructure, Transport and Tourism, JAPAN.  ",
        "term4Title": "4. LINK TO THE WEBSITE",
        "term4Text": "Links to the Website are free in principle. However, links in a form that could lead to business alliance or misidentification of the business entity are not allowed. The Website (except the top page, http://www.riskma.net/) is subject to change without prior notice. CTIE may decline the links for commercial purposes or if CTIE determines that the links would damage CTIE’s reputation or character.  ",

        "term5Title": "5. PROHIBITED ACT",
        "term5Text": "The User shall not engage in any of the following actions when using the Website.<br>・Acts that violate or may violate laws and regulations in Japan;<br>・Acts that infringe or may infringe on the copyright, intellectual property rights, or other rights of CTIE or a third party;<br>・Acts that obstruct the operation of the Website, such as the transmission of harmful computer programs;<br>・Acts of using information held and managed on the Website, the Website Content, and the screens of the Website Content beyond the scope of private use, such as reproduction, translation, adaptation, broadcasting, publication, sale and lending, without CTIE’s prior written consent;<br>・Acts of tempering with the Website and the Content;<br>・Acts of posting information that is contrary to public order and morality;<br>・Acts of posting for commercial purposes; and<br>・Any other acts deemed inappropriate by CTIE.",

        "term6Title": "6. SUSPENTION OF USER BY CTIE",
        "term6Text": "CTIE may suspend the use of the Website without prior notice if the User falls under any of the following items.<br>・When the User has committed an act that violates the Terms;<br>・If the User does not follow the instructions from CTIE necessary for the Website use; and<br>・Any other use, when CTIE determines that such use is inappropriate. ",

        "term7Title": "7. COMPENSATION FOR DAMAGES  ",
        "term7Text": "If the User causes damage to a third party in connection with the use of the Website, the User shall solve it at own expense and responsibility. If the User causes any damage to CTIE, the User shall be liable to compensate CTIE for the damages.  ",

        "term8Title": "8. PROTECTION OF PERSONAL INFORMATION ",
        "term8Text": "CTIE may obtain the User’s e-mail address for the e-mail service of the Website. CTIE will handle personal information under strict control in accordance with laws and regulations in Japan, and will not disclose or provide such information to any third party without the consent of the User, unless otherwise required by the laws and regulations in Japan.  ",

        "term9Title": "9. SUSPENSION OF THE WEBSITE ",
        "term9Text": "CTIE may temporarily suspend the operation of the Website without prior notice in the event of any of the following events. In no event shall CTIE be liable for any damages, whether direct or indirect, to the User or third parties incurred by the temporarily suspension of the Website. <br>・To maintain the system of the Website on a regular or emergency basis;<br>・When communication line cannot be used due to failure, etc.; <br>・In the event that the Website cannot be operated due to force majeure such as fire, power failure, earthquake, eruption, flood or tsunami, etc.; and <br>・In the event that it is deemed necessary to suspend the Website temporarily for operational or technical reasons.  ",

        "term10Title": "10. TERMINATION OF THE WEBSITE ",
        "term10Text": "CTIE may terminate the operation of the Website upon prior notice to the User on the Website. In no event shall CTIE be liable for any damages, whether direct or indirect, to the User or third parties incurred by the termination of the Website.  ",

        "term11Title": "11. CHANGES TO THE TERMS AND CONDITIONS ",
        "term11Text": "CTIE may change or revise the Terms at any time. Unless otherwise provided, the revised Terms shall become effective immediately. ",

        "enactmentDate": "ENACTMENT DATE: 16 March 2017"
    }
}
</i18n>
