import { render, staticRenderFns } from "./FeatureController.vue?vue&type=template&id=323464d8&scoped=true&"
import script from "./FeatureController.vue?vue&type=script&lang=js&"
export * from "./FeatureController.vue?vue&type=script&lang=js&"
import style0 from "./FeatureController.vue?vue&type=style&index=0&id=323464d8&prod&lang=scss&"
import style1 from "./FeatureController.vue?vue&type=style&index=1&id=323464d8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "323464d8",
  null
  
)

/* custom blocks */
import block0 from "./FeatureController.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports