<template>
  <v-row justify="center" no-gutters class="mobile-current-info">
    <v-col cols="12" v-if="selectedRisk !== null" class="text-center">
      <div class="selected-risk py-1 px-2 mb-1">
        {{ $t(`risk-${selectedRisk}`) }}
      </div>
    </v-col>
  </v-row>
</template>
<script>
import PcCurrentInfo from "@/components/risk-map-view/pc/PcCurrentInfo";
export default {
  name: "mobileCurrentInfo",
  extends: PcCurrentInfo,
};
</script>
<style lang="scss">
.mobile-current-info {
  width: 100%;
  .selected-risk {
    width: fit-content;
    margin: auto;
    background-color: $base-color-alpha;
    color: white;
    font-size: 20px;
    border-radius: 10px;
    font-weight: bold;
  }
}
</style>
