<script>
import { RISK } from "@/enums/Risk";
import RainLayerBase from "./base/RainLayerBase";
export default {
  name: "RainTotalLayer",
  extends: RainLayerBase,
  data() {
    return {
      riskType: RISK.RT,
      imageParts: [],
      imageInfo: {
        cumulative: {
          coordinate: {
            east: 149,
            north: 46.666666666666666666666666666666,
            west: 120,
            south: 20.666666666666666666666666666666,
          },
          slicedLatHeight: 0.5,
          sliceCount: 52,
          mobileSlicedLatHeight: 5.213,
          mobileSliceCount: 5,
        },
      },
    };
  },
};
</script>
