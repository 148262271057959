<template>
  <div class="mobile-map-controller">
    <div class="controls-area">
      <div class="controls top left">
        <mobile-logo></mobile-logo>
      </div>
      <div class="controls top right">
        <mobile-reload-button></mobile-reload-button>
      </div>
      <div class="controls left bottom">
        <mobile-map-legend></mobile-map-legend>
        <v-row no-gutters class="mt-2">
          <v-col cols="auto" class="mr-2">
            <mobile-risk-controller></mobile-risk-controller>
          </v-col>
          <v-col>
            <mobile-current-info></mobile-current-info>
          </v-col>
        </v-row>
      </div>
      <div class="controls right bottom">
        <current-location-button></current-location-button>
        <zoom-controller></zoom-controller>
      </div>
      <div class="controls date-controller-area">
        <mobile-bottom-sheet></mobile-bottom-sheet>
        <mobile-date-controller></mobile-date-controller>
      </div>
    </div>
  </div>
</template>

<script>
import MobileLogo from "@/components/risk-map-view/mobile/MobileLogo";
import MobileCurrentInfo from "@/components/risk-map-view/mobile/MobileCurrentInfo";
import MobileRiskController from "@/components/risk-map-view/mobile/MobileRiskController";
import MobileDateController from "@/components/risk-map-view/mobile/MobileDateController";
import ZoomController from "@/components/risk-map-view/ZoomController";
import MobileMapLegend from "@/components/risk-map-view/mobile/MobileMapLegend";
import MobileBottomSheet from "@/components/risk-map-view/mobile/MobileBottomSheet";
import CurrentLocationButton from "@/components/risk-map-view/mobile/CurrentLocationButton";
import MobileReloadButton from "@/components/risk-map-view/mobile/MobileReloadButton";
export default {
  name: "MobileThirdPartyMapController",
  components: {
    MobileReloadButton,
    CurrentLocationButton,
    MobileBottomSheet,
    MobileMapLegend,
    ZoomController,
    MobileDateController,
    MobileRiskController,
    MobileCurrentInfo,
    MobileLogo,
  },
};
</script>
<style lang="scss">
.mobile-map-controller {
  .row {
    line-height: 1;
  }
}
</style>
<style lang="scss" scoped>
$date-controller-height: 80px;
.mobile-map-controller {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  z-index: 1000;
  .controls-area {
    width: 100%;
    height: 100%;
    display: inline-block;
    position: relative;
    .controls {
      position: absolute;
      > * {
        pointer-events: auto;
        position: relative;
        clear: both;
      }
    }
    .top {
      top: 10px;
      > * {
        margin-left: 10px;
      }
    }
    .left {
      left: 0;
      text-align: left;
      > * {
        float: left;
      }
    }
    .right {
      right: 0;
      text-align: right;
      > * {
        float: right;
      }
    }
    .bottom {
      bottom: $date-controller-height;
      > * {
        margin: 0 10px;
      }
    }
    .center {
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      > * {
        float: left;
        margin: 0;
      }
    }
    .date-controller-area {
      bottom: 0;
      > * {
        margin: 0;
      }
    }
  }
}
</style>
