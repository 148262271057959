<template>
  <div class="warning-status text-center">
    <div class="section py-6">
      <div>
        <h2>
          {{ $t("waterFloodArea") }}
        </h2>
      </div>
      <div class="pa-2" v-if="statusList.first">
        <warning-status-layout :data="statusList.first"></warning-status-layout>
        <div v-if="statusList.first && statusList.first.status.length === 0">
          {{ $t("waterFloodAreaAnnounce") }}
        </div>
      </div>
    </div>
    <div class="section py-6">
      <h2>
        {{ $t("whetherAlert") }}
      </h2>
      <h4>
        （
        <a href="https://www.jma.go.jp/jp/warn/" target="_blank">
          {{ $t("meteorologicalAgency") }}
        </a>
        ）
      </h4>

      <div class="pa-2" v-if="statusList.second">
        <warning-status-layout
          :data="statusList.second"
        ></warning-status-layout>
        <div v-if="statusList.second && statusList.second.status.length === 0">
          {{ $t("waterFloodAreaAnnounce") }}
        </div>
      </div>
    </div>
  </div>
</template>
<i18n>
{
  "ja": {
    "waterFloodArea": "内水予測地域",
    "waterFloodAreaAnnounce": "内水予測エリアはありません",
    "whetherAlert": "現在発表中の警報",
    "meteorologicalAgency": "気象庁",
    "alertAnnounce": "警報発表中の地域はありません"
  },
  "en": {
    "waterFloodArea": "Stormwater flooding predicted area",
    "waterFloodAreaAnnounce": "There is no flooding predicted area.",
    "whetherAlert": "Currently announced warning",
    "meteorologicalAgency": "Japan Meteorological Agency",
    "alertAnnounce": "Currently there is no aera with a warning."
  }
}
</i18n>
<script>
import { RISK } from "@/enums/Risk";
import WarningStatusLayout from "@/components/risk-map-view/WarningStatusLayout";
export default {
  name: "WarningStatus",
  components: { WarningStatusLayout },
  data() {
    return {
      floodLevels: [
        {
          value: 0,
          color: "#0086ff",
          fontColor: "white",
          label: "Lv 1",
          engLabel: "Lv 1",
        },
        {
          value: 1,
          color: "#f8ff00",
          fontColor: "black",
          label: "Lv 2",
          engLabel: "Lv 2",
        },
        {
          value: 2,
          color: "#f20022",
          fontColor: "white",
          label: "Lv 3",
          engLabel: "Lv 3",
        },
      ],
      rainLevels: [
        {
          value: 1,
          color: "#e20f0f",
          fontColor: "white",
          label: "警報",
          engLabel: "Warning",
        },
        {
          value: 2,
          color: "#e400ff",
          fontColor: "white",
          label: "特別警報",
          engLabel: "Emergency Warning",
        },
      ],
      guerrillaLevels: [
        {
          value: 0,
          color: "#ffdb59",
          fontColor: "black",
          label: "注意報",
          engLabel: "Advisory",
        },
        {
          value: 1,
          color: "#e20f0f",
          fontColor: "white",
          label: "警報",
          engLabel: "Warning",
        },
        {
          value: 2,
          color: "#e400ff",
          fontColor: "white",
          label: "特別警報",
          engLabel: "Emergency Warning",
        },
      ],
      statusList: {
        first: null,
        second: null,
      },
    };
  },
  computed: {
    selectedRisk() {
      return this.$store.state.selectedRisk;
    },
    rainAreaStatus() {
      return this.$store.state.rainAreaStatus;
    },
    floodAreaStatus() {
      return this.$store.state.floodAreaStatus;
    },
    guerrillaRainAreaStatus() {
      return this.$store.state.guerrillaRainAreaStatus;
    },
    baseDate() {
      return this.$store.state.baseDate;
    },
  },
  watch: {
    async baseDate() {
      await this.$store.dispatch("loadWarningStatus");
      this.setStatusList();
    },
  },
  async created() {
    await this.$store.dispatch("loadWarningStatus");
    this.setStatusList();
  },
  methods: {
    setStatusList() {
      switch (this.selectedRisk) {
        case RISK.RR:
        case RISK.RT:
        case RISK.FL:
        case RISK.VWS:
          this.statusList = {
            first: {
              status: this.floodAreaStatus,
              legend: this.floodLevels,
            },
            second: {
              status: this.rainAreaStatus,
              legend: this.rainLevels,
            },
          };
          break;
        case RISK.GR:
          this.statusList = {
            first: {
              status: this.floodAreaStatus,
              legend: this.floodLevels,
            },
            second: {
              status: this.guerrillaRainAreaStatus,
              legend: this.guerrillaLevels,
            },
          };
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.warning-status {
  a {
    color: #82b1ff;
  }
}
</style>
