<template>
  <div class="feature-controller py-4 text-center">
    <div>
      <h4 v-t="'translucency'"></h4>
      <v-slider v-model="opacity" step="0.1" :max="1" color="#82b1ff">
      </v-slider>
    </div>
    <div>
      <h4 v-t="'mapDisplay'"></h4>
      <v-row no-gutters>
        <v-col v-for="o in features" :key="o.type">
          <v-checkbox
            dense
            :label="$t(`feature-${o.type}`)"
            v-model="o.visible"
            hide-details
            color="#82b1ff"
            @change="onChangeFeature"
          ></v-checkbox>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<i18n>
{
  "ja": {
    "translucency": "データ濃色",
    "mapDisplay": "地図表示",
    "feature-0": "河川",
    "feature-1": "流域界",
    "feature-2": "行政界"
  },
  "en": {
    "translucency": "Translucency",
    "mapDisplay": "Map display",
    "feature-0": "River",
    "feature-1": "Watershed",
    "feature-2": "Administrative District"
  }
}
</i18n>
<script>
import _ from "lodash";
import {
  UPDATE_IMAGE_OPACITY,
  UPDATE_SELECTED_FEATURES,
} from "@/store/mutation-types";
import { FEATURE } from "@/enums/Feature";

export default {
  name: "FeatureController",
  computed: {
    opacity: {
      get() {
        return this.$store.state.imageOpacity;
      },
      set(imageOpacity) {
        this.$store.commit(UPDATE_IMAGE_OPACITY, { imageOpacity });
      },
    },
  },
  data() {
    return {
      features: [
        { type: FEATURE.RIVER, visible: false },
        { type: FEATURE.WATERSHED, visible: false },
        { type: FEATURE.ADMIN, visible: false },
      ],
    };
  },
  methods: {
    onChangeFeature() {
      const selectedFeatures = _.filter(this.features, { visible: true });
      this.$store.commit(UPDATE_SELECTED_FEATURES, {
        selectedFeatures: _.map(selectedFeatures, "type"),
      });
    },
  },
};
</script>

<style lang="scss">
.feature-controller {
  .theme--light.v-label {
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      color: white;
    }
    font-size: 13px;
  }
  .v-application--is-ltr .v-input--selection-controls__input {
    margin-right: 0;
  }
}
</style>
<style lang="scss" scoped>
.feature-controller {
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    @include map-controls-base;
  }
}
</style>
