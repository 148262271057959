import Vue from "vue";
import VueRouter from "vue-router";
import TopView from "../views/top-view/TopView.vue";
import RiskMapView from "../views/risk-map-view/RiskMapView.vue";
import i18n from "@/plugins/i18n";
import EnterpriseView from "@/views/enterprise-view/EnterpriseView";
import MiruoneView from "@/views/enterprise-view/MiruoneView";
import MainView from "../views/top-view/children/MainView.vue";
import RenewalView from "@/views/top-view/children/RenewalView";
import CompanyView from "@/views/top-view/children/CompanyView";
import AgreementView from "@/views/top-view/children/AgreementView";
import RisuKumaView from "@/views/top-view/children/RisuKumaView";
import { UPDATE_META_INFO } from "@/store/mutation-types";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/:lang/top",
    component: TopView,
    children: [
      {
        path: "agreement",
        component: AgreementView,
      },
      {
        path: "company",
        component: CompanyView,
      },
      {
        path: "risu-kuma",
        component: RisuKumaView,
      },
      {
        path: "renewal",
        component: RenewalView,
      },
      {
        path: "",
        component: MainView,
      },
      {
        path: "*",
        redirect: `/${i18n.locale}/top`,
      },
    ],
  },
  {
    path: "/:lang/enterprise",
    component: MiruoneView,
  },
  {
    path: "/:lang/enterprise2",
    component: EnterpriseView,
  },
  {
    path: "/:lang/risk-map",
    component: RiskMapView,
  },
  {
    path: "/:lang/risk-map/:latLngZoom",
    component: RiskMapView,
  },
  {
    path: "/:lang/risk-map/:latLngZoom/:dataType",
    component: RiskMapView,
  },
  {
    path: "/*",
    redirect: (to) => {
      // 특정 조건을 만족하는 경우 리다이렉트
      if (!to.path.includes("/pdf")) {
        return `/${i18n.locale}/top`;
      }
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  if (!to.params.lang) {
    to.params.lang = i18n.locale;
  } else {
    i18n.locale = to.params.lang;
  }
  next();
});
router.afterEach((to) => {
  if (to.path.includes("/enterprise") && !to.path.includes("/enterprise2")) {
    store.commit(UPDATE_META_INFO, {
      metaInfo: {
        title: "みるわん｜河川カメラによる防災監視パッケージ",
        meta: [
          {
            name: "description",
            content:
              "「みるわん」では、近年頻発するゲリラ豪雨・集中豪雨による災害の防止を目指し、高感度の監視カメラや、水位計、雨量計、冠水センサーなどのIoT監視機器の提供の他、気象庁などのリアルタイムの災害発生リスク情報を提供しています。",
          },
        ],
      },
    });
  } else {
    store.commit(UPDATE_META_INFO, {
      metaInfo: {
        title:
          "RisKma (りすくま)は内水や外水氾濫による浸水予測を行い水害対応支援を行います",
        meta: [
          {
            name: "description",
            content:
              "RisKma(りすくま)では、近年頻発するゲリラ豪雨・集中豪雨による災害の防止を目指し、リアルタイムの災害発生リスク情報を提供しています。コンテンツは３時間先のゲリラ豪雨を予測する「短時間ゲリラ豪雨予測」、長時間先の雨雲の動きを確認できる「レーダー・36時間予報」、内水はん濫の発生リスクをリアルタイムで予測する「内水リスクマップ」があります。",
          },
        ],
      },
    });
  }
  window.scrollTo(0, 0);
});

export default router;
