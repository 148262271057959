<template>
  <div class="mobile-date-controller">
    <div
      id="date-container"
      class="date-container"
      :class="{ scroll: !isIOS(), small: isThirdParty }"
      v-scroll:#date-container="handleScroll"
    >
      <button class="box white-space" ref="whiteSpace"></button>
      <button
        v-for="(o, index) in riskData"
        :key="index"
        ref="dateBox"
        :id="`date${index}`"
        class="box date"
        :class="{ active: index === sliderPosition, small: isThirdParty }"
        @click="playInterval ? stop() : scrollTo(index)"
      >
        <v-row no-gutters align="center" justify="center" class="fill-height">
          <div class="date-view">
            <template v-if="getMoment(o.date).diff(baseDate, 'minutes') === 0">
              {{ $t("now") }}
            </template>
            <template v-else-if="selectedRisk === RISK.RT">
              {{ $t("actual") }} <br />
              {{ getMoment(o.date).diff(baseDate, "hours") }} {{ $t("hours") }}
            </template>
            <template v-else-if="selectedRisk === RISK.FL">
              {{ getMoment(o.date).diff(baseDate, "minutes") }}
              {{ $t("minutes") }} <br />
              {{ $t("within") }}
            </template>
            <template v-else-if="selectedRisk === RISK.VWS">
              {{ Math.abs(getMoment(o.date).diff(baseDate, "minutes")) }}
              {{ $t("minutes") }}{{ $t("ago") }}
            </template>
            <template v-else>
              <div class="MM-DD-d">
                {{ getMMDDdFormat(o.date) }}
              </div>
              <div class="hh-mm">
                {{ getHHmmFormat(o.date) }}
              </div>
            </template>
          </div>
        </v-row>
      </button>
      <button class="box white-space"></button>
      <div class="overlay-gradients"></div>
      <v-btn
        x-large
        icon
        class="play-btn ml-2"
        :class="{ scroll: !isIOS(), small: isThirdParty }"
        color="white"
        @click="playInterval ? stop() : play()"
      >
        <v-icon x-large> {{ playInterval ? "mdi-stop" : "mdi-play" }} </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import PcDateController from "@/components/risk-map-view/pc/PcDateController";
import { UPDATE_SELECTED_DATE } from "@/store/mutation-types";
import VueScrollTo from "vue-scrollto";

const DATE_FORMAT = "YYYY/MM/DD HH:mm";
export default {
  name: "MobileDateController",
  extends: PcDateController,
  data: () => ({
    week: {
      ja: ["日", "月", "火", "水", "木", "金", "土"],
      en: ["Sun.", "Mon.", "Tue.", "Wed.", "Thu.", "Fri.", "Sat."],
      timeWhiteSpace: 0,
    },
  }),
  computed: {
    selectedDateRiskDataIndex() {
      return this.$store.getters.selectedDateRiskDataIndex;
    },
    isThirdParty() {
      return this.$store.state.isThirdParty;
    },
  },
  watch: {
    baseDateRiskDataIndex() {
      this.$nextTick(() => {
        const el = this.$refs.dateBox[this.sliderPosition];
        if (el) {
          el.scrollIntoView({ inline: "center", block: "end" });
        }
      });
    },
    selectedDateRiskDataIndex() {
      this.$nextTick(() => {
        const el = this.$refs.dateBox[this.selectedDateRiskDataIndex];
        if (el) {
          el.scrollIntoView({ inline: "center", block: "end" });
        }
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.timeWhiteSpace = this.$refs.whiteSpace.clientWidth;
    });
  },
  methods: {
    getDay(index) {
      return this.week[this.$i18n.locale][index];
    },
    getMMDDdFormat(dateString) {
      const date = this.getMoment(dateString);
      return date.format("MM/DD") + " " + this.getDay(date.day());
    },
    getHHmmFormat(dateString) {
      return moment(dateString, DATE_FORMAT).format("HH:mm");
    },
    handleScroll(e) {
      this.updateCenterDateIndex(e);
    },
    updateCenterDateIndex(e) {
      const scrollAreaWidth = e.target.offsetWidth;
      const dateBoxWidth = scrollAreaWidth / 4;
      const centerBoxIndex = e.target.scrollLeft / dateBoxWidth;
      this.sliderPosition = Math.round(centerBoxIndex);

      if (!this.playInterval && this.riskData[this.sliderPosition]) {
        this.$store.commit(UPDATE_SELECTED_DATE, {
          selectedDate: moment(
            this.riskData[this.sliderPosition].date,
            DATE_FORMAT
          ),
        });
      }
    },
    getMoment(dateString) {
      return moment(dateString, DATE_FORMAT);
    },
    scrollTo(index) {
      const options = {
        container: "#date-container",
        easing: "easeInOutCubic",
        offset: -this.timeWhiteSpace,
        x: true,
        y: false,
      };
      VueScrollTo.scrollTo("#date" + index, 100, options);
    },
    isIOS() {
      return (
        [
          "iPad Simulator",
          "iPhone Simulator",
          "iPod Simulator",
          "iPad",
          "iPhone",
          "iPod",
        ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      );
    },
  },
};
</script>
<style lang="scss" scoped>
$scroll-bar-height: 24px;
$small-scroll-bar-height: 14px;
.mobile-date-controller {
  position: relative;
  width: 100vw;
  background-color: $base-color;
  .date-container {
    height: 60px;
    overflow-y: hidden;
    overflow-x: scroll;
    white-space: nowrap;
    scroll-snap-type: x mandatory; /* Chrome Canary */
    -webkit-overflow-scrolling: touch;
    .box {
      height: 100%;
      text-align: center;
      scroll-snap-align: center;
      display: inline-block;
      background-color: $base-color;
      color: white;
      touch-action: manipulation;
      vertical-align: bottom;
      .MM-DD-d {
        font-weight: bold;
        font-size: 12px;
      }
      .hh-mm {
        font-weight: bold;
        font-size: 24px;
        line-height: 1.2;
      }
    }
    .small {
      .MM-DD-d {
        font-size: 10px;
      }
      .hh-mm {
        font-size: 16px;
      }
    }
    .box.date {
      width: 25%;
    }
    .box.date.active {
      background-color: orange;
      font-weight: bolder;
      color: black;
    }
    .box.white-space {
      width: calc(50% - (25% / 2));
    }
    .overlay-gradients {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100vw;
      background-image: linear-gradient(
        to right,
        rgba(#000000, 0.6),
        rgba(#000000, 0),
        rgba(#000000, 0),
        rgba(#000000, 0),
        rgba(#000000, 0),
        rgba(#000000, 0),
        rgba(#000000, 0.6)
      );
      pointer-events: none;
    }
    .play-btn {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
    }
    .play-btn.scroll {
      transform: translateY(calc(-50% - 12px));
    }
    .play-btn.scroll.small {
      transform: translateY(calc(-50% - 7px));
    }
    .date-view {
      width: 100%;
      display: inline;
      white-space: break-spaces;
    }
  }

  .date-container.scroll {
    height: 80px;
  }
  .date-container.scroll.small {
    height: 50px;
  }
  .scroll::-webkit-scrollbar {
    height: $scroll-bar-height;
  }
  .scroll.small::-webkit-scrollbar {
    height: $small-scroll-bar-height;
  }
  .scroll::-webkit-scrollbar-thumb {
    background-color: #999999;
    border: 1px solid #ffffff;
  }
  .scroll::-webkit-scrollbar-track {
    background: #ffffff;
  }
}
</style>
