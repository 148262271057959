<template>
  <l-image-overlay
    v-if="selectedDateRiskData && selectedDateRiskData.image"
    :bounds="imageInfo.bounds"
    :url="selectedDateRiskData.image.src"
    :opacity="imageOpacity"
  >
  </l-image-overlay>
</template>

<script>
export default {
  name: "GuerrillaRainLayer",
  data() {
    return {
      imageInfo: {
        bounds: [
          [33.46, 135.008],
          [37.5496, 141.0875],
        ],
      },
    };
  },
  computed: {
    map() {
      return this.$store.state.map;
    },
    currentDate() {
      return this.$store.state.currentDate;
    },
    imageOpacity() {
      return this.$store.state.imageOpacity;
    },
    selectedDateRiskData() {
      return this.$store.getters.selectedDateRiskData;
    },
  },
  watch: {
    currentDate() {
      this.loadData();
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.$store.dispatch("loadGuerrillaRain");
    },
  },
};
</script>

<style scoped></style>
