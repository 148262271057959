<template>
  <div class="risk-info">
    <div class="pa-4" v-if="contents === RISK.RR">
      <rain-radar-info></rain-radar-info>
    </div>
    <div class="pa-4" v-else-if="contents === RISK.RT">
      <rain-total-info></rain-total-info>
    </div>
    <div class="pa-4" v-else-if="contents === RISK.FL">
      <flood-info></flood-info>
    </div>
    <div v-else-if="contents === RISK.VWS">
      <virtual-water-surface-info></virtual-water-surface-info>
    </div>
    <div class="pa-4" v-else-if="contents === RISK.GR">
      <guerrilla-rain-info></guerrilla-rain-info>
    </div>
  </div>
</template>

<script>
import { RISK } from "@/enums/Risk";
import RainRadarInfo from "@/components/risk-map-view/risk-info/RainRadarInfo";
import RainTotalInfo from "@/components/risk-map-view/risk-info/RainTotalInfo";
import FloodInfo from "@/components/risk-map-view/risk-info/FloodInfo";
import VirtualWaterSurfaceInfo from "@/components/risk-map-view/risk-info/VirtualWaterSurfaceInfo";
import GuerrillaRainInfo from "@/components/risk-map-view/risk-info/GuerrillaRainInfo";

export default {
  name: "RiskInfo",
  components: {
    GuerrillaRainInfo,
    VirtualWaterSurfaceInfo,
    FloodInfo,
    RainTotalInfo,
    RainRadarInfo,
  },
  props: ["contents"],
  data() {
    return {
      RISK: RISK,
      active: null,
    };
  },
};
</script>
<style lang="scss" scoped>
.info-header {
  padding-bottom: 20px;
  text-align: center;

  img {
    height: 50px;
    margin: 5px 0;
  }
}

.info-card {
  .info-subject {
    color: white;
    text-align: center;
    background-color: rgba(66, 72, 99, 1);
    border-radius: 10px;
    padding: 3px;
    margin-bottom: 15px;
    font-weight: bold;
  }
  p {
    text-align: left;
  }

  .level3 {
    padding: 2px;
    background: #cf4d4d;
  }
  .level2 {
    padding: 2px;
    background: #f2a007;
  }
  .level1 {
    padding: 2px;
    background: #4b68bf;
  }
}

.text-red {
  color: #ff0900 !important;
}
.text-bold {
  font-weight: bold;
}
</style>
