<template>
  <v-row class="pc-date-controller mb-6" no-gutters align="center">
    <v-col cols="auto" class="buttons-area text-center">
      <v-tooltip top color="primary">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            large
            icon
            color="black"
            class="mr-4"
            @click="previous"
          >
            <v-icon>mdi-skip-previous</v-icon>
          </v-btn>
        </template>
        <span class="font-weight-bold"> 戻る </span>
      </v-tooltip>
      <v-tooltip v-if="playInterval" top color="primary">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            x-large
            fab
            color="#00af82"
            class="mr-4"
            @click="stop"
          >
            <v-icon>{{ "mdi-pause" }}</v-icon>
          </v-btn>
        </template>
        <span class="font-weight-bold">中断する</span>
      </v-tooltip>
      <v-tooltip v-else top color="primary">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            x-large
            fab
            color="#00af82"
            class="mr-4"
            @click="play"
          >
            <v-icon>{{ "mdi-play" }}</v-icon>
          </v-btn>
        </template>
        <span class="font-weight-bold"> プレイする </span>
      </v-tooltip>
      <v-tooltip top color="primary">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            large
            icon
            color="black"
            @click="next"
          >
            <v-icon>mdi-skip-next</v-icon>
          </v-btn>
        </template>
        <span class="font-weight-bold">進む</span>
      </v-tooltip>
    </v-col>
    <v-col class="slider-area px-6">
      <div style="height: 62px">
        <v-slider
          v-model="sliderPosition"
          :tick-labels="sliderLabels"
          :max="riskData ? riskData.length - 1 : 0"
          step="1"
          ticks="always"
          color="#424863"
          track-color="#424863"
          thumb-color="#ff951f"
          thumb-label="always"
          hide-details
        >
          <template v-slot:thumb-label="{}">
            <div v-if="selectedDate" class="thumb-custom py-1 px-4">
              <div v-if="selectedDate.diff(baseDate, 'minutes') === 0">
                {{ $t("now") }}
              </div>
              <div v-else-if="selectedRisk === RISK.RT">
                {{ $t("actual") }} <br />
                {{ selectedDate.diff(baseDate, "hours") }} {{ $t("hours") }}
              </div>
              <div v-else-if="selectedRisk === RISK.FL">
                {{ selectedDate.diff(baseDate, "minutes") }}
                {{ $t("minutes") }} <br />
                {{ $t("within") }}
              </div>
              <div v-else-if="selectedRisk === RISK.VWS">
                {{ Math.abs(selectedDate.diff(baseDate, "minutes")) }}
                {{ $t("minutes") }}{{ $t("ago") }}
              </div>
              <div v-else>
                {{ selectedDate.format("MM/DD HH:mm") }}
              </div>
            </div>
          </template>
        </v-slider>
      </div>
    </v-col>
  </v-row>
</template>
<i18n>
{
  "ja": {
    "actual": "実績",
    "hours": "時間",
    "minutes": "分",
    "within": "以内",
    "ago": "前",
    "now": "現在"
  },
  "en": {
    "actual": "Actual",
    "hours": "hours",
    "minutes": "minutes",
    "within": " ",
    "ago": " ago",
    "now": "now"
  }
}
</i18n>
<script>
import { UPDATE_SELECTED_DATE } from "@/store/mutation-types";
import moment from "moment";
import _ from "lodash";
import { RISK } from "@/enums/Risk";
const DATE_FORMAT = "YYYY/MM/DD HH:mm";

export default {
  name: "PcDateController",
  data() {
    return {
      RISK: RISK,
      playInterval: null,
      sliderPosition: 0,
      sliderLabels: [],
      labelInfo: {
        [RISK.RR]: [
          { label: "-1h", value: -60 },
          { label: "Now", value: 0 },
          { label: "+1h", value: 60 },
          { label: "+12h", value: 720 },
          { label: "+24h", value: 1440 },
        ],
        [RISK.RT]: [
          { label: "1hrs", value: 60 },
          { label: "3hrs", value: 180 },
          { label: "6hrs", value: 360 },
          { label: "12hrs", value: 720 },
          { label: "24hrs", value: 1440 },
          { label: "48hrs", value: 2880 },
        ],
        [RISK.FL]: [
          { label: "Now", value: 0 },
          { label: "+30min", value: 30 },
          { label: "+60min", value: 60 },
        ],
        [RISK.VWS]: [
          { label: "-120min", value: -120 },
          { label: "-60min", value: -60 },
          { label: "Now", value: 0 },
        ],
        [RISK.GR]: [
          { label: "+1h", value: 60 },
          { label: "+2h", value: 120 },
          { label: "+3h", value: 180 },
        ],
      },
    };
  },
  computed: {
    riskData() {
      return this.$store.state.riskData;
    },
    selectedRisk() {
      return this.$store.state.selectedRisk;
    },
    selectedDate() {
      return this.$store.state.selectedDate;
    },
    baseDate() {
      return this.$store.state.baseDate;
    },
    baseDateRiskDataIndex() {
      return this.$store.getters.baseDateRiskDataIndex;
    },
  },
  watch: {
    sliderPosition(after, before) {
      if (after === before) {
        return;
      }
      if (this.riskData[this.sliderPosition]) {
        const selectedDate = moment(
          this.riskData[this.sliderPosition].date,
          DATE_FORMAT
        );
        this.$store.commit(UPDATE_SELECTED_DATE, { selectedDate });
      }
    },
    riskData(after, before) {
      if (after === before) {
        return;
      }
      this.sliderPosition =
        this.baseDateRiskDataIndex === -1 ? 0 : this.baseDateRiskDataIndex;
      this.sliderLabels = this.getSliderLabels();
    },
    sliderLabels() {
      this.$nextTick(() => {
        this.removeLongTicks();
        this.removeBaseDateTick();
        this.addLongTicks();
        this.addBaseDateTick();
      });
    },
  },
  methods: {
    removeLongTicks() {
      const longTicks = document.querySelectorAll(
        `.pc-date-controller #long-tick`
      );
      _.forEach(longTicks, (node) => {
        node.removeAttribute("id");
      });
    },
    addLongTicks() {
      const nodes = document.querySelectorAll(
        `.pc-date-controller .v-slider__tick`
      );
      _.forEach(this.sliderLabels, (label, index) => {
        if (
          (label && label !== "") ||
          index === 0 ||
          index === this.sliderLabels.length - 1
        ) {
          const currentNode = nodes[index];
          if (currentNode) {
            currentNode.id = "long-tick";
          }
        }
      });
    },
    removeBaseDateTick() {
      const ticks = document.querySelectorAll(
        `.pc-date-controller #base-date-tick`
      );
      _.forEach(ticks, (node) => {
        node.removeAttribute("id");
      });
    },
    addBaseDateTick() {
      const nodes = document.querySelectorAll(
        `.pc-date-controller .v-slider__tick`
      );
      const baseDateTick = nodes[this.baseDateRiskDataIndex];
      if (baseDateTick) {
        baseDateTick.id = "base-date-tick";
      }
    },
    getSliderLabels() {
      const labels = _.cloneDeep(this.riskData).fill("");
      _.forEach(this.labelInfo[this.selectedRisk], (info) => {
        const nearest = _.minBy(this.riskData, (o) => {
          const date = moment(o.date, DATE_FORMAT);
          const diff = info.value - date.diff(this.baseDate, "minutes");
          return Math.abs(diff);
        });
        const nearestIndex = _.findIndex(this.riskData, nearest);
        if (nearestIndex !== -1) {
          labels[nearestIndex] = info.label;
        }
      });

      return labels;
    },
    previous() {
      if (this.sliderPosition <= 0) {
        return;
      }
      this.sliderPosition -= 1;
    },
    next() {
      if (this.sliderPosition >= this.riskData.length - 1) {
        return;
      }
      this.sliderPosition += 1;
    },
    play() {
      this.playInterval = setInterval(() => {
        if (this.sliderPosition >= this.riskData.length - 1) {
          clearInterval(this.playInterval);
          return;
        }
        this.sliderPosition += 1;
      }, 1000);
    },
    stop() {
      if (this.playInterval) {
        clearInterval(this.playInterval);
      }
      this.playInterval = null;
    },
  },
};
</script>
<style lang="scss">
.pc-date-controller {
  .v-slider__tick {
    height: 10px !important;
    width: 1px !important;
    transform: translateY(+110%);
    border-radius: 50px;
  }
  .v-slider__tick#long-tick {
    height: 16px !important;
    width: 3px !important;
    transform: translateY(+50%);
    background-color: $base-color;
    border-radius: 50px;
  }
  .v-slider__tick#base-date-tick {
    height: 16px !important;
    width: 3px !important;
    transform: translateY(+50%);
    background-color: red;
    border-radius: 50px;
  }
  .v-slider__tick--filled {
    background-color: $base-color;
  }
  .v-slider__thumb {
    transform: translateY(-50%) scale(1.2) !important;
  }
  .v-slider__tick-label {
    font-size: 18px;
    font-weight: bold;
  }
  .v-slider--horizontal .v-slider__track-container {
    height: 6px;
  }
  .v-slider__thumb-label {
    background-color: rgba(0, 0, 0, 0) !important;
    height: 80px !important;
  }
  .v-slider--horizontal .v-slider__tick .v-slider__tick-label {
    top: 16px;
  }
  .v-slider__track-fill {
    border-radius: 50px;
  }
  .v-slider__track-background {
    border-radius: 50px;
  }
}
</style>
<style lang="scss" scoped>
.pc-date-controller {
  width: 100%;
  .buttons-area {
    width: 240px;
  }
  .selected-date-area {
    background-color: $base-color-alpha;
    color: $base-font-color;
  }
  .thumb-custom {
    width: 130px;
    color: black;
    font-size: 24px;
    font-weight: bolder;
    text-align: center;
  }
}
</style>
