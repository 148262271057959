<template>
  <div>
    <rain-radar-layer v-if="selectedRisk === RISK.RR"></rain-radar-layer>
    <rain-total-layer v-else-if="selectedRisk === RISK.RT"></rain-total-layer>
    <flood-layer v-else-if="selectedRisk === RISK.FL"></flood-layer>
    <virtual-water-surface-layers
      v-else-if="selectedRisk === RISK.VWS"
    ></virtual-water-surface-layers>
    <guerrilla-rain-layer
      v-else-if="selectedRisk === RISK.GR"
    ></guerrilla-rain-layer>
  </div>
</template>

<script>
import { RISK } from "@/enums/Risk";
import RainRadarLayer from "@/components/risk-map-view/layers/RainRadarLayer";
import RainTotalLayer from "@/components/risk-map-view/layers/RainTotalLayer";
import FloodLayer from "@/components/risk-map-view/layers/FloodLayer";
import GuerrillaRainLayer from "@/components/risk-map-view/layers/GuerrillaRainLayer";
import VirtualWaterSurfaceLayers from "@/components/risk-map-view/layers/VirtualWaterSurfaceLayers";

export default {
  name: "RiskLayers",
  components: {
    VirtualWaterSurfaceLayers,
    GuerrillaRainLayer,
    FloodLayer,
    RainTotalLayer,
    RainRadarLayer,
  },
  data() {
    return {
      RISK: RISK,
    };
  },
  computed: {
    selectedRisk() {
      return this.$store.state.selectedRisk;
    },
  },
};
</script>

<style scoped></style>
