<template>
  <div class="logo" :class="{ small: isThirdParty }">
    <v-row no-gutters justify="center" :class="{ 'mb-2': !isThirdParty }">
      <a :href="`/${$i18n.locale}/top`" :target="isThirdParty ? '_blank' : ''">
        <v-img
          class="header-logo"
          :class="{ small: isThirdParty }"
          :src="require('@/assets/images/views/common/header-logo.svg')"
          contain
          aspect-ratio="4"
        ></v-img>
      </a>
    </v-row>
  </div>
</template>
<script>
import PcLogo from "@/components/risk-map-view/pc/PcLogo";
export default {
  name: "MobileLogo",
  extends: PcLogo,
  computed: {
    isThirdParty() {
      return this.$store.state.isThirdParty;
    },
  },
};
</script>
<style lang="scss" scoped>
.logo {
  .header-logo {
    width: 135px;
  }
  .header-logo.small {
    width: 110px;
  }
  .v-image {
    filter: drop-shadow(0 0 3px #424863);
  }
  width: 100%;
}
.logo.small {
  width: inherit;
}
</style>
