<template>
  <v-dialog v-model="dialog" width="600">
    <v-card id="dialogContents" color="rgba(35,53,89,0.8)">
      <div v-if="isIE" class="pa-2" style="text-align: center">
        申し訳ございません。本機能はIE非対応となっております。<br />Edge、Chrome、Firefox、Safariなどのブラウザで確認をお願いします。
      </div>
      <div v-else-if="!showChart && !isIE" class="pa-2 chart-loading">
        Loading...
      </div>
      <div v-else-if="selectedObservatory" class="pt-3 chart-title">
        {{ selectedObservatory.river }}
      </div>
      <div
        class="pa-3"
        :style="{ visibility: showChart && !isIE ? 'visible' : 'hidden' }"
      >
        <ctie-chart-app
          id="ctieChart"
          :api-key="chartApiKey"
          :aspect-ratio="aspectRatio"
          type="4"
          debug="true"
          @painted="painted"
          @status="status"
          @error="error"
        ></ctie-chart-app>
      </div>
      <v-card-actions class="px-3 pb-3 pt-0">
        <v-btn block tile dark color="warning" @click="closeDialog">戻る</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import conf from "@/config/index";

export default {
  name: "ObservatoriesChartDialog",
  data() {
    return {
      script: false,
      dialog: false,
      showChart: false,
      isIE: false,
      chartApiKey: null,
      aspectRatio: null,
      selectedObservatory: null,
    };
  },
  created() {
    this.chartApiKey = conf.ctieChart.key;
    if (this.$vuetify.breakpoint.mdAndUp) {
      this.aspectRatio = 1.62;
    } else {
      this.aspectRatio = 1;
    }
  },
  methods: {
    painted() {
      this.showChart = true;
    },
    closeDialog() {
      this.dialog = false;
      this.$nextTick(() => {
        this.showChart = false;
        this.$emit("close");
      });
    },
    status(e) {
      console.log(e.detail.status);
    },
    error(e) {
      console.log(e.detail.error);
    },
    updateSelectedObservatory(selectedObservatory) {
      if (!selectedObservatory) {
        return;
      }

      this.selectedObservatory = selectedObservatory;
      this.dialog = true;

      this.$nextTick(() => {
        let element = document.getElementById("ctieChart");
        if (element) {
          element.setAttribute("observatory", selectedObservatory.id);
        }
      });
    },
  },
};
</script>

<style>
.v-dialog__content {
  z-index: 1200 !important;
}
.v-overlay {
  z-index: 1200 !important;
  color: #233559;
}
.chart-loading {
  text-align: center;
  color: white;
  font-weight: bold;
  font-size: 20px;
}
.chart-title {
  text-align: center;
  color: white;
  font-weight: bold;
  font-size: 20px;
}
</style>

<style scoped></style>
