<script>
import { RISK } from "@/enums/Risk";
import FloodLayerBase from "@/components/risk-map-view/layers/base/FloodLayerBase";

export default {
  name: "VirtualWaterSurfaceImageLayer",
  extends: FloodLayerBase,
  data() {
    return {
      riskType: RISK.VWS,
      images: [],
      imageInfo: {
        0: {
          coordinate: {
            east: 147.0,
            north: 46.0,
            west: 138.0,
            south: 41.33333333333333,
          },
          slicedLatHeight: 0.5,
          sliceCount: 10,
          mobileSlicedLatHeight: 1,
          mobileSliceCount: 5,
        },
        1: {
          coordinate: {
            east: 143.0,
            north: 41.333333333333,
            west: 138.0,
            south: 32.6666666666666,
          },
          slicedLatHeight: 0.5,
          sliceCount: 18,
          mobileSlicedLatHeight: 0.618,
          mobileSliceCount: 10,
        },
        2: {
          coordinate: {
            east: 138.0,
            north: 38.0,
            west: 128.0,
            south: 30.66666666666,
          },
          slicedLatHeight: 0.5,
          sliceCount: 15,
          mobileSlicedLatHeight: 0.718,
          mobileSliceCount: 8,
        },
        3: {
          coordinate: {
            east: 132.0,
            north: 30.66666666666666,
            west: 122.0,
            south: 24.0,
          },
          slicedLatHeight: 0.5,
          sliceCount: 14,
          mobileSlicedLatHeight: 0.718,
          mobileSliceCount: 8,
        },
      },
    };
  },
};
</script>
