var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"riskMap",staticClass:"risk-map"},[_c('l-map',{ref:"map",attrs:{"id":"map","center":_vm.defaultCenter,"zoom":_vm.defaultZoom,"minZoom":4,"maxZoom":14,"max-bounds":_vm.selectedRisk === _vm.RISK.GR ? _vm.guerrillaMaxBounds : [],"zoomAnimation":false,"options":{
      zoomControl: false,
    }},on:{"ready":function($event){return _vm.updateMapObject()}}},[(_vm.mapObject)?[_c('base-tile-layer'),_c('risk-layers'),_c('features-layer'),_c('current-location-marker'),(_vm.riskmaMode)?_c('riskma-mode-layer',{attrs:{"riskma-mode":_vm.riskmaMode}}):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp)?_c('l-control-attribution',{attrs:{"position":"bottomleft","prefix":`<a href='https://maps.gsi.go.jp/'> ${_vm.$t(
          'geographicalSurveyInstitute'
        )}</a> | <a href='https://leafletjs.com/'>Leaflet</a> | ${_vm.$t(
          'attribution'
        )}`}}):_c('l-control-attribution',{attrs:{"position":"topleft","prefix":`<a href='https://maps.gsi.go.jp/'>${_vm.$t(
          'geographicalSurveyInstitute'
        )}</a> | <a href='https://leafletjs.com/'>Leaflet</a>`}})]:_vm._e()],2),_c('div',{staticClass:"controller-area"},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('pc-map-controller'):[(_vm.isThirdParty)?_c('mobile-third-party-map-controller'):_c('mobile-map-controller')]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }