<template>
  <div>
    <div class="info-header">
      <div>
        <h2 v-t="'waterFloodTitle'"></h2>
      </div>
      <div>
        <p v-t="'waterFloodOverview'"></p>
      </div>
    </div>
    <div class="info-card">
      <p>
        {{ $t("waterFloodDetails1") }}<br />
        <br />
        {{ $t("waterFloodDetails2") }}<br />
        <br />
        {{ $t("waterFloodDetails3") }}<br />
      </p>
    </div>
    <div class="info-card">
      <div>
        <div class="info-subject" v-t="'watarFloodRiskTitle'"></div>
        <p>
          {{ $t("watarFloodRiskOverview") }}<br />
          <br />
          <span class="level3" v-t="'emergencyWarningTitle'"></span> &nbsp;{{
            $t("emergencyWarningText")
          }}<br />
          <span class="level2" v-t="'warningTitle'"></span> &nbsp;{{
            $t("warningText")
          }}<br />
          <span class="level1" v-t="'cautionTitle'"></span> &nbsp;{{
            $t("cautionText")
          }}<br />
          <br />
          {{ $t("watarFloodRiskExplained") }}
        </p>
      </div>
      <div>
        <div class="info-subject">内水リスクマップによる解析事例</div>
        <div class="text-xs-center">
          <img
            alt="now-icon"
            style="width: 150px"
            src="@/assets/images/views/risk-map-view/water-flood-legend.png"
          />
        </div>
        <v-row no-gutters style="font-size: 10px; text-align: center">
          <v-col cols="12" md="4" class="pa-1">
            <div style="border: 1px solid black">
              <img
                alt="now-icon"
                style="width: 100%; height: 220px"
                src="@/assets/images/views/risk-map-view/water-flood-1-1.png"
              />
              解析事例(平成25年7月23日世田谷豪雨)
            </div>
          </v-col>
          <v-col cols="12" md="4" class="pa-1">
            <div style="border: 1px solid black">
              <img
                alt="now-icon"
                style="width: 100%; height: 220px"
                src="@/assets/images/views/risk-map-view/water-flood-1-2.png"
              />
              解析事例(平成25年9月4日名古屋豪雨)
            </div>
          </v-col>
          <v-col cols="12" md="4" class="pa-1">
            <div style="border: 1px solid black">
              <img
                alt="now-icon"
                style="width: 100%; height: 220px"
                src="@/assets/images/views/risk-map-view/water-flood-1-3.png"
              />
              解析事例(平成28年7月30日山形)
            </div>
          </v-col>
        </v-row>
        <div class="text-xs-center" style="font-size: 10px">
          地図:(C)OpenStreetMap contributors
        </div>
      </div>
    </div>
  </div>
</template>
<i18n>
{
  "ja": {
    "waterFloodTitle": "内水リスク予測",
    "waterFloodOverview": "水路やマンホールから水が溢れる可能性のあるエリアをリアルタイムで表示しています。",
    "waterFloodDetails1": "１時間以内程度の豪雨現象による内水現象を対象に、気象庁高解像度降水ナウキャストおよび地形モデルを組み合わせて、内水の発生の恐れがあるエリア（リスクエリア）を解析・予測しています。なお本システムは、数時間続く豪雨を原因とする、河川からの越水氾濫は考慮しておりません。",
    "waterFloodDetails2": "また、リスクマップによる内水の恐れがあるエリアと実際の内水エリアは完全に整合は取れておりません。 ご覧になる場合はこれらの特性を十分にご理解の上、ご利用ください。",
    "waterFloodDetails3": "またご利用の際は、合わせて気象庁の大雨・洪水(内水害)の警報にも十分注意してください。",
    "watarFloodRiskTitle": "「内水の恐れ」の説明",
    "watarFloodRiskOverview": "「内水の恐れ」は、３段階(赤、黄、青)に分けて表示し、赤色になるほど、内水の恐れが高いメッシュを表しています。(内水の深さを表す指標ではありません。)",
    "emergencyWarningTitle": "危険",
    "emergencyWarningText": "周辺で内水が発生している危険性が高い",
    "warningTitle": "警戒",
    "warningText": "周辺で内水が発生する恐れがある",
    "cautionTitle": "注意",
    "cautionText": "今後の雨の降り方に注意",
    "watarFloodRiskExplained": "内水リスク予測では、実況雨量に基づく「現在内水の恐れがあるエリア」、および予測雨量に基づいた「今後内水の恐れがあるエリア」を表示しています。"
  },
  "en": {
    "waterFloodTitle": "What is Stormwater Flood Risk Map?",
    "waterFloodOverview": "Stormwater flood risk map shows an area where there is a predicted risk of stormwater flood occurring within approximately one hour.",
    "waterFloodDetails1": "The map is created by analyzing a high-resolution precipitation Nowcast data from the Japan Meteorological Agency and a topographic model of given area.Note that this system does not consider the floods caused by an overflow from a river due to heavy rain lasting for several hours.",
    "waterFloodDetails2": "In addition, there may be a discrepancy between the area with a predicted risk of flooding and the actual flooded area.Please fully understand the above characteristics when using the stormwater flood risk map.",
    "waterFloodDetails3": "Furthermore, please pay attention to the heavy rain/flood alerts issued by the Japan Meteorological Agency that are displayed on the map.",
    "watarFloodRiskTitle": "Risk of flooding explained",
    "watarFloodRiskOverview": "Risk of flooding is divided into three levels (red, yellow, blue), where the red color indicates a mesh with a high risk of flooding. (Color is not an indicator of the depth of predicted flooding.)",
    "emergencyWarningTitle": "Emergency Warning :",
    "emergencyWarningText": " High risk of flooding in the vicinity",
    "warningTitle": "Warning :",
    "warningText": " Floods may occur in the vicinity",
    "cautionTitle": "Caution :",
    "cautionText": " Caution is advised",
    "watarFloodRiskExplained": "Stormwater flood risk map highlights areas which currently have a risk of flooding based on the actual measured rainfall, and areas with a predicted risk of flooding based on the predicted rainfall data."
  }
}
</i18n>

<script>
import RiskInfoBase from "./RiskInfoBase";
export default {
  name: "FloodInfo",
  extends: RiskInfoBase,
};
</script>
