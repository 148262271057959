<template>
  <div>
    <v-menu top right offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          dark
          color="primary"
          class="font-weight-bold"
          :small="isThirdParty"
        >
          <v-icon :small="isThirdParty">mdi-layers-triple</v-icon>
        </v-btn>
      </template>
      <v-list dense dark color="#424863">
        <v-list-item
          v-for="(risk, index) in risks"
          :key="index"
          @click="selectedRisk = risk"
        >
          <v-list-item-title
            v-html="$t(`risk-${risk}`)"
            class="font-weight-bold"
            :style="{
              color: selectedRisk === risk ? '#ff951f' : 'white',
            }"
          >
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<i18n>
{
  "ja": {
    "risk-0": "レーダー・36時間予報",
    "risk-1": "実積累加雨量",
    "risk-2": "内水リスク予測",
    "risk-3": "バーチャル水面",
    "risk-4": "ゲリラ豪雨"
  },
  "en": {
    "risk-0": "Radar 36 hours forecast",
    "risk-1": "cumulative rainfall",
    "risk-2": "Flood forecast",
    "risk-3": "water’s surface",
    "risk-4": "\"Guerilla rainstorm\""
  }
}
</i18n>
<script>
import PcRiskController from "@/components/risk-map-view/pc/PcRiskController";
import { RISK } from "@/enums/Risk";

export default {
  name: "MobileRiskController",
  extends: PcRiskController,
  data() {
    return {
      risks: [RISK.RR, RISK.RT, RISK.FL, RISK.VWS, RISK.GR],
    };
  },
  computed: {
    isThirdParty() {
      return this.$store.state.isThirdParty;
    },
  },
};
</script>
