var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.imageView)?[_vm._l((_vm.imageView),function(part,index){return [(part.visible)?_c('l-marker',{key:index,attrs:{"lat-lng":[part.markerLat, part.markerLng]}},[_c('l-icon',{attrs:{"icon-anchor":[0, 0]}},[_c('div',{style:({
              width: part.divWidth + 'px',
              height: part.divHeight + 'px',
              overflow: 'hidden',
              'pointer-events': 'none',
            })},[_c('img',{staticClass:"risk-image",style:({
                transform: `translate(0,${part.translateY}px)`,
                width: part.imgWidth + 'px',
                height: part.imgHeight + 'px',
                opacity: _vm.imageOpacity,
                'pointer-events': 'none',
              }),attrs:{"alt":"risk-image","src":_vm.selectedDateRiskData.image.src}})])])],1):_vm._e()]})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }