<template>
  <div class="mobile-map-legend" style="pointer-events: none">
    <div v-if="selectedRisk !== null">
      <div v-if="selectedRisk === RISK.VWS" class="vws-area pa-2">
        <div>
          <v-img
            :src="
              require('@/assets/images/views/risk-map-view/virtual-water-surface-legend.png')
            "
            contain
            aspect-ratio="1"
            height="80px"
          ></v-img>
        </div>
        <div>
          <v-row no-gutters align="center">
            <v-icon color="#559944">mdi-crop-square</v-icon>
            <span v-t="'alluvialFan'"></span>
          </v-row>
          <v-row no-gutters align="center">
            <v-icon color="#35fe23">mdi-crop-square</v-icon>
            <span v-t="'AreasBelowSeaLevel'"></span>
          </v-row>
          <v-row no-gutters align="center">
            <v-icon color="#2824f2">mdi-minus</v-icon>
            <span v-t="'targetRiver'"></span>
          </v-row>
        </div>
      </div>
      <div v-else>
        <div class="legend-wrapper" v-if="selectedRisk === RISK.RT">
          <div
            v-for="(o, index) in selectedRiskLegend.legend[
              selectedDateRiskDataIndex
            ]"
            :key="index"
            class="color-box"
            :style="{ backgroundColor: o.color }"
          >
            {{ o.value }}
          </div>
        </div>
        <div
          v-else
          v-for="(o, index) in selectedRiskLegend.legend"
          :key="index"
          class="color-box"
          :style="{ backgroundColor: o.color }"
        >
          {{ o.value }}
        </div>
        <div v-if="selectedRiskLegend" class="color-box">
          {{ selectedRiskLegend.unit }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PcMapLegend from "@/components/risk-map-view/pc/PcMapLegend";
import { RISK } from "@/enums/Risk";

export default {
  name: "MobileMapLegend",
  extends: PcMapLegend,
  data() {
    return {
      RISK: RISK,
    };
  },
};
</script>
