<template>
  <div class="risk-map" ref="riskMap">
    <l-map
      id="map"
      ref="map"
      :center="defaultCenter"
      :zoom="defaultZoom"
      :minZoom="4"
      :maxZoom="14"
      :max-bounds="selectedRisk === RISK.GR ? guerrillaMaxBounds : []"
      :zoomAnimation="false"
      :options="{
        zoomControl: false,
      }"
      @ready="updateMapObject()"
    >
      <template v-if="mapObject">
        <base-tile-layer></base-tile-layer>
        <risk-layers></risk-layers>
        <features-layer></features-layer>
        <current-location-marker></current-location-marker>
        <riskma-mode-layer
          v-if="riskmaMode"
          :riskma-mode="riskmaMode"
        ></riskma-mode-layer>
        <l-control-attribution
          v-if="$vuetify.breakpoint.mdAndUp"
          position="bottomleft"
          :prefix="`<a href='https://maps.gsi.go.jp/'> ${$t(
            'geographicalSurveyInstitute'
          )}</a> | <a href='https://leafletjs.com/'>Leaflet</a> | ${$t(
            'attribution'
          )}`"
        >
        </l-control-attribution>
        <l-control-attribution
          v-else
          position="topleft"
          :prefix="`<a href='https://maps.gsi.go.jp/'>${$t(
            'geographicalSurveyInstitute'
          )}</a> | <a href='https://leafletjs.com/'>Leaflet</a>`"
        ></l-control-attribution>
      </template>
    </l-map>
    <div class="controller-area">
      <pc-map-controller v-if="$vuetify.breakpoint.mdAndUp"></pc-map-controller>
      <template v-else>
        <mobile-third-party-map-controller
          v-if="isThirdParty"
        ></mobile-third-party-map-controller>
        <mobile-map-controller v-else></mobile-map-controller>
      </template>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import BaseTileLayer from "@/components/risk-map-view/layers/BaseTileLayer";
import RiskLayers from "@/components/risk-map-view/layers/RiskLayers";
import PcMapController from "@/components/risk-map-view/pc/PcMapController";
import { UPDATE_MAP_OBJECT } from "@/store/mutation-types";
import FeaturesLayer from "@/components/risk-map-view/layers/FeatureLayers";
import MobileMapController from "@/components/risk-map-view/mobile/MobileMapController";
import CurrentLocationMarker from "@/components/risk-map-view/layers/CurrentLocationMarker";
import { RISK } from "@/enums/Risk";
import RiskmaModeLayer from "@/components/risk-map-view/layers/RiskmaModeLayer";
import MobileThirdPartyMapController from "@/components/risk-map-view/mobile/MobileThirdPartyMapController";

export default {
  name: "RiskMapView",
  components: {
    MobileThirdPartyMapController,
    RiskmaModeLayer,
    CurrentLocationMarker,
    MobileMapController,
    FeaturesLayer,
    RiskLayers,
    PcMapController,
    BaseTileLayer,
  },
  data() {
    return {
      RISK: RISK,
      defaultCenter: { lat: 35.5, lng: 136 },
      defaultZoom: 7,
      guerrillaMaxBounds: [
        [31.46, 135.008],
        [37.5496, 141.0875],
      ],
      riskmaMode: null,
    };
  },
  computed: {
    mapObject() {
      return this.$store.state.map;
    },
    selectedRisk() {
      return this.$store.state.selectedRisk;
    },
    isThirdParty() {
      return this.$store.state.isThirdParty;
    },
  },
  watch: {
    selectedRisk() {
      this.replaceUrl();
    },
  },
  created() {
    this.riskmaMode = this.getRiskmaMode();
    this.initMap();
  },
  mounted() {
    this.$nextTick(() => {
      let resize;
      window.onresize = () => {
        clearTimeout(resize);
        resize = setTimeout(this.onResize, 100);
      };
      this.onResize();
    });
  },
  beforeDestroy() {
    this.$store.commit(UPDATE_MAP_OBJECT, { map: null });
  },
  methods: {
    onResize() {
      document.body.style.height = window.innerHeight + "px";
      this.$refs.riskMap.style.height = window.innerHeight + "px";
      this.mapObject.invalidateSize();
    },
    updateMapObject() {
      const mapObject = this.$refs.map.mapObject;
      this.$store.commit(UPDATE_MAP_OBJECT, { map: mapObject });
      this.addMapMoveEvent();
    },
    addMapMoveEvent() {
      this.mapObject.on("moveend", this.onMove);
    },
    initMap() {
      this.initMapByCache();
      this.initMapUrlParams();
    },
    initMapByCache() {
      try {
        let lat = localStorage.getItem("lat");
        let lng = localStorage.getItem("lng");
        if (lat && lng) {
          this.defaultCenter = { lat: parseFloat(lat), lng: parseFloat(lng) };
        }

        let zoom = localStorage.getItem("zoom");
        if (zoom) {
          this.defaultZoom = parseInt(zoom);
        }
      } catch (err) {
        return;
      }
    },
    initMapUrlParams() {
      const latLngZoom = this.$route.params.latLngZoom;
      if (latLngZoom) {
        const [lat, lng, zoom] = _.split(_.trim(latLngZoom, "@z"), ",");
        this.defaultCenter = { lat: parseFloat(lat), lng: parseFloat(lng) };
        this.defaultZoom = parseInt(zoom);
      }
    },
    onMove() {
      this.replaceUrl();
      this.setCache();
    },
    replaceUrl() {
      const { lat, lng } = this.mapObject
        ? this.mapObject.getCenter()
        : this.defaultCenter;
      const zoom = this.mapObject ? this.mapObject.getZoom() : this.defaultZoom;
      let location = `/${this.$i18n.locale}/risk-map/@${lat},${lng},${zoom}z`;
      if (this.selectedRisk) {
        const [data, subData] = ((selectedRisk) => {
          return [
            Math.floor(parseInt(selectedRisk) / 2),
            parseInt(selectedRisk) % 2,
          ];
        })(this.selectedRisk);
        location += `/data=${data}&subData=${subData}`;
        this.$router.replace(
          location,
          () => {},
          () => {}
        );
      }
    },
    setCache() {
      const position = this.mapObject.getCenter();
      try {
        localStorage.setItem("lng", position.lng);
        localStorage.setItem("lat", position.lat);
        localStorage.setItem("zoom", this.mapObject.getZoom());
      } catch (err) {
        return;
      }
    },
    getRiskmaMode() {
      const search = window.location.href.slice(
        _.lastIndexOf(window.location.href, "/") + 1
      );
      const params = new URLSearchParams(_.trimStart(search, "risk-map?"));
      const mode = params.get("mode") ? params.get("mode") : null;
      if (mode) {
        return mode;
      } else {
        return null;
      }
    },
  },
};
</script>
<i18n>
{
  "ja": {
    "geographicalSurveyInstitute": "国土地理院",
    "attribution": "河川・流域界：国土交通省国土政策局「国土数値情報流域界・非集水域S52 河川H18～H21」をもとに(株)建設技術研究所が加工"
  },
  "en": {
    "geographicalSurveyInstitute": "Geographical Survey Institute",
    "attribution": "River and watershed delineation based on MLIT (Ministry of Land, Infrastructure, Transport and Tourism) data processed by CTI Engineering Co., Ltd."
  }
}
</i18n>
<style lang="scss">
.leaflet-pane.leaflet-tile-pane .leaflet-layer:nth-child(1) {
  filter: blur(0px) brightness(89%) contrast(100%) grayscale(8%)
    hue-rotate(309deg) opacity(100%) invert(0%) saturate(131%) sepia(2%);
}
.leaflet-bottom.leaflet-right
  .leaflet-control-attribution.leaflet-control:last-child {
  display: none;
}
</style>
<style lang="scss" scoped>
.risk-map {
  position: relative;
  width: 100%;
  overflow: hidden;
}
#map {
  width: 100%;
  height: 100%;
}
.controller-area {
  position: absolute;
  background-color: $base-color;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
</style>
