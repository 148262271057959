<template>
  <div class="mb-10 my-md-6">
    <div>
      <v-btn small dark color="#00AF82" fab @click="zoomIn">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
    <div class="mt-2">
      <v-btn small dark color="#00AF82" fab @click="zoomOut">
        <v-icon>mdi-minus</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  inject: {
    riskMap: {
      default: [],
    },
  },
  name: "ZoomController",
  computed: {
    map() {
      return this.$store.state.map;
    },
  },
  methods: {
    zoomIn() {
      this.map.setZoom(this.map.getZoom() + 1);
    },
    zoomOut() {
      this.map.setZoom(this.map.getZoom() - 1);
    },
  },
};
</script>

<style scoped></style>
