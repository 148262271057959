<template>
  <div>
    <template v-if="imageView">
      <template v-for="(part, index) in imageView">
        <l-marker
          v-if="part.visible"
          :key="index"
          :lat-lng="[part.markerLat, part.markerLng]"
        >
          <l-icon :icon-anchor="[0, 0]">
            <div
              :style="{
                width: part.divWidth + 'px',
                height: part.divHeight + 'px',
                overflow: 'hidden',
                'pointer-events': 'none',
              }"
            >
              <img
                class="risk-image"
                alt="risk-image"
                :src="selectedDateRiskData.image.src"
                :style="{
                  transform: `translate(0,${part.translateY}px)`,
                  width: part.imgWidth + 'px',
                  height: part.imgHeight + 'px',
                  opacity: imageOpacity,
                  'pointer-events': 'none',
                }"
              />
            </div>
          </l-icon>
        </l-marker>
      </template>
    </template>
  </div>
</template>

<script>
import _ from "lodash";
import ImageLayerBase from "@/components/risk-map-view/layers/base/ImageLayerBase";
export default {
  name: "RainLayerBase",
  extends: ImageLayerBase,
  methods: {
    loadData() {
      this.$store.dispatch("loadRain", { riskType: this.riskType });
    },
    updateImageView() {
      this.imageView = this.generateImageView(this.selectedDateRiskData.type);
    },
    updateVisible() {
      if (this.imageView && this.imageView.length > 0) {
        _.forEach(this.imageView, (part) => {
          part.visible = this.isVisible(
            part.markerLat,
            part.markerLng,
            part.divHeight
          );
        });
      }
    },
    generateImageView(imageType) {
      if (!this.map) return;
      if (!this.imageInfo[imageType]) return;

      const imageView = [];
      const coordinate = this.imageInfo[imageType].coordinate;
      const [sliceCount, slicedLatHeight] = ((imageType) => {
        if (
          this.$vuetify.breakpoint.smAndDown &&
          this.map.getZoom() < this.mobileMaxZoom
        ) {
          return [
            this.imageInfo[imageType].mobileSliceCount,
            this.imageInfo[imageType].mobileSlicedLatHeight,
          ];
        } else {
          return [
            this.imageInfo[imageType].sliceCount,
            this.imageInfo[imageType].slicedLatHeight,
          ];
        }
      })(imageType);

      for (let i = 0; i < sliceCount; i++) {
        const slicedImageBottomLeftPoint = this.map.project([
          coordinate.north - i * slicedLatHeight - slicedLatHeight,
          coordinate.west,
        ]);
        const slicedImageTopRightPoint = this.map.project([
          coordinate.north - i * slicedLatHeight,
          coordinate.east,
        ]);

        let slicedImageHeight =
          slicedImageBottomLeftPoint.y - slicedImageTopRightPoint.y;
        const slicedImageWidth =
          slicedImageTopRightPoint.x - slicedImageBottomLeftPoint.x;

        if (
          Math.round(slicedImageBottomLeftPoint.y) <
          Math.round(
            slicedImageBottomLeftPoint.y + Math.ceil(slicedImageHeight)
          )
        ) {
          slicedImageHeight -= -1;
        }

        const markerLat = coordinate.north - i * slicedLatHeight;
        const markerLng = coordinate.west;
        const divHeight = Math.ceil(slicedImageHeight) - 1.4;

        imageView.push({
          markerLat: markerLat,
          markerLng: markerLng,
          divWidth: slicedImageWidth,
          divHeight: divHeight,
          imgWidth: slicedImageWidth,
          imgHeight: slicedImageHeight * sliceCount,
          translateY: -(i * slicedImageHeight + 1),
          visible: this.isVisible(markerLat, markerLng, divHeight),
        });
      }

      return imageView;
    },
  },
};
</script>
