<template>
  <div>
    <template v-for="(image, index) in imageView">
      <template v-for="(slicedImageInfo, subIndex) in image">
        <l-marker
          v-if="slicedImageInfo.visible"
          :key="`flood-${index}-${subIndex}`"
          :lat-lng="[slicedImageInfo.markerLat, slicedImageInfo.markerLng]"
        >
          <l-icon :icon-anchor="[0, 0]">
            <div
              :style="{
                width: slicedImageInfo.divWidth + 'px',
                height: slicedImageInfo.divHeight + 'px',
                overflow: 'hidden',
                'pointer-events': 'none',
              }"
            >
              <img
                class="risk-image"
                alt="risk-image"
                v-if="selectedDateRiskData.images[index]"
                :src="selectedDateRiskData.images[index].src"
                :style="{
                  transform: `translate(0,${slicedImageInfo.translateY}px)`,
                  width: slicedImageInfo.imgWidth + 'px',
                  height: slicedImageInfo.imgHeight + 'px',
                  opacity: imageOpacity,
                  'pointer-events': 'none',
                }"
              />
            </div>
          </l-icon>
        </l-marker>
      </template>
    </template>
  </div>
</template>

<script>
import _ from "lodash";
import ImageLayerBase from "@/components/risk-map-view/layers/base/ImageLayerBase";
export default {
  name: "FloodLayerBase",
  extends: ImageLayerBase,
  methods: {
    loadData() {
      this.$store.dispatch("loadWater", { riskType: this.riskType });
    },
    updateImageView() {
      const images = [];
      this.selectedDateRiskData.images.forEach((image, index) => {
        images.push(this.generateImageView(index));
      });
      this.imageView = images;
    },
    updateVisible() {
      if (this.imageView && this.imageView.length > 0) {
        _.forEach(this.imageView, (image) => {
          _.forEach(image, (part) => {
            part.visible = this.isVisible(
              part.markerLat,
              part.markerLng,
              part.divHeight
            );
          });
        });
      }
    },
    generateImageView(imageType) {
      if (this.map == null) return;

      const imageParts = [];
      const coordinate = this.imageInfo[imageType].coordinate;
      const [sliceCount, slicedLatHeight] = ((imageType) => {
        if (
          this.$vuetify.breakpoint.smAndDown &&
          this.map.getZoom() < this.mobileMaxZoom
        ) {
          return [
            this.imageInfo[imageType].mobileSliceCount,
            this.imageInfo[imageType].mobileSlicedLatHeight,
          ];
        } else {
          return [
            this.imageInfo[imageType].sliceCount,
            this.imageInfo[imageType].slicedLatHeight,
          ];
        }
      })(imageType);

      let lastScale =
        coordinate.south - coordinate.north + sliceCount * slicedLatHeight;
      lastScale = 1 - lastScale / slicedLatHeight;

      for (let i = 0; i < sliceCount; i++) {
        if (i < sliceCount - 1) {
          const slicedImageBottomLeftPoint = this.map.project([
            coordinate.north - i * slicedLatHeight - slicedLatHeight,
            coordinate.west,
          ]);
          const slicedImageTopRightPoint = this.map.project([
            coordinate.north - i * slicedLatHeight,
            coordinate.east,
          ]);

          let slicedImageHeight =
            slicedImageBottomLeftPoint.y - slicedImageTopRightPoint.y;
          const slicedImageWidth =
            slicedImageTopRightPoint.x - slicedImageBottomLeftPoint.x;

          if (
            Math.round(slicedImageBottomLeftPoint.y) <
            Math.round(
              slicedImageBottomLeftPoint.y + Math.ceil(slicedImageHeight)
            )
          ) {
            slicedImageHeight -= -1;
          }

          const markerLat = coordinate.north - i * slicedLatHeight;
          const markerLng = coordinate.west;
          const divHeight = Math.ceil(slicedImageHeight) - 1.4;
          imageParts.push({
            markerLat: markerLat,
            markerLng: markerLng,
            divWidth: slicedImageWidth,
            divHeight: divHeight,
            imgWidth: slicedImageWidth,
            imgHeight:
              slicedImageHeight * (sliceCount - 1) +
              slicedImageHeight * lastScale,
            translateY: -(i * slicedImageHeight + 1),
            visible: this.isVisible(markerLat, markerLng, divHeight),
          });
        } else {
          const slicedImageBottomLeftPoint = this.map.project([
            coordinate.north,
            coordinate.west,
          ]);
          const slicedImageTopRightPoint = this.map.project([
            coordinate.north - (sliceCount - 1) * slicedLatHeight,
            coordinate.east,
          ]);

          let slicedImageHeight =
            slicedImageBottomLeftPoint.y - slicedImageTopRightPoint.y;
          const slicedImageWidth =
            slicedImageTopRightPoint.x - slicedImageBottomLeftPoint.x;

          if (
            Math.round(slicedImageBottomLeftPoint.y) <
            Math.round(
              slicedImageTopRightPoint.y + Math.ceil(slicedImageTopRightPoint)
            )
          ) {
            slicedImageHeight = slicedImageHeight - 1;
          }
          const sliceY = slicedImageHeight / lastScale;

          const markerLat = coordinate.north - i * slicedLatHeight;
          const markerLng = coordinate.west;
          const divHeight = Math.ceil(slicedImageHeight) - 1.4;
          imageParts.push({
            markerLat: markerLat,
            markerLng: markerLng,
            divWidth: slicedImageWidth,
            divHeight: divHeight,
            imgWidth: slicedImageWidth,
            imgHeight: sliceY * (sliceCount - 1) + slicedImageHeight,
            translateY: -((sliceCount - 1) * sliceY + 1),
            visible: this.isVisible(markerLat, markerLng, divHeight),
          });
        }
      }
      return imageParts;
    },
  },
};
</script>
