import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import vuetify from "./plugins/vuetify";
import i18n from "./plugins/i18n";
import "./plugins/vueMq";
import "./plugins/vueLeaflet";
import VueGtag from "vue-gtag";
import VueMeta from "vue-meta";

Vue.config.productionTip = false;
Vue.use(
  VueGtag,
  {
    config: { id: "G-BBZR8S1YE7" },
    params: {
      send_page_view: false,
    },
  },
  router
);
Vue.use(VueMeta);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
