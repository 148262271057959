export const UPDATE_IS_LOADING = "UPDATE_IS_LOADING";
export const UPDATE_MAP_OBJECT = "UPDATE_MAP_OBJECT";
export const UPDATE_IMAGE_OPACITY = "UPDATE_IMAGE_OPACITY";
export const UPDATE_SELECTED_RISK = "UPDATE_SELECTED_RISK";
export const UPDATE_SELECTED_FEATURES = "UPDATE_SELECTED_FEATURES";
export const UPDATE_CURRENT_DATE = "UPDATE_CURRENT_DATE";
export const UPDATE_BASE_DATE = "UPDATE_BASE_DATE";
export const UPDATE_SELECTED_DATE = "UPDATE_SELECTED_DATE";
export const UPDATE_RISK_DATA = "UPDATE_RISK_DATA";
export const UPDATE_FLOOD_AREA_STATUS = "UPDATE_FLOOD_AREA_STATUS";
export const UPDATE_GUERRILLA_RAIN_AREA_STATUS =
  "UPDATE_GUERRILLA_RAIN_AREA_STATUS";
export const UPDATE_RAIN_AREA_STATUS = "UPDATE_RAIN_AREA_STATUS";
export const UPDATE_CURRENT_LOCATION = "UPDATE_CURRENT_LOCATION";
export const UPDATE_META_INFO = "UPDATE_META_INFO";
